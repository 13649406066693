import React, { useRef, useState } from 'react';
import { PrintTable } from './PrintTable';
import EmailSetup from './EmailSetup';
import ReactDOMServer from 'react-dom/server';

export default function Table(props) {
    const componentRef = useRef();
    const printAction =
        <div>
            <PrintTable ref={componentRef} />
        </div>
    const [filters, setFilters] = useState({});
    const [searchText, setSearchText] = useState('');

    const handleFilterChange = (event, key) => {
        setFilters({
            ...filters,
            [key]: event.target.value.toLowerCase(),
        });
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const filterRows = (row) => {
        const searchFilter = (searchText === '')
            ? true // if search text is empty, return true to include all rows
            : props.tHeadItems.some((key) => {
                const cellValue = row[key] ? row[key].toString().toLowerCase() : '';
                return cellValue.includes(searchText);
            });

        const columnFilters = Object.entries(filters).every(([key, value]) => {
            if (!value) return true; // if filter is not set, return true to include the row
            const cellValue = row[key] ? row[key].toString().toLowerCase() : ''; // check if property exists and convert to string
            return cellValue.includes(value);
        });

        return searchFilter && columnFilters;
    };
    const tableStyles = {
        borderCollapse: 'collapse',
        width: '100%',
        fontFamily: 'Kalimati, Regular',
    };

    const thStyles = {
        padding: '10px',
        border: '1px solid #ddd',
        backgroundColor: '#007bff',
        color: '#fff',
    };

    const tdStyles = {
        padding: '10px',
        border: '1px solid #ddd',
    };
    const tableRowStyles = {
        backgroundColor: '#e9e9e9',
    };

    const tableAlternateRowStyles = {
        backgroundColor: '#fff',
    };

    const filteredRows = props.tBodyItems.filter(filterRows);

    const tablehead = (
        <thead>
            <tr className='tableRow'>

                {props.tHeadItems.map((item, index) => (
                    <th key={index} >
                        <input className='tableRow'
                            type="text"
                            placeholder={`Filter by ${item}`}
                            onChange={(event) => handleFilterChange(event, item)}
                        />
                    </th>
                ))}
            </tr>
            <tr>
                <th colSpan={props.tHeadItems.length}>
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value={searchText}
                    />
                </th>
            </tr>
        </thead>
    );

    const uploadtoparticipation = (id) => {
        // alert("button click")
        let tr = document.getElementById(id);
        if (tr) {
            tr.style.backgroundColor = "green";
        }
        else {
            alert("tr doesn't exist")
        }

    }
    const tablebody = (
        <tbody>
            {filteredRows.map((items, index) => (

                <tr key={index} style={index % 2 !== 0 ? tableRowStyles : tableAlternateRowStyles} id={index + 'key' + props.inputType}>
                    <td style={tdStyles}>{index + 1}</td>
                    {props.tHeadItems.map((item, ind) => (
                        <td key={ind}>{items[item]}</td>
                    ))}
                    {props.inputType === "invitations" && <td>
                        <button onClick={() => { uploadtoparticipation(index + 'key' + props.inputType) }} style={thStyles}>√</button>
                    </td>}
                </tr>
            ))}
        </tbody>
    );
    const emailtable = (
        <tbody>
            {filteredRows.map((items, index) => (

                <tr key={index} style={index % 2 !== 0 ? tableRowStyles : tableAlternateRowStyles} id={index + 'key' + props.inputType}>
                    <td style={tdStyles}>{index + 1}</td>
                    {[0, 1, 2].map((items, columns) => {
                        <tr key={columns}>
                            <td>S.N.</td>
                            <td>Topic</td>
                            <td>Value</td>
                        </tr>
                    })}
                    {props.tHeadItems.map((item, ind) => (
                        <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{item}</td>
                            <td>{items[item]}</td>

                        </tr>
                    ))}
                    {props.inputType === "invitations" && <td>
                        <button onClick={() => { uploadtoparticipation(index + 'key' + props.inputType) }} style={thStyles}>√</button>
                    </td>}
                </tr>
            ))}
        </tbody>
    );


    const displayTable = <table className='table-print' style={tableStyles} ref={componentRef}>
        <thead>
            <tr style={tableRowStyles}>
                <td style={thStyles}>सि.नं</td>

                {props.tHeadItems.map((item, index) => {
                    return <td key={index} style={thStyles}>{item}</td>
                })}
                {props.inputType === "invitations" && <td>
                    <button style={thStyles}>yes</button>
                </td>}
            </tr>
        </thead>
        {tablebody}
    </table>

    return (<div>
        <div>
            <table style={tableStyles}>
                <thead>
                    <tr>
                        <th colSpan={props.tHeadItems.length - 2} style={thStyles}>Filter and Search</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td style={tdStyles}>{props.tableDescription} </td>
                    </tr>
                </tbody>

                {tablehead}
            </table>
        </div>
        {displayTable}
        <div className='row'>


            <div className="col email-sender-container">{printAction}</div>
            <div className="col">{<EmailSetup subject={props.inputType} html={ReactDOMServer.renderToString(emailtable)} />}</div>

        </div>

        {emailtable}

    </div>

    );
}
