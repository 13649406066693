import React, { useState } from 'react'
import Heading from '../ParagraphItems/Heading'
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import RelationToApplicant from '../ParagraphItems/RelationToApplicant'
import SarjinPresent from '../ParagraphItems/SarjinPresent'
import Selection from '../ParagraphItems/Selection'
import Sender from '../ParagraphItems/Sender'
import { applicantObjects, bold, kittaKaatItems, protectorTitles, underline } from '../Variables'
import Person from '../Variables/Person'
import { getOtherPeoples } from '../Variables/TextVariables'

export default function Protector(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);

    const titleItems = protectorTitles;
    const titleName = "selectedTitle";
    const selectedTitle = details[titleName] ? details[titleName] : titleItems[0];
    const selectedIndex = titleItems.indexOf(selectedTitle);

    const protectedPerson = details["protected"] ? details["protected"] : applicantObjects;
    const protectedPersonInput = <div>
        <hr />
        <b className='p-1 mb-1  text-primary'>Protected Person Details:</b>
        <Person person={protectedPerson} handlePerson={(field, value) => {
            props.detailsUpdater("protected", { ...props.details["protected"], ...{ ...protectedPerson, ...{ [field]: value } } });
        }} />

    </div>

    return (
        <div>
            <div>
                <Selection selectionTitle={titleName} options={titleItems} details={details} detailsUpdater={props.detailsUpdater} />
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />

                {protectedPersonInput}
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedTitle} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {selectedTitle} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}, {bold(getOtherPeoples(protectedPerson))}को, संरक्षक भएको हुनाले सो व्यहोरा {sarjin.sarjinPresent && (sarjin.sarjinDetails + "का आधारमा ")}स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (१९) बमोजिम {selectedIndex === 0 ? "प्रमाणित" : "सिफारिस"} गरिन्छ ।
                </div>
                <div>
                    <br />

                    {table}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
