import React from 'react'
import InputBox from '../InputBox'
import Heading from '../ParagraphItems/Heading'
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import Sender from '../ParagraphItems/Sender'
import { suchanaDate } from '../Variables'
import { checkCharacters, isValidDate } from '../Variables/TextVariables'

export default function OpenClosedHouse(prop) {
    const props = prop.prop;
    const details = props.details;
    const suchanaMiti = <div className="row">
        <InputBox maxlength={"10"} field={suchanaDate} value={details[suchanaDate]} handleChanges={(field, value) => { props.detailsUpdater(field, isValidDate(value)) }} />
    </div>
    return (
        <div>
            {suchanaMiti}
            <hr />
            <div className='businessClose' ref={props.componentRef}>

                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} बन्द घर/कोठा खुलाई पाउँ भनी दिनु भएको निवेदन उपर मिति {details[suchanaDate]} मा घर/कोठा सम्बन्धमा जारी गरेको सूचना  बमोजिम सरोकारवालाहरुको उपस्थितीमा (यसै साथ संलग्न मुचुल्का बमोजिम) बन्द घर/कोठा खोलिएको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(४) बमोजिम प्रमाणित गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
