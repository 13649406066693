import React from 'react'
import ReactToPrint from 'react-to-print';

export const FormValidation = React.forwardRef((props, ref) => {

    return (
        validateForm() ?
            <ReactToPrint
                // onBeforeGetContent={validateForm()}
                trigger={() =>
                (
                    <div>

                        <button className="btn btn-danger" type="submit">{props.buttonName ?? "Submit"}</button>

                    </div>
                )
                }
                content={() => ref.current}
            /> : "Enter all Details"
    )
}
);



export const validateForm = (props) => {
    try {
        const applicant_NameField = document.getElementById("_Name");

        // Check if the field exists and is visible (displayed)
        const isApplicantNameVisible = applicant_NameField && applicant_NameField.offsetParent !== null;

        if (isApplicantNameVisible) {
            const applicant_Name = applicant_NameField.value.trim(); // Trim whitespace
            console.log(applicant_Name, "applicant Name");

            if (applicant_Name.length < 1) {
                applicant_NameField.focus(); // Focus on the input field
                applicant_NameField.select(); // Select the input field content
                return false; // Return false indicating validation failed
            } else {
                return true; // Return true indicating validation passed
            }
        } else {
            // If the applicant name field is not visible, consider the form validated
            return true;
        }
    } catch (error) {
        console.error("ERROR in Validation:", error);
        return false; // Return false if an error occurs during validation
    }
};
