// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, } from 'firebase/auth';
import { setIsUserInfo } from "../Variables/TextVariables";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSgyq-aEK59XIm-T3XFIJsZ9Qa01a4usY",
    authDomain: "reactfirebase-693c4.firebaseapp.com",
    databaseURL: "https://reactfirebase-693c4-default-rtdb.firebaseio.com",
    projectId: "reactfirebase-693c4",
    storageBucket: "reactfirebase-693c4.appspot.com",
    messagingSenderId: "825009788070",
    appId: "1:825009788070:web:66159ad26e7de596ba9067",
    measurementId: "G-4X3D5MFGVM"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const database = getFirestore(app);
const auth = getAuth();
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, provider);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    setIsUserInfo(false)
    signOut(auth)
    // <Login />
};
export {
    auth,
    database,
    signInWithGoogle,
    // logInWithEmailAndPassword,
    // registerWithEmailAndPassword,
    // sendPasswordReset,
    logout,

};