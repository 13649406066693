import React from 'react'

export default function SelectBox(props) {
    const handleInputChange = (value) => {
        console.log("field value", value);
        props.handleChanges(value)
    }


    return (
        <div className='selectContainer' >
            <select className="form-select" required id={props.items} aria-label="Default select example" onChange={(e) => handleInputChange(e.target.value)} value={props.value}>
                {props.items ? <option value=''>{props.items}</option> : ""}
                {props.options.map((item, index) => { return <option key={index} value={item} >{item}</option> })} </select>
        </div>
    )
}
