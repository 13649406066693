import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Sender from '../ParagraphItems/Sender';
import SelectBox from '../SelectBox';
import { applicantObjects, bold, firstPerson, marriageDate, marriageItems, relationToApplicant, unmarriedDate } from '../Variables';
import Person from '../Variables/Person';
import { currentDate, getApplicant, getOtherPeoples, isValidDate } from '../Variables/TextVariables';

export default function Marriage(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const isPersonDifferent = applicantRelation.isPersonDifferent;
    const personMention = applicantRelation.personMention
    const isMarriagePramanit = !(details["type"] === marriageItems[1]);
    const selectedTopic = isMarriagePramanit ? marriageItems[0] : marriageItems[1];

    const selectItems = <SelectBox value={details["type"]} options={marriageItems} handleChanges={(value) => props.detailsUpdater("type", value)} />

    const spouse = details["spouse"] ? details["spouse"] : applicantObjects;
    const spouseDetails = <div>
        <hr />
        <b className='p-1 mb-1  text-primary'>Spouse Details:</b>
        <Person person={spouse} handlePerson={(field, value) => {
            props.detailsUpdater("spouse", { ...props.details["spouse"], ...{ ...spouse, ...{ [field]: value } } });
        }} />

    </div>


    const sarjinPresent = sarjin.sarjinPresent;
    const sarjinDetails = sarjin.sarjinDetails;
    const marriedUnmarriedTitle = isMarriagePramanit ? marriageDate : unmarriedDate;
    let dateValue = isMarriagePramanit ? (details[marriedUnmarriedTitle] ? details[marriedUnmarriedTitle] : "") : (details[marriedUnmarriedTitle] ? details[marriedUnmarriedTitle] : currentDate)
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-6">
                    <hr />
                    {selectItems}
                    <hr />
                </div>
            </div>

            <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
            {isMarriagePramanit && spouseDetails}
            <hr />
            <div className='row  justify-content-center '>
                <div className="col-6">
                    <hr />
                    <InputBox field={marriedUnmarriedTitle} value={dateValue} handleChanges={(field, value) => {

                        props.detailsUpdater(field, isValidDate(value))
                    }} />
                    <hr />
                </div>

            </div>

            <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedTopic} date={props.date} />
                <br />
                {isMarriagePramanit ? <div className="content" align='justify'>
                    {isPersonDifferent && props.starter} {isPersonDifferent && selectedTopic} {isPersonDifferent && "गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार नीज निवेदकको "}{isPersonDifferent && details[relationToApplicant[0]]}
                    {!isPersonDifferent ? getApplicant() : getOtherPeoples(details[firstPerson] ? details[firstPerson] : applicantObjects)} र {getOtherPeoples(spouse)} बीच मिति {details[marriageDate]} मा विवाह भएको व्यहोरा निवेदनसाथ संलग्न कागजात{sarjinPresent && " तथा " + sarjinDetails}का आधारमा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(९) बमोजिम प्रमाणित गरिन्छ ।
                </div> :
                    <div className="content" align='justify'>
                        {props.starter} {selectedTopic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(personMention)} मिति {details[unmarriedDate] ? details[unmarriedDate] : currentDate} सम्म अविवाहित रहेको व्यहोरा {sarjinPresent && sarjinDetails + " का आधारमा "} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(९) बमोजिम प्रमाणित गरिन्छ ।
                    </div>
                }
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>

        </div>
    )
}
