import React, { useEffect } from 'react'
import InputBox from '../InputBox';
import ApplicationDate from '../ParagraphItems/ApplicationDate';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Sender from '../ParagraphItems/Sender';
import SelectBox from '../SelectBox';
import { bold, businessCloseSpecificItems, businessTransferSpecificItems, spaces } from '../Variables';
import Person from '../Variables/Person';
import { getApplicant, getOtherPeoples, officeAddress } from '../Variables/TextVariables';

export default function OutBusiness(prop) {
    const props = prop.prop;
    const sender = props.person.sender;
    const receiver = props.person.receiver;
    const details = props.details;
    const businessWorkTypes = ['ठाउँसारी', 'किसिम थप', 'किसिम परिवर्तन', "नाम परिवर्तन", "पुँजी परिवर्तन", "नाम र किसिम परिवर्तन", "बन्द", "नामसारी"]
    const goingValues = ['ठाउँसरी जाने', 'ठाउँसरी आउने']
    const businessWork = details.businessWork ? details.businessWork : businessWorkTypes[0];
    const changedType = details.changedType ? details.changedType : "";
    const goingValue = details.goingValue ? details.goingValue : goingValues[1];
    const componentRef = props.componentRef;
    const handleChange = (value) => { props.detailsUpdater("businessWork", value); }
    var isCurrentOwnerApplicant = false;
    var isNewOwnerApplicant = false;
    const currentOwner = getOtherPeoples(details['व्यवसाय धनी']);
    const newOwner = getOtherPeoples(details['नयाँ व्यवसाय धनी']);
    const currentOwnerChecker = details['व्यवसाय धनी'] ? details['व्यवसाय धनी']['sameAsApplicant'] : true;
    const newOwnerChecker = details['नयाँ व्यवसाय धनी'] ? details['नयाँ व्यवसाय धनी']['sameAsApplicant'] : false;
    if (typeof (currentOwnerChecker && newOwnerChecker) === 'undefined' || currentOwnerChecker) isCurrentOwnerApplicant = true;
    else if (newOwnerChecker) isNewOwnerApplicant = true;

    const selectItems = <div className="row justify-content-center" key={1}>
        <div className="col-6">
            <hr />
            <SelectBox value={details["businessWork"]} options={businessWorkTypes} handleChanges={handleChange} />
            <hr />
        </div>
    </div>

    const inputs = [selectItems, businessTransferSpecificItems.map((items, index) => {
        let sameAsApplicant = true;
        if (index >= 3) {
            sameAsApplicant = details[items]['sameAsApplicant'];
            if (sameAsApplicant !== false) {
                if (typeof sameAsApplicant === 'undefined') sameAsApplicant = index === 3 ? true : false;
                console.log({ sameAsApplicant }, { index })
                if (sameAsApplicant) details[items] = { ...details[items], ...props.applicant }
            }
        }
        return index < 3 ? <div className='col' key={index}>
            <InputBox field={items} value={details[items]} handleChanges={props.detailsUpdater} />
        </div> :
            (details.businessWork === 'नामसारी') ? <div key={index}>
                <br />
                <span className='p-3 mb-2 bg-danger text-white'>{items}</span> {spaces(5)}

                <input type="checkbox" id={items + '_checkbox'} checked={sameAsApplicant} onChange={(e) => {
                    let otherindex = index === 3 ? 4 : 3;
                    let allowChange = true;
                    if (details[businessTransferSpecificItems[otherindex]]['sameAsApplicant']) allowChange = false;
                    if (typeof details[businessTransferSpecificItems[otherindex]]['sameAsApplicant'] === 'undefined' && index === 4) allowChange = false;
                    console.log({ allowChange })

                    if (allowChange)
                        props.detailsUpdater(items, { ...details[items], ...{ ['sameAsApplicant']: e.target.checked } })

                }
                } /> <b className='p-3 mb-2 bg-primary text-white'> same as Applicant?</b>

                {!sameAsApplicant ? <Person person={details[items]} handlePerson={(field, value) => {
                    props.detailsUpdater(items, { ...details[items], ...{ [field]: value } })
                }} /> : ''}
                <hr />
                <hr />
            </div> : ''
    }), <div className='row' key={2}>
            {/* <div className="col"><InputBox className='col' field="हालको किसिम" value={currentType} handleChanges={(field, value) => setCurrentType(value)} />
    </div> */}

            {businessWork === businessWorkTypes[0] ?

                <div className='row solidBorder'>
                    <div className="col"><input className='form-check-input' type="radio" name="goingCheck" id="Checkout" value={goingValues[0]} checked={goingValue === goingValues[0]} onChange={(e) => {
                        let x = document.getElementById("Receiver_Office");
                        x.focus();
                        x.select();

                        props.detailsUpdater("goingValue", e.target.value);
                    }} /> {goingValues[0]}</div>
                    <div className="col"> <input className='form-check-input' type="radio" name="goingCheck" id="Checkin" value={goingValues[1]} checked={goingValue === goingValues[1]} onChange={(e) => props.detailsUpdater("goingValue", e.target.value)} /> {goingValues[1]}</div>


                </div>
                : ""}
            {businessWork !== businessWorkTypes[6] &&
                businessWork !== businessWorkTypes[7] &&
                <div className="col"><InputBox className='col' field={businessWork !== businessWorkTypes[0] ? businessWork.substring(businessWork.lastIndexOf(" ")) + " गरिएको " + businessWork.substring(0, businessWork.lastIndexOf(" ")) : goingValue + " ठेगाना"} value={changedType} handleChanges={(field, value) => props.detailsUpdater("changedType", value)} />
                </div>}
        </div>]
    useEffect(() => {
        console.log('tryValue Changed has changed:');
        props.setInputs(inputs)
    }, [details]);





    return (
        <div>
            {/* {inputs} */}
            <div className='businessClose' ref={componentRef}>
                <OfficeInformation />
                <Heading receiver={receiver} topic={props.topic + " " + businessWork} date={props.date} />
                <br />

                {businessWork === businessWorkTypes[7] ?
                    <div className="content" align='justify'>
                        {spaces(5)} उपरोक्त विषयमा यही मिति <b>{props.date.applicationDate}</b> गते यस {officeAddress} को वडा कार्यालयमा <b>{getApplicant()}</b> ले दिनु
                        भएको निवेदन अनुसार  {isCurrentOwnerApplicant ? bold("नीज") : bold(currentOwner)}को नाममा रहेको <b>{`"${details[businessTransferSpecificItems[0]]}"`} {typeof details[businessTransferSpecificItems[1]] !== 'undefined' && details[businessTransferSpecificItems[1]] !== '' ? 'संकेत नं ' + details[businessTransferSpecificItems[1]] : ''} व्यवसाय {details[businessTransferSpecificItems[2]]}</b> कारणले गर्दा {isNewOwnerApplicant ? bold("नीज निवेदक") : bold(newOwner)}को नाममा नामसारी गर्नु पर्ने भएकोले सो व्यवसाय निवेदकको माग बमोजिम यस वडा कार्यालय बाट नामसारी गरिएको व्यहोरा जानकारीका साथ अनुरोध छ ।
                    </div> :
                    businessWork === businessWorkTypes[8] ? <div className='content' align='justify'>
                        ले आफ्नो नाममा रहेको व्यापार व्यवसाय बन्द भएको सिफारिस गरिदिन दिनु भएको निवेदन अनुसार स्थलगत रुपमा सर्जमिन मुचुल्का समेत गरी बुझ्दा उक्त ...................... कारणले मिति ..................... देखि .................. सम्म बन्द भएको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(८) बमोजिम सिफारिस गरिन्छ ।
                    </div>
                        :
                        <div className="content" align='justify'>
                            {spaces(5)} उपरोक्त विषयमा यही मिति <b>{props.date.applicationDate}</b> गते यस {officeAddress} को वडा कार्यालयमा <b>{getApplicant()}</b> ले दिनु
                            भएको निवेदन अनुसार नीजको नाममा रहेको  <b>{`"${details[businessCloseSpecificItems[0]]}"`} {typeof details[businessCloseSpecificItems[1]] !== 'undefined' && details[businessCloseSpecificItems[1]] !== '' ? 'संकेत नं ' + details[businessCloseSpecificItems[1]] + " " : ''}
                                व्यवसाय{businessWork !== businessWorkTypes[6] && (businessWork === businessWorkTypes[1] ? "मा" : "को")} {details[businessCloseSpecificItems[2]]}</b> कारणले गर्दा {bold(changedType)}{businessWork === businessWorkTypes[2] || businessWork === businessWorkTypes[0] ? "मा" : ""} {(businessWork)} गर्नु पर्ने भएकोले नीजको माग बमोजिम {businessWork === businessWorkTypes[0] && goingValue == goingValues[0] ? <span> {bold(businessWork)} गरिदिन सिफारिश </span> : <span>यस कार्यालय बाट {bold("व्यवसाय")}  {bold(businessWork)} गरिएको व्यहोरा जानकारीका </span>}
                            साथ अनुरोध छ ।
                        </div>

                }

                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}

