import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import OfficeInformation from '../ParagraphItems/OfficeInformation'

export default function LetterPad(prop) {
    const props = prop.prop;
    const componentRef = props.componentRef;
    return (
        <div>
            {/* <div className='printAction'>
                <ReactToPrint
                    trigger={() => <button className="btn btn-danger" type="submit">Submit</button>}
                    content={() => componentRef.current}
                />
            </div> */}
            <div className='businessClose' ref={componentRef}>
                <OfficeInformation />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>

        </div>
    )
}
