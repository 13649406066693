import React, { useEffect, useRef, useState } from 'react'
import { FormValidation } from './FormValidation';
import OutBusiness from './OutgoingItems/OutBusiness';
import Applicant from './ParagraphItems/Applicant';
import ApplicationDate from './ParagraphItems/ApplicationDate';
import DateItem from './ParagraphItems/DateItem';
import SenderReceiver from './ParagraphItems/SenderReceiver';
import SelectBox from './SelectBox'
import Business from './SifarisItems/Business';
import { businessTransferSpecificItems, outgoingItem, spaces } from './Variables';
import { getApplicant, officeAddress, userDetail } from './Variables/TextVariables';

export default function Outgoing(props) {
    const [inputs, setInputs] = useState([]);
    const [tryValue, setTryValue] = useState("");
    console.log({ tryValue, inputs });
    const outgoing = props.outgoingItems;

    const topic = outgoing.items.topic ? outgoing.items.topic : "व्यवसाय";
    const selectItems = <SelectBox value={outgoing["topic"]} options={outgoingItem} handleChanges={(value) => {
        outgoing.updater("topic", value);

    }} />
    const senderReceiver = <SenderReceiver person={props.person} handlePerson={props.handlePerson} />
    useEffect(() => {

        outgoingItem.map((item, index) => {
            if (topic === item) {
                let sender = {};
                sender.Post = "वडा अध्यक्ष"
                sender.Office = userDetail().office["नाम"];
                sender.Address = userDetail().municipality["नाम"] + "-" + userDetail().office["वडा नं"] + ", " + userDetail().district;
                let receiver = { Post: "", Office: "", Address: "" }

                switch (item) {

                    case "व्यवसाय": receiver.Office = 'आन्तरिक राजश्व कार्यालय'; receiver.Address = userDetail().municipality["नाम"] + ", " + userDetail().district; break;

                }
                props.handlePerson("", {
                    ...props.person, ...{ sender, receiver }
                })
            }

        });
        // props.setInputs(inputs)
    }, [topic]);


    const componentRef = useRef();
    const printAction =
        <div className='printAction'>
            <FormValidation ref={componentRef} />

        </div>
    const startingText = <span>{spaces(5)} उपरोक्त विषयमा यही मिति <b>{props.date.applicationDate}</b> गते यस {officeAddress} को वडा कार्यालयमा <b>{getApplicant()}</b> ले </span>
    var displayContent = '';
    const handleOutgoing = (field, value, innerfield) => {
        const updatedValue = {}; updatedValue[field] = value;
        outgoing.updater(topic, { ...outgoing.items[topic], ...updatedValue })
    }
    const prop = {
        date: props.date, person: props.person, handlePerson: props.handlePerson, topic: topic, applicant: props.applicant, details: props.outgoingItems.items[topic], detailsUpdater: handleOutgoing, componentRef: componentRef, starter: startingText, setInputs: setInputs, setTryValue: setTryValue, tryValue: tryValue, inputs: inputs
    }

    switch (topic) {
        case 'व्यवसाय': displayContent = <OutBusiness prop={prop} />
            break;
        default:
            break;
    }


    return (
        <div className='mukesh'>
            <div className="GridContainer">
                <div className="left-section">
                    <DateItem date={props.date} selection={props.title} />
                    <Applicant selection={props.title} applicant={props.applicant} handleApplicant={props.handleApplicant} />
                    {printAction}
                    <hr />
                    <hr />
                    {selectItems}
                    <hr />
                    <hr />
                    <ApplicationDate date={props.date} setApplicationDate={props.setApplicationDate} />
                    <div className="row">
                        {inputs}

                    </div>

                    {senderReceiver}
                </div>
                <div className='right-section'>

                    {displayContent}

                </div>
            </div>

        </div>
    )
}
