import React from 'react'
import { dateWithSlash } from '../Variables/TextVariables'

export default function Heading(props) {
    const receiver = props.receiver
    const dname = receiver['Name']
    const dpost = receiver['Post']

    const doffice = receiver['Office']
    const daddress = receiver['Address']
    const toWhomItConcerns = <h6 align="left"> {props.starter ? props.starter : "जो जससँग सम्बन्धित छ ।"}</h6>
    console.log(props.noending, "noending")
    return (
        <div>
            <div id='DateRow' align='right'> मितिः {dateWithSlash(props.date.currentDate)}</div>

            {dpost ? <div className="col" id='description' align='left'>
                <br />
                श्री {dpost}
                <br />{doffice}
                <br />{daddress}

            </div> : (doffice ? <div className="col" id='description' align='left'>
                श्री {doffice}
                <br />{daddress}
            </div> : "")}
            <div className="row">
                <div className="col" align='center'>
                    विषयः <b>
                        <u>
                            {props.topic} {!props.noending ? "सम्बन्धमा" : ''}
                        </u >
                    </b>
                    {!dpost && !doffice && !props.noStarter && toWhomItConcerns}
                </div>
            </div>
        </div>
    )
}
