import React, { useState } from 'react'
import { Sifaris } from './Sifaris';
import Sarjamin from './Sarjamin';
import Person from './Variables/Person';
import Darta from './Darta';
import Outgoing from './Outgoing';
import Applicant from './ParagraphItems/Applicant';
import Secretariat from './MayorSecretariat/Secretariat';
import Gharnaksa from './GharNaksaSakha/Gharnaksa';
import Samjhouta from './Samjhouta/Samjhouta';
import Tippani from './Tippani/Tippani';
import Internal from './InternalLetters/Internal';
const variables = require('./Variables');
const textVariables = require('./Variables/TextVariables')
const Date = require('./Date');
const applicantItems = variables.applicantItems;
// applicantItems.map((items) => { applicantObjects[items] = ''; return null; })
const handleStateUpdates = (itemToUpdate, functionThatUpdatesItem, field, value) => {
    var updatedValue = {}; field !== '' ? updatedValue[field] = value : updatedValue = value;
    functionThatUpdatesItem({ ...itemToUpdate, ...updatedValue });
}

export const LeftSide = (props) => {
    const selection = props.selection;
    //login specific details ---> Details From Login ID or specific ID
    // office address , 
    const [applicant, setApplicant] = useState(variables.applicantObjects);
    textVariables.setApplicant(applicant)

    const [sifarisPersons, setSifarisPersons] = useState({ 'sender': variables.arrayToObjects(variables.sifarisPersonDetails), 'receiver': variables.arrayToObjects(variables.sifarisPersonDetails) });
    console.log(sifarisPersons)
    const [specificItems, setSpecificItems] = useState(variables.SifarisItemsObject);
    // specificItems['locallevel'] = 'नगरपालिका' // high level details from login
    console.log(specificItems)
    const [dartaData, setDartaData] = useState([]);
    const [samjhoutaData, setsamjhoutaData] = useState([]);
    const [outgoingItems, setOutgoingItems] = useState(variables.outgoingItemsObject);
    console.log(outgoingItems);






    const [currentDate, setCurrentDate] = useState(Date.getNepaliDate);
    const [applicationDate, setApplicationDate] = useState(currentDate);
    const date = { currentDate: currentDate, applicationDate: applicationDate, setCurrentDate: setCurrentDate }

    const handleApplicant = (field, value) => { handleStateUpdates(applicant, setApplicant, field, value) }
    const handleSifarisPerson = (field, value) => {
        console.log("inside sifaris person", field, value);

        handleStateUpdates(sifarisPersons, setSifarisPersons, field, value)
    }
    const handleSpecificItems = (field, value) => { handleStateUpdates(specificItems, setSpecificItems, field, value) }
    const handleOutgoingItems = (field, value) => { handleStateUpdates(outgoingItems, setOutgoingItems, field, value) }

    const listItems = [];
    let listItemsValues = variables.mainheadings;
    for (let i = 0; i < listItemsValues.length; i++) {
        let items = {};
        items.id = i;
        items.value = listItemsValues[i]
        listItems.push(items);
    }

    const [sideItems, setSideItems] = useState(listItems);

    console.log({ selection }, "first");
    const [count, setCount] = useState(0)
    // useEffect(() => {
    //     console.log('useeffect called')
    //     // setBackColor(backColor);
    // });
    const handleEffect = (event) => {
        console.log(count);
        setCount(count + 1);
        console.log("omMouseOver + value: " + event.target.value);
        props.setSelection(event.target.value);

    }

    const itemsOnLeft = sideItems.map((item) => {
        return (<button key={item.id} type='button' className='btn btn-outline-primary mukesh' onClick={handleEffect} value={item.value}>{item.value}</button>)
    });




    return (
        <div className='leftSide'>

            <aside className='asideClass sticky'>
                <ul className='list-group'>
                    {itemsOnLeft}
                </ul>
            </aside>

            <div>



            </div>
            <div className="middlepage">

                {selection === listItemsValues[5] && textVariables.hasPermissions("Sarjamin") ? <Sarjamin applicantItems={applicantItems} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} /> :
                    selection === listItemsValues[2] && textVariables.hasPermissions("Sifaris") ? <Sifaris title={selection} date={date} setApplicationDate={setApplicationDate}
                        person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} specificItems={{ "items": specificItems, "updater": handleSpecificItems }} /> :
                        selection === listItemsValues[0] && textVariables.hasPermissions("Darta") ? <Darta title={selection} date={date} setApplicationDate={setApplicationDate}
                            person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} specificItems={{ "items": specificItems, "updater": handleSpecificItems }} darta={{ "data": dartaData, "updater": setDartaData }} /> :
                            selection === listItemsValues[6] && textVariables.hasPermissions("Outgoing") ? <Outgoing person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} outgoingItems={{ "items": outgoingItems, "updater": handleOutgoingItems }} /> :
                                selection === listItemsValues[listItemsValues.indexOf("सचिवालय")] && textVariables.hasPermissions("Sachibalaya") ? <Secretariat /> :
                                    selection === listItemsValues[listItemsValues.indexOf("घर नक्सा")] && textVariables.hasPermissions("Gharnaksa") ? <Gharnaksa person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} outgoingItems={{ "items": outgoingItems, "updater": handleOutgoingItems }} /> :
                                        selection === listItemsValues[listItemsValues.indexOf("सम्झौता")] && textVariables.hasPermissions("Samjhouta") ? <Samjhouta title={selection} person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} specificItems={{ "items": specificItems, "updater": handleSpecificItems }} data={{ "data": samjhoutaData, "updater": setsamjhoutaData }} /> :
                                            selection === listItemsValues[listItemsValues.indexOf("टिप्पणी")] && textVariables.hasPermissions("Tippani") ? <Tippani title={selection} person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} specificItems={{ "items": specificItems, "updater": handleSpecificItems }} data={{ "data": samjhoutaData, "updater": setsamjhoutaData }} /> :
                                                selection === listItemsValues[listItemsValues.indexOf("आन्तरिक चिठी")] && textVariables.hasPermissions("Internal Letters") ? <Internal title={selection} person={sifarisPersons} handlePerson={handleSifarisPerson} applicant={applicant} handleApplicant={handleApplicant} date={date} setApplicationDate={setApplicationDate} specificItems={{ "items": specificItems, "updater": handleSpecificItems }} data={{ "data": samjhoutaData, "updater": setsamjhoutaData }} /> :

                                                    ''}
            </div>

        </div>
    )
}