import React, { useEffect } from 'react'
import InputBox from '../InputBox';
import { applicantObjects, firstPerson, relationToApplicant, twoNamesDefaults } from '../Variables';
import Person from '../Variables/Person';
import { getOtherPeoples } from '../Variables/TextVariables';

export default function RelationToApplicant(props) {
    let details = props.details;
    const isPersonDifferent = typeof details[relationToApplicant[0]] !== "undefined" && details[relationToApplicant[0]] !== twoNamesDefaults[1] && details[relationToApplicant[0]] !== '';
    const personMention = isPersonDifferent ? ("नीज निवेदकको " + details[relationToApplicant[0]] + " " + getOtherPeoples(details[firstPerson] ? details[firstPerson] : applicantObjects)) : "नीज निवेदक";

    let person = details[firstPerson];
    const personInput = isPersonDifferent && <div>
        <b className='p-1 mb-1  text-primary'>व्यक्तिगत विवरण</b>
        <Person person={person} handlePerson={(field, value) => {
            // person = { ...person, ...{ [field]: value } }
            props.detailsUpdater(firstPerson, { ...props.details[firstPerson], ...{ ...person, ...{ [field]: value } } });
        }} />
    </div>

    let value = details[relationToApplicant[0]];
    if (!value) value = twoNamesDefaults[1];
    useEffect(() => {
        console.log('userEffect: relationToApplicant');
        props.setRelation({ isPersonDifferent, personMention })
    }, [details[relationToApplicant[0]], details[firstPerson]]);
    return <div className="row" >
        <div className="col"><InputBox field={relationToApplicant[0]} value={value} handleChanges={(field, value) => { props.detailsUpdater(field, value) }} />
        </div>
        <hr />
        {personInput}
    </div>
}
