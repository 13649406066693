import React, { useState, useEffect } from 'react'
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry'
import { bold, DhanipurjaLandDetails } from '../Variables'

export default function Dhanipurja(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);
    const defaults = { 0: userDetails().municipality.नाम + "-" + userDetails().office["वडा नं"] }

    const tableEntry = <div>
        <h4>जग्गा विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={DhanipurjaLandDetails} defaults={defaults} details={details} detailsUpdater={props.detailsUpdater} />
    </div>
    useEffect(() => {
        props.setInputs(tableEntry)
    }, [details.inputobjects]);

    return (
        <div>
            <div>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />

            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को  तपसिलमा उल्लिखित विवरण अनुसार जग्गाधनी प्रमाणपूर्जा हराएकोले नियमानुसार गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन,२०७४ को दफा १२(२)ङ(१७) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <div>
                    <br />
                    तपसिल
                    {table}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>


        </div>
    )
}
