import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry';
import { bold, jaggaMulyankanItems, outin, reportTypes, udhyogInputs } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function LandEvaluation(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);
    const tableEntry = <div>

        <h4>जग्गाको विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={jaggaMulyankanItems} details={details} detailsUpdater={props.detailsUpdater} />
    </div>

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                {tableEntry}
                <hr />
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को तपसिलमा उल्लिखित जग्गाको मूल्यांकन/प्रमाणित देहाय बमोजिम भएको व्यहोरा {sarjin.sarjinPresent && sarjin.sarjinDetails + "का आधारमा "}स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(२६) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                तपसिल
                {table}
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
