import React, { useState } from 'react'
import InputBox from '../InputBox'
import Heading from '../ParagraphItems/Heading'
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import Sender from '../ParagraphItems/Sender'
import SelectBox from '../SelectBox'
import { getDatesDifference } from '../tests'
import { applicantObjects, bold, fiscalYears, gharjaggaItems, lekhajokhaTypes, rentDate, rentDates, rentItems, sides, SifarisItems, spaces, underline } from '../Variables'
import Person from '../Variables/Person'
import { checkCharacters, convertNumberToText, fiscalYearsLists, getApplicant, getOtherPeoples, isValidDate, officeAddress, userDetail } from '../Variables/TextVariables'



export default function RentTaxAccount(prop) {
    const props = prop.prop;
    const details = props.details;
    const lekhajokha = details.lekhajokhaWork ? details.lekhajokhaWork : lekhajokhaTypes[0];
    var firstSide = details[sides[0]] ? details[sides[0]] : applicantObjects;
    var secondSide = details[sides[1]] ? details[sides[1]] : applicantObjects;
    var firstSame = typeof firstSide['sameAsApplicant'] !== "undefined" ? firstSide['sameAsApplicant'] : true;
    var secondSame = typeof secondSide['sameAsApplicant'] !== "undefined" ? secondSide['sameAsApplicant'] : false;

    const firstPerson = firstSame ? "नीज निवेदक" : getOtherPeoples(firstSide);
    const secondPerson = secondSame ? "नीज निवेदक" : getOtherPeoples(secondSide);
    //get differnce between two rent dates
    const differenceInDates = getDatesDifference(details[rentDates[0]], details[rentDates[1]]);
    var differenceText = !(typeof differenceInDates.year === "undefined") ? differenceInDates.year + " वर्ष " + (!differenceInDates.month < 1 ? differenceInDates.month + " महिना" : "") + (!differenceInDates.day < 1 ? " " + differenceInDates.day + " दिन" : "") : "Invalid Date";
    if (differenceInDates.year < 0) differenceText = "STARTING DATE IS MORE THAN CLOSING DATE";
    console.log(differenceInDates, "differenceInDates", typeof differenceInDates.year === "undefined")

    if (firstSame)
        firstSide = { ...firstSide, ...props.applicant }
    else if (secondSame)
        secondSide = { ...secondSide, ...props.applicant }
    const rentDetails = <div className="row">
        {rentItems.map((items, index) => {
            return <div key={index} className="col"><InputBox type={"number"} field={items} value={details[rentItems[index]]} handleChanges={(field, value) => { if (!(index == 0 && value > 100)) props.detailsUpdater(field, value) }} /></div>

        })}
    </div>
    const rentDatesInput = <div className="row">
        <b className='p-1 mb-1  text-primary'>{rentDate}</b>
        {rentDates.map((items, index) => {
            return <div key={index} required={true} className="col"><InputBox maxlength={"10"} field={items} value={details[rentDates[index]]} handleChanges={(field, value) => { props.detailsUpdater(field, isValidDate(value)) }} /></div>

        })}


    </div>
    const specificinputs = lekhajokha === lekhajokhaTypes[0] ? <div className="row">
        <div className="col">{underline(sides[0])} {spaces(1)}
            <input type="checkbox" name="checkbox" id="firstsidecheckbox" checked={firstSame} onChange={(e) => {
                !secondSame && props.detailsUpdater(sides[0], { ...props.details[sides[0]], ...{ ...firstSide, "sameAsApplicant": !firstSame } });
            }} />
            <b className='p-1 mb-1  text-danger'>Same As Applicant?</b>


            <Person person={firstSide} handlePerson={(field, value) => {

                firstSide = { ...firstSide, ...{ [field]: value } }
                props.detailsUpdater(sides[0], { ...props.details[sides[0]], ...firstSide })
            }} />

        </div>
        <div className="col">{underline(sides[1])} {spaces()}
            <input type="checkbox" name="checkbox" id="secondsidecheckbox" checked={secondSame} onChange={(e) => {
                !firstSame && props.detailsUpdater(sides[1], { ...props.details[sides[1]], ...{ ...secondSide, "sameAsApplicant": !secondSame } });
            }} />
            <b className='p-1 mb-1  text-danger'>Same As Applicant?</b>
            <Person person={secondSide} handlePerson={(field, value) => {
                secondSide = { ...secondSide, ...{ [field]: value } }
                props.detailsUpdater(sides[1], { ...props.details[sides[1]], ...secondSide })
            }} />
        </div>
    </div> :
        <div className='row'>
            {fiscalYears.map((items, index) => {
                return <div className="col" key={index}>
                    <b className='p-1 mb-1  text-primary'>{fiscalYears[index]}</b>
                    <SelectBox items={items} value={details[fiscalYears[index]]} options={fiscalYearsLists()} handleChanges={(value) => props.detailsUpdater(fiscalYears[index], value)} />
                </div>

            })}
            <hr />
            <div className="col"><InputBox type={"number"} field={gharjaggaItems[0]} value={details[gharjaggaItems[0]]} handleChanges={(field, value) => { props.detailsUpdater(field, value) }} /></div>

        </div>

    const handleChange = (e) => {

        props.detailsUpdater("lekhajokhaWork", e.target.value);

    }
    const typeSelector = <div className='form-check'>
        <form className='form-check'>
            {lekhajokhaTypes.map((items, index) => {
                return (<div key={index} ><input className='form-check-input' type="radio" value={lekhajokhaTypes[index]} id={lekhajokhaTypes[index]}
                    onChange={handleChange} name="gender" />

                    <label htmlFor="male">{lekhajokhaTypes[index]}</label> <br />
                </div>
                )
            })}

        </form>
    </div>
    return (
        <div>
            <hr />
            {typeSelector}
            <hr />
            {specificinputs}
            <hr />
            {lekhajokha === lekhajokhaTypes[0] && rentDatesInput}
            <hr />
            {lekhajokha === lekhajokhaTypes[0] && rentDetails}

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={lekhajokha} date={props.date} />
                <br />

                {lekhajokha === lekhajokhaTypes[0] && <div className="content" align='justify'>
                    {props.starter} {bold(lekhajokha)} सिफारिस गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(firstPerson)} र {bold(secondPerson)} बीच भएको बहाल सम्झौता बमोजिम मिति {details[rentDates[0]]} देखि मिति {details[rentDates[1]]} सम्म जम्मा {bold(differenceText)}को बहाल कर यस {userDetail().municipality["प्रकार"]}को नियमानुसार सम्झौता रकमको {bold(details[rentItems[0]])} प्रतिशतका दरले जम्मा रु. {bold(details[rentItems[1]])}।- {bold("(अक्षरुपी ")} {bold(convertNumberToText(details[rentItems[1]]) + "रुपैयाँ मात्र )")}  बुझाइ कर चुक्ता गरेको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(३) बमोजिम प्रमाणित गरिन्छ ।
                </div>}
                {lekhajokha === lekhajokhaTypes[1] && <div className="content" align='justify'>
                    {props.starter} {bold(lekhajokha)} सिफारिस गरिदिनुहुन भनी जग्गाधनी प्रमाणपत्र र घर जग्गाको अन्य विवरण सहित दिनु भएको निवेदन अनुसार निजको आ.व. {bold(details[fiscalYears[0]])} देखि {bold(details[fiscalYears[1]])} सम्मको यस {bold(userDetail().municipality.प्रकार)}मा रहेको घर र जग्गाको तिर्नुपर्ने मालपोत/सम्पत्तिकर रु.{bold(details[gharjaggaItems[0]])}।- {bold("(अक्षरुपी ")} {bold(convertNumberToText(details[gharjaggaItems[0]]) + "रुपैयाँ मात्र )")}  (घरजग्गासँग सम्बन्धित अन्य) सबै कर चुक्ता गरेको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(६)बमोजिम सिफारिस गरिन्छ ।
                </div>}
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>

            </div>
        </div>
    )
}
