import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, personDetailsItems, spaces } from '../Variables';

export default function PersonalDetails(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const titleItems = ['व्यक्तिगत विवरण प्रमाणित', 'व्यक्तिगत विवरण सिफारिस']
    const titleName = "selectedTitle";
    const selectedTitle = details[titleName] ? details[titleName] : titleItems[0];

    let initializationObject = { [personDetailsItems[0]]: "", [personDetailsItems[1]]: "" };
    const personalDetails = details.personalDetails ? details.personalDetails : ([initializationObject])

    const [count, setCount] = useState(personalDetails.length);
    var detailsInput = [];
    let detailsCounts = 0;
    const addDetailsButton = <button className='btn btn-primary btn-lg' onClick={(e) => { setCount(count + 1) }}>ADD </button>

    while (count > detailsCounts) {

        let detailsCount = detailsCounts;
        let x = personalDetails[detailsCount] ? "" : personalDetails.push(initializationObject)
        detailsInput.push(
            <div className='row' key={detailsCount}>

                <div className="col-md-4"> <input type="text" name="person" id="person" value={(personalDetails[detailsCount][personDetailsItems[0]])} placeholder={personDetailsItems[0]} onChange={(e) => {
                    let currentObject = personalDetails[detailsCount]
                    console.log(detailsCount, "detailsCount")
                    currentObject[personDetailsItems[0]] = e.target.value;
                    console.log('personalDetails From Inside', personalDetails);
                    props.detailsUpdater("personalDetails", [...personalDetails])
                }} /></div>
                {spaces(2)}
                <div className="col-md-4 "> <input type="text" name="person" id="person" value={personalDetails[detailsCount][personDetailsItems[1]]} placeholder={personDetailsItems[1]} onChange={(e) => {
                    let currentObject = personalDetails[detailsCount]
                    currentObject[personDetailsItems[1]] = e.target.value;
                    props.detailsUpdater("personalDetails", [...personalDetails])
                }} /></div>
                <div className="col">
                    {spaces(2)}
                    <button className='col btn btn-outline-danger btn-sm' onClick={(e) => {
                        personalDetails.splice(detailsCount, 1);
                        // setpersonalDetails([...personalDetails]);
                        setCount(count - 1);
                    }}>Remove
                    </button>
                </div>


            </div>
        )

        detailsCounts++;
    }
    const tablehead = <>

        <th>क्र.सं.</th>
        <th>{personDetailsItems[0]}</th>
        <th>{personDetailsItems[1]}</th>
    </>
    const tablebody = personalDetails.map((items, index) => {
        return <tr>
            <td>{index + 1}</td>
            <td>{items[personDetailsItems[0]]}</td>
            <td>{items[personDetailsItems[1]]}</td>
        </tr>

    })

    const itemsToMap = personDetailsItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, value)
            }} />
        </div>
    })


    return (
        <div>
            <div className='row'>
                <Selection selectionTitle={titleName} options={titleItems} details={details} detailsUpdater={props.detailsUpdater} />
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                {/* {inputs} */}
                {detailsInput}
                <hr />
                {addDetailsButton}
                <hr />

                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedTitle} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {/* {props.starter} {selectedTitle} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को {details[personDetailsItems[0]]} {details[personDetailsItems[1]]} भएको व्यहोरा निवेदनसाथ संलग्न कागजात{sarjin.sarjinPresent && " तथा " + sarjin.sarjinDetails}का आधारमा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१२) बमोजिम {selectedTitle} गरिन्छ । */}
                    {props.starter} {selectedTitle} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को व्यक्तिगत विवरण देहाय बमोजिम भएको व्यहोरा निवेदनसाथ संलग्न कागजात{sarjin.sarjinPresent && " तथा " + sarjin.sarjinDetails}का आधारमा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१२) बमोजिम {selectedTitle} गरिन्छ ।
                    <br />
                    देहाय
                    <table>
                        <thead>
                            <tr>
                                {tablehead}
                            </tr>

                        </thead>
                        <tbody>
                            {tablebody}
                        </tbody>
                    </table>

                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}

