import React from 'react'
import InputBox from '../InputBox';
import { sifarisPersonDetails } from '../Variables';

export default function SenderReceiver(props) {
    const sender = props.person.sender;
    const receiver = props.person.receiver;

    const senderHeading = <h3>{props.senderHeading}</h3>;
    const receiverHeading = <h3>{props.receiverHeading}</h3>;
    const handleSender = (field, value) => {
        const updatedValue = {}; updatedValue[field] = value;
        props.handlePerson("sender", { ...sender, ...updatedValue })
    }
    const senderItems = sifarisPersonDetails.map((items, index) => {
        return (
            <div className='row' key={index}>
                <InputBox field={items} value={sender[items]} handleChanges={handleSender} />
            </div>
        );
    })
    const handleReceiver = (field, value) => {
        const updatedValue = {}; updatedValue[field] = value;
        props.handlePerson("receiver", { ...receiver, ...updatedValue })
    }
    const receiverItems = sifarisPersonDetails.map((items, index) => {

        return (
            <div className='row' key={index}>

                <InputBox inputHead="Receiver" field={items} value={receiver[items]} handleChanges={handleReceiver} />
            </div>
        );
    })
    const senderSide = props.receiverOnly !== true ? <>{senderHeading}{senderItems}</> : null;
    const receiverSide = props.senderOnly !== true ? <>{receiverHeading}{receiverItems}</> : null;

    return (
        <div className="row">

            <div className="col mr-5">
                {senderSide}
            </div>
            <div className="col ml-5">
                {receiverSide}
            </div>
        </div>
    )
}
SenderReceiver.defaultProps = {
    receiverOnly: false,
    senderOnly: false,
    receiverHeading: "Receiver",
    senderHeading: "Sender"
}
