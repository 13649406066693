import React from 'react'
import { dateWithSlash, userDetail } from '../Variables/TextVariables'

export default function ApplicationHeading(props) {
    return (
        <div>
            <div id='DateRow' align='right'> मितिः {dateWithSlash(props.date.currentDate)}</div>
            <div>
                श्रीमान् प्रमुख प्रशासकीय अधिकृत ज्यू <br />
                {userDetail().municipality.नाम} {userDetail().office.नाम} <br />
                {userDetail().municipality.नाम},  {userDetail().district}
            </div>
            <br />
            <div align="center">विषयः भुक्तानी सम्बन्धमा । </div>

        </div>
    )
}
