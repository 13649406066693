import React from 'react'
import InputBox from './InputBox';

export default function Sarjamin(props) {
    const handleChanges = (field, value) => {
        props.handleApplicant(field, value)
    }
    const queries = props.applicantItems.map((items, index) => {
        return (
            <div className='col' key={index}>
                <InputBox field={items} value={props.applicant[items]} applicantItems={props.applicantItems} handleChanges={handleChanges} />
            </div>
        );
    });
    return (
        <div className='container-fluid'>
            <hr />
            <h3>सर्जमिन</h3>
            <p>Select a Sarjamin Topic</p>

            <select name="sarjaminTopic" id="sarjaminSelected" className="form-select" aria-label="Default select example">
                <option value="अविवाहित सर्जमिन">अविवाहित सर्जमिन</option>
                <option value="जन्मदर्ता सर्जमिन">जन्मदर्ता सर्जमिन</option>
            </select>
            <p>Sarjamin Details as per Topic</p>
            <p>साक्षीहरु</p>
            <p>अन्तिम व्यहोरा</p>
        </div>
    )
}
