
import './App.css';
import Login from './MyComponents/Users/Login';

function App() {
  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route exact path="/signUp" element={<SignupPage />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter> */}
      <Login />
    </>

  );
}

export default App;
