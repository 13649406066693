import React from 'react'

export default function Spinners() {
    return (

        <div className="spinner-grow text-primary loadingCenter" role="status" style={{ width: "10rem", height: "10rem" }} >
            <span className="visually-hidden">Loading...</span>
        </div>

    )
}
