import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, feesOptions, healthItems, startEndDates } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function Health(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const healthWorkTypes = ['स्वास्थ्य उपचार'];
    const selectionTitle = "healthWork"
    const selectedTopic = details[selectionTitle] ? details[selectionTitle] : healthWorkTypes[0];

    const feesTitle = "feesOption";
    const feesOption = details[feesTitle] ? details[feesTitle] : feesOptions[0];

    const itemsToMap = [startEndDates[0], ...healthItems]
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index < 1 ? isValidDate(value) : value)
            }} />
        </div>
    })
    return (
        <div>

            <Selection selectionTitle={selectionTitle} options={healthWorkTypes} details={details} detailsUpdater={props.detailsUpdater} />

            <div className="row">
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                {inputs}
                <Selection selectionTitle={feesTitle} options={feesOptions} details={details} detailsUpdater={props.detailsUpdater} />
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />

            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedTopic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {selectedTopic} सिफारिस गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)} मिति {bold(details[startEndDates[0]])} देखि {bold(details[healthItems[0]])} रोगका कारण {details[healthItems[1]]} मा उपचाररत भएको{sarjin.sarjinPresent && " र " + sarjin.sarjinDetails + 'ले सो व्यहोरा पुष्टि गरेको हुना'}ले वहाँलाई {bold(feesOption)} स्वास्थ्य उपचार गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१०) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>

        </div>
    )
}
