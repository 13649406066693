import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry';
import { bold, hakwalaDetailsItems, hakwalaItems } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function Hakwala(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);

    const itemsToMap = hakwalaItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 0 ? isValidDate(value) : value)
            }} />
        </div>
    })
    const tableEntry = <div>
        <h4>हकवालाहरुको विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={hakwalaDetailsItems} details={details} detailsUpdater={props.detailsUpdater} />
    </div>
    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                {inputs}
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
                {tableEntry}

            </div>

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को हकदारहरु तपसिलमा उल्लेख भए बमोजिम रहेको व्यहोरा मिति {details[hakwalaItems[0]]}मा {details[hakwalaItems[1]]}बाट जारी गरिएको नाता प्रमाणित विवरण{sarjin.sarjinPresent && " तथा " + (sarjin.sarjinDetails)}का आधारमा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (२३) बमोजिम प्रमाणित गरिन्छ ।
                </div>
                <div>

                    तपसिल
                    {table}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
