import React, { useRef, useState } from 'react'
import { FormValidation } from '../FormValidation';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Applicant from '../ParagraphItems/Applicant';
import { ToastContainer } from 'react-toastify';
import Heading from '../ParagraphItems/Heading';
import { bold, programItems, sides, spaces, tippaniItems, tippaniPeople } from '../Variables';
import InputBox from '../InputBox';
import { convertNumberToText, dateWithSlash, getApplicant, isValidDate, numbersWithCommas } from '../Variables/TextVariables';
import TableEntry from '../ParagraphItems/TableEntry';
import SenderReceiver from '../ParagraphItems/SenderReceiver';
import Sender from '../ParagraphItems/Sender';
import Selection from '../ParagraphItems/Selection';

export default function Tippani(props) {
    const componentRef = useRef();
    const [table, setTable] = useState([]);
    const [activeDiv, setActiveDiv] = useState("first");
    const topic = props.title;
    const specificItems = props.specificItems.items;
    var topicObjects = specificItems[topic];
    if (typeof topicObjects === 'undefined') topicObjects = {};
    const samjhoutaItems = specificItems["सम्झौता"] || {};

    const availableOptions = ['कार्यक्रम सञ्चालन', 'भुक्तानी'];
    const selectedTitle = "selectedTitle";
    const selectedOption = topicObjects[selectedTitle] ? topicObjects[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);
    const printAction = (
        <div className='printAction'>
            <FormValidation ref={componentRef} />
        </div>
    );



    const updater = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;
        props.specificItems.updater(topic, { ...specificItems[topic], ...updatedValue });
    };

    const copyFromSamjhouta = () => {
        const copiedItems = {};
        programItems.forEach(item => {
            if (samjhoutaItems[item]) {
                copiedItems[item] = samjhoutaItems[item];
            }
        });
        let item = "टिप्पणीमा समावेश व्यक्तिहरु";
        if (samjhoutaItems[item]) {
            copiedItems[item] = samjhoutaItems[item];
        }
        props.specificItems.updater(topic, { ...specificItems[topic], ...copiedItems });
        props.handleApplicant("", { "Name": props.person.receiver.Office, "Address": props.person.receiver.Address })

        // props.handleApplicant("Address", props.person.receiver.Address)


    };
    const tableEntry = (
        <div>
            <br />
            <h4>टिप्पणीमा समावेश व्यक्तिहरु</h4>
            <TableEntry signTable={true} setTable={setTable} inputHeads={tippaniPeople} variableName="टिप्पणीमा समावेश व्यक्तिहरु" details={topicObjects} detailsUpdater={updater} />
        </div>
    );

    return (
        <div className='mukesh'>
            <button onClick={copyFromSamjhouta}>Copy from सम्झौता</button>
            <Applicant selection={props.title} applicant={props.applicant} handleApplicant={props.handleApplicant} />
            <ToastContainer />
            Tippani
            {printAction}
            <SenderReceiver person={props.person} handlePerson={props.handlePerson} senderHeading={sides[0]} receiverHeading={sides[1]} />
            <Selection selectionTitle={selectedTitle} options={availableOptions} details={topicObjects} detailsUpdater={updater} />

            {tippaniItems.map((items, index) => (
                <div key={index} required={true} className="col">
                    <InputBox
                        style={{ width: '100%' }}
                        field={items}
                        value={topicObjects[items]}
                        handleChanges={(field, value) => {
                            updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                        }}
                    />
                </div>
            ))}
            <div className="row">
                <div className="col">
                    {programItems.map((items, index) => (
                        <div key={index} required={true}>
                            <InputBox
                                style={{ width: '50%' }}
                                type={index === 2 ? "number" : "text"}
                                field={items}
                                value={topicObjects[items]}
                                handleChanges={(field, value) => {
                                    updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className="col">
                    {tableEntry}
                </div>
            </div>
            <button onClick={() => setActiveDiv(activeDiv === "first" ? "second" : "first")}>
                {activeDiv === "first" ? "कार्यादेशमा जाने" : "टिप्पणीमा जाने"}
            </button>
            <div className='businessClose' contentEditable={true} ref={activeDiv === "first" ? componentRef : null}>
                <OfficeInformation noPatra={true} chalaniName='टिप्पणी संख्या :-' headerName='टिप्पणी र आदेश' />
                <Heading receiver={{}} topic={selectedOption} date={props.date} starter={"श्रीमान्"} />
                <div className="content" align='justify'>
                    {spaces(4)} प्रस्तुत विषयमा {bold(getApplicant())}ले यस इनरुवा नगरपालिकामा {bold(topicObjects[programItems[0]])}{selectedIndex == 0 ? " गर्नका लागि प्रस्तावना" : " गरेबापत बिल बमोजिमको भुक्तानी गरिदिन निवेदन"} पेश गरेको छ। आर्थिक कार्यविधी तथा वित्तिय उत्तरदायित्व ऐन, २०७६ को दफा २४ (१) मा “अधिकार प्राप्‍त अधिकारीले स्वीकृत बजेट, कार्यक्रम र उपलब्ध स्रोत समेतको आधारमा खर्चको आदेश दिनुपर्नेछ” भन्ने व्यवस्था रहेको छ। तसर्थ {selectedIndex == 0 ? <span>अनुमानित रकम रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("( अक्षरुपी " + convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} ननाघ्ने गरी उक्त कार्यक्रम उक्त संस्थालाई सञ्चालन गर्न दिन आदेशका लागि पेश गरेको छु ।</span> : <span>{bold(topicObjects[programItems[0]])} कार्यक्रम गरेबापत {bold(getApplicant())} को नाममा जम्मा रकम रु. {numbersWithCommas(topicObjects[programItems[2]])} {bold("(अक्षरुपी ")} {bold(convertNumberToText(topicObjects[programItems[2]]) + "रुपैयाँ मात्र )")} भुक्तानी दिन आदेशका लागि निर्णयार्थ पेश गरेको छु ।
                    </span>}
                    <br />
                </div>
                {table}
            </div>

            <div className='businessClose' contentEditable={true} ref={activeDiv === "second" ? componentRef : null}>
                <OfficeInformation />
                <br />
                <Heading receiver={props.person.receiver} topic={"कार्यादेश"} date={props.date} />
                <br />
                <div className="content" align='justify'>

                    प्रस्तुत सम्बन्धमा मिति {dateWithSlash(props.date.currentDate)} गते को निर्णय र सम्झौता बमोजिम यस इनरुवा नगरपालिकामा {bold(topicObjects[programItems[0]])} नियमानुसार सम्झौता बमोजिम सम्पन्न गर्नका लागि यो कार्यादेश दिइएको छ ।

                    <br />

                </div>

                <br /> <br /> <br /> <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />
                </div>

            </div>

        </div>
    )
}
