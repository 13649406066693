import React, { useState } from 'react'
import { mainheadings } from '../Variables';
import Person from '../Variables/Person';

export default function Applicant(props) {

    const [hover, setHover] = useState();

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    const handleDetailsHeadClick = (event) => {
        console.log("I am doing my jov")
        let formItems = document.getElementById("applicant_details_elements");
        let showhide = document.getElementById("applicant_details_showhide");
        if (formItems.style.display === "none") {
            formItems.style.display = "block";
            showhide.innerText = "Hide"
        } else {
            formItems.style.display = "none";
            showhide.innerText = "Show"
        }
    }
    return (
        <div>

            <div className="row mukesh" >

                <div className="row" onClick={handleDetailsHeadClick} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>

                    <div className="col"><h5>{props.headingName}

                    </h5></div>
                    <div className="col"  >
                        <span style={{ opacity: hover ? .5 : .10 }} id='applicant_details_showhide' className="position-relative top-50 start-50 translate-middle badge rounded-pill bg-primary text-blue">
                            Hide </span>
                    </div>
                </div>



                <div className="input-group" id='applicant_details_elements'>
                    {/* {applicantQueries} */}
                    {<Person person={props.applicant} handlePerson={props.handleApplicant} />}
                </div>

            </div>
        </div>
    )
}
Applicant.defaultProps = {
    headingName: 'Applicant Details',
}
