import React from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Sender from '../ParagraphItems/Sender';
import SelectBox from '../SelectBox';
import { birthdatesInputs, birthPersonTitle, relationToApplicant, twoNamesDefaults, applicantObjects, bold, passportTitle, passport, applicantItems, nagariktaDetails, birthRelatedItems, birthRelatedTitle, birthDateChangeItems, businessCloseSpecificItems, proofPaper, asProofPaper, proofPaperSelectTitle, individualDetails, spaces } from '../Variables';
import Person from '../Variables/Person';
import { checkCharacters, getOtherPeoples, isValidDate } from '../Variables/TextVariables';

export default function BirthDate(prop) {
    const props = prop.prop;
    const details = props.details;
    const passportPresent = details.passportPresent ? details.passportPresent : false;
    const isPersonDifferent = typeof details[relationToApplicant[0]] !== "undefined" && details[relationToApplicant[0]] !== twoNamesDefaults[1] && details[relationToApplicant[0]] !== '';
    var birthPerson = details[birthPersonTitle[0]] ? details[birthPersonTitle[0]] : applicantObjects;
    const personMention = isPersonDifferent ? ("नीज निवेदकको " + details[relationToApplicant[0]] + " " + getOtherPeoples(birthPerson)) : "नीज निवेदक";
    const personInput = isPersonDifferent && <div>
        <b className='p-1 mb-1  text-primary'>व्यक्तिगत विवरण</b>
        <Person person={birthPerson} handlePerson={(field, value) => {
            birthPerson = { ...birthPerson, ...{ [field]: value } }
            props.detailsUpdater(birthPersonTitle[0], { ...props.details[birthPersonTitle[0]], ...birthPerson });
        }} />
    </div>
    const relation = <div className="row">
        {relationToApplicant.map((items, index) => {
            let value = details[relationToApplicant[0]];
            if (!value) value = twoNamesDefaults[1];
            return <div className="row" key={index}><div className="col"><InputBox field={relationToApplicant[0]} value={value} handleChanges={(field, value) => { props.detailsUpdater(field, value) }} />
            </div>
                <hr />
                {personInput}
            </div>
        })
        }

    </div>
    const birthDatesInput = <div className="row">
        {birthdatesInputs.map((items, index) => {
            return <div key={index} required={true} className="col"><InputBox maxlength={"10"} field={items} value={details[birthdatesInputs[index]]} handleChanges={(field, value) => { props.detailsUpdater(field, isValidDate(value)) }} /></div>
        })}
    </div>
    const passportItems = passportPresent && <div className='row'>

        <div>
            <b className='p-1 mb-1  text-primary'>{passportTitle}</b>
        </div>
        {passport.map((item, index) => {

            let value = "";
            try {
                value = details[passportTitle][item];
            } catch (error) {
                // console.log(error);
            }
            if (!value) value = ""
            return (
                <div className='col form-floating' key={index}>
                    <input type="text" className="form-control inputbox" field={item} value={value} placeholder={item} onChange={(e) => {
                        // console.log({ ...details[passportTitle], ...{ [item]: e.target.value } }, "ttttt")
                        let value = e.target.value;
                        if (index === 1) value = isValidDate(value);
                        props.detailsUpdater(passportTitle, { ...details[passportTitle], ...{ [item]: value } })
                    }
                    } />
                    <label htmlFor="floatingInputName" id={item + '_label'}>{item}</label>
                </div>
            )
        })}
    </div>

    const nagariktaPresent = isPersonDifferent ? birthPerson[applicantItems[6]][nagariktaDetails[0]] : props.applicant[individualDetails[2]][nagariktaDetails[0]];
    console.log({ nagariktaPresent })
    const passportCheckbox = <><input type="checkbox" id={'_checkbox'} checked={passportPresent} onChange={(e) => { props.detailsUpdater("passportPresent", e.target.checked) }} />
        <b> {passportTitle} ?</b> {spaces(5)}
    </>
    var passportDetailsPresent;
    try {
        passportDetailsPresent = details[passportTitle][passport[0]]
    } catch {
        passportDetailsPresent = false
    }
    const selectItems = <SelectBox value={details[birthRelatedTitle]} options={birthRelatedItems} handleChanges={(value) => props.detailsUpdater(birthRelatedTitle, value)} />
    const asProofItems = <SelectBox items={proofPaperSelectTitle} value={details[proofPaper]} options={asProofPaper} handleChanges={(value) => props.detailsUpdater(proofPaper, value)} />

    const birthChangeInputs = <div className="row">    {birthDateChangeItems.map((items, index) => {
        return <div className="col" key={index}>

            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                if (index < 2) value = isValidDate(value);
                props.detailsUpdater(field, value)
            }} />

        </div>
    })}
    </div>


    const isJanmaPramanit = !(details[birthRelatedTitle] === birthRelatedItems[1]);
    const selectedTopic = isJanmaPramanit ? birthRelatedItems[0] : birthRelatedItems[1];

    return (
        <div>

            <div className="row justify-content-center">
                <div className="col-6">
                    <hr />
                    {selectItems}
                    <hr />
                </div>
            </div>

            {isJanmaPramanit ? birthDatesInput : <div>

                {birthChangeInputs}

                {asProofItems}
            </div>

            }

            <hr />

            {relation}
            {passportCheckbox}
            {passportItems}
            <hr />
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedTopic} date={props.date} />
                <br />
                {isJanmaPramanit && <div className="content" align='justify'>
                    {props.starter} {selectedTopic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(personMention)} को {nagariktaPresent && "नेपाली नागरिकता"} {nagariktaPresent && passportPresent && " र "} {passportPresent && passportDetailsPresent && `राहदानी (राहदानी नं. ${details[passportTitle][passport[0]]}, जारी मिति ${details[passportTitle][passport[1]]} र स्थान ${details[passportTitle][passport[2]]})`} बमोजिम मिति {bold(details[birthdatesInputs[0]])} गते जन्म भएको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(७) बमोजिम प्रमाणित गरिन्छ ।
                </div>}
                {
                    !isJanmaPramanit && <div className="content" align='justify'>
                        {props.starter} {selectedTopic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(personMention)} को जन्म मिति {details[birthDateChangeItems[2]]} कारणले संशोधन गर्नुपर्ने भएकोले {bold(details[proofPaper] ? details[proofPaper] : "जन्म दर्ता/शैक्षिक प्रमाणपत्र/नेपाली नागरिकता प्रमाण")} बमोजिम हुने गरी एउटै कायम गर्न देहाय बमोजिम नीजको जन्म मिति संशोधन गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(७) बमोजिम सिफारिस गरिन्छ ।
                        <p></p>
                        देहाय
                        <table>
                            <thead>
                                <tr>
                                    {birthDateChangeItems.map((items) => {
                                        return <th>{items}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody className='left'>
                                <tr>
                                    {birthDateChangeItems.map((items) => {
                                        return <td>{details[items]}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>

                    </div>
                }
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
