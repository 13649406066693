import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, outin, reportTypes, udhyogInputs } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function BasicShcool(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const availableOptions = ['आधारभूत विद्यालय खोल्न सिफारिस', 'विद्यालय कक्षा थप गर्ने सिफारिस']
    const selectedTitle = "selectedTitle";
    const selectedOption = details[selectedTitle] ? details[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);

    const reportTitle = "reportOption";
    const reportOption = details[reportTitle] ? details[reportTitle] : reportTypes[0];
    const basicSchoolItems = ['वडा कार्यालयमा निर्णय भएको मिति', 'विद्यालय खोल्नु पर्ने स्थानको ठेगाना'];
    const addClassItems = ['वडा कार्यालयमा निर्णय भएको मिति', 'विद्यालयको नाम', 'थप गर्नु पर्ने कक्षा'];
    const itemsToMap = selectedIndex === 0 ? basicSchoolItems : addClassItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 0 ? isValidDate(value) : value)
            }} />
        </div>
    })

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                <Selection selectionTitle={selectedTitle} options={availableOptions} details={details} detailsUpdater={props.detailsUpdater} />

                {inputs}
                <Selection selectionTitle={reportTitle} options={[...reportTypes, ...[' ']]} details={details} detailsUpdater={props.detailsUpdater} />


                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedOption} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {selectedOption} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {selectedIndex === 0 ? <span>{details
                    [itemsToMap[1]]}मा आधारभूत विद्यालय खोल्नका लागि स्थानीय भद्रभलादमी सहितको बैठकको निर्णय अनुसार माग भई आएकोमा सो स्थानमा आधारभूत विद्यालय खोल्न उपयुक्त भएको भनी</span> : <span>विद्यालयमा कक्षा थपका लागि विद्यालय व्यवस्थापन समितिको निर्णय सहित माग भई आएकोमा सो विद्यालयमा माग बमोजिम कक्षा थपका लागि शैक्षिक पूर्वाधार समेत पूरा भएको देखिएको</span>} यस वडा कार्यालयको मिति {details[itemsToMap[0]]}मा निर्णय भएकोले सोही अनुसार {sarjin.sarjinPresent ? (reportOption !== reportTypes[0] && reportOption !== ' ' ? reportOption + " र " + sarjin.sarjinDetails : sarjin.sarjinDetails) + "को आधारमा" : reportOption + (reportOption != ' ' ? "को आधारमा" : '')} {selectedIndex === 0 ? "आधारभूत विद्यालय खोल्न" : "कक्षा " + details[itemsToMap[2]] + " थपका लागि"} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ ({selectedIndex === 0 ? "२७" : "२९"}) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
