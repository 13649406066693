import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const prevPage = () => {
        onPageChange(currentPage - 1);
    };

    const nextPage = () => {
        onPageChange(currentPage + 1);
    };

    return (
        <div>
            <button onClick={prevPage} disabled={currentPage === 1}>{"<"}</button>
            {Array.from({ length: totalPages }, (_, index) => (
                <button key={index} onClick={() => onPageChange(index + 1)} disabled={currentPage === index + 1}>
                    {index + 1}
                </button>
            ))}
            <button onClick={nextPage} disabled={currentPage === totalPages}>{">"}</button>
        </div>
    );
};

export default Pagination;
