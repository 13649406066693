import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, outin, reportTypes, udhyogInputs } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function UdhyogThauSaari(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const outInTitle = "goingOrComingOption";
    const outInOption = details[outInTitle] ? details[outInTitle] : outin[0];
    const reportTitle = "reportOption";
    const reportOption = details[reportTitle] ? details[reportTitle] : reportTypes[0];

    const itemsToMap = [...udhyogInputs, ...[outInOption + " ठेगाना"]];
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 3 ? isValidDate(value) : value)
            }} />
        </div>
    })

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />

                <Selection selectionTitle={outInTitle} options={outin} details={details} detailsUpdater={props.detailsUpdater} />
                {inputs}
                <Selection selectionTitle={reportTitle} options={[...reportTypes, ...[' ']]} details={details} detailsUpdater={props.detailsUpdater} />


                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को नाममा {details[itemsToMap[0]] ? details[itemsToMap[0]] + "मा " : ''}रहेको {details[itemsToMap[1]]} नामको उद्योग {details[outInOption + " ठेगाना"]}मा {outInOption} {sarjin.sarjinPresent ? (reportOption !== reportTypes[0] && reportOption !== ' ' ? reportOption + " र " + sarjin.sarjinDetails : sarjin.sarjinDetails) + "को आधारमा" : reportOption + (reportOption != ' ' ? "को आधारमा" : '')} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(२६) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
