import React from 'react'
import CSVFile from './CSV/csvFile'

export const Footer = () => {

    return (
        <form>
            <fieldset className='form-group border p-3'>
                <legend className='w-auto px-2 '>Footer parts Okay</legend>
                <input type="color" name="x" id="x " />
                <p>CSV Works</p>

            </fieldset>
        </form>
    )
}
