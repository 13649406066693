import React, { useState } from 'react'
import { arrayToObjects, gharnaksaPassDetails } from '../Variables';
import InputBox from '../InputBox';
import Table from '../MayorSecretariat/Table';
import { currentFiscalYear, userDetail } from '../Variables/TextVariables';
import { database } from '../ServerWorks/firebase';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import Spinners from '../InteractingItems/Spinners';

const Dates = require('../Date');

export default function Gharnaksa() {
    const [gharnaksaInputItems, setgharnaksaInputItems] = useState(arrayToObjects(gharnaksaPassDetails));
    const [gharNaksaData, setgharNaksaData] = useState([]);
    const [showloadingIcon, setLoadingIcon] = useState(false);
    const [currentDate] = useState(Dates.getNepaliDate());
    const databaseChainName = userDetail().district + "/" + userDetail().municipality.नाम + "/Ward" + userDetail().office["वडा नं"]

    // let currentFiscalYear = currentFiscalYear
    let FiscalYear = currentFiscalYear() // for searching it can take other values as well


    const handleGharnaksaInputs = (field, value) => {
        console.log("field:", field, "value:", value)
        setgharnaksaInputItems({ ...gharnaksaInputItems, ...{ [field]: value } })

    }
    const gharnaksaInputs = gharnaksaPassDetails.map((items, index) => {
        const inputItems = <div key={index} className='col-auto'>
            {items !== "कैफियत" ? <InputBox required={true} field={items} value={gharnaksaInputItems[items]} handleChanges={handleGharnaksaInputs} /> :
                <InputBox field={items} value={gharnaksaInputItems[items]} handleChanges={handleGharnaksaInputs} />}
        </div>
        return inputItems

    });
    const handleSubmit = (event) => {
        event.preventDefault();
        const docRef = doc(database, databaseChainName, FiscalYear, "Gharnaksa", "dataCount");
        getDoc(docRef)
            .then((snapshot) => {
                let dataCount;
                if (snapshot.exists()) {
                    dataCount = snapshot.data()["counts"] + 1
                } else {
                    dataCount = 1;
                }
                setLoadingIcon(true);
                let dataToWeb = { ...gharnaksaInputItems, ...{ datanum: dataCount, date: currentDate } };
                setDoc(doc(database, databaseChainName, FiscalYear, "Gharnaksa", dataCount + ""),
                    dataToWeb, { merge: true })
                    .then(() => {
                        toast('Data Done 👍');
                        setLoadingIcon(false);

                        setDoc(docRef, { counts: dataCount })
                            .then(() => {
                                // alert('Darta Count updated')
                                setgharNaksaData([...[dataToWeb], ...gharNaksaData]);
                                setgharnaksaInputItems(arrayToObjects(gharnaksaPassDetails));//reset input editTextboxes
                            })
                            .catch((error) => {
                                alert(error.message, "1");
                            })
                    })
                    .catch((error) => {
                        alert(error.message, "2");
                    });
            })
            .catch((error) => {
                alert(error.message, "3")
            })

    }
    const handleShow = () => {

        document.getElementById("showgharnaksaButton").style.display = "none";
        const naksaDataFromWeb = [];
        setLoadingIcon(true)
        getDocs(collection(database, databaseChainName, FiscalYear, "Gharnaksa"))
            .then((snapshot) => {

                snapshot.forEach((doc) => {
                    if (doc.id !== "dataCount") naksaDataFromWeb.push(doc.data())
                })
                naksaDataFromWeb.sort((a, b) => {
                    if (a.datanum > b.datanum) return 1;
                    if (a.datanum < b.datanum) return -1;
                    return 0;
                })
                // console.log("sorted", dartaDataFromWeb)
                setLoadingIcon(false)
                setgharNaksaData(naksaDataFromWeb.reverse())

            })
    }

    const tableEntry = (inputType, inputHeads, inputItems, inputBoxes) => {
        let showButtonId = "show" + inputType + "Button";
        return <>
            <fieldset>
                <form action="submit" onSubmit={(event) => handleSubmit(event, inputType, inputItems, inputHeads)}>
                    <div className="row justify-content-between">
                        {inputBoxes}
                    </div>
                    <input className='asideRight btn btn-danger' type="submit" value="थप गर्नुहोस" align='right' disabled={false} />
                </form>
            </fieldset>
            <fieldset>

                <br />
                Output Section
                <div className="d-grid gap-2 col-6 mx-auto">
                    <button type="button" className="btn btn-primary" onClick={() => handleShow(inputType)} id={showButtonId}>Show All</button>
                </div>
                {/* {populateTable(inputHeads, secretariatData[inputType])} */}
                {<Table inputType={inputType} tHeadItems={inputHeads} tBodyItems={gharNaksaData} />}
            </fieldset>

        </>
    }
    return (
        <div className='secretariat mukesh'>
            <ToastContainer />
            {showloadingIcon && <Spinners />}
            <h4>विवरण भर्नुहोस् ।</h4>

            {/* {gharnaksaInputs} */}
            {tableEntry("gharnaksa", gharnaksaPassDetails, gharnaksaInputItems, gharnaksaInputs)}




        </div>
    )
}
