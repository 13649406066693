import React, { useState } from 'react'
import ReactToPrint from 'react-to-print';
import { useRef } from 'react'
import SelectBox from '../SelectBox';
import { applicantInfo } from '../Variables/TextVariables';
import Sender from '../ParagraphItems/Sender';
var NepaliDate = require('../tests');
const variables = require('../Variables');
const bold = variables.bold;
const underline = variables.underline;
const spaces = variables.spaces;
const englishFont = variables.englishFont;


export default function Nagarikta(prop) {
    const props = prop.prop;
    const receiver = props.person.receiver;
    console.log(receiver, "receiver")
    const details = props.details
    const ko = variables.joiner[details.gender];
    const dateofBirth = details.जन्ममिति;

    let munName = bold(details.Municipality);
    let birthWard = bold(details.birthWard);

    let dobNepali = dateofBirth !== '' ? (dateofBirth.length === 2 ? (dateofBirth + " वर्ष (वि.सं. " + applicantInfo().नागरिकता[variables.nagariktaDetails[1]] + " मा)") : bold(NepaliDate.NepaliFormatNagarikta(dateofBirth))) : '.......साल........महिना........गते';

    // let father = bold(details.बुबा);
    let age = bold(NepaliDate.getAge(dateofBirth));

    let nameNepali = bold(details.नाम);
    let name = bold(details.Name);
    let localLevel = bold(details.localLevel);
    let sexNepali = bold(details.gender);
    let sex = bold(variables.sexConvert[details.gender]);
    let birthPlaceNepali = bold(details.जन्मस्थान);
    let birthPlace = englishFont(bold(details['Place of Birth']));
    let districtNepali = bold(details.जिल्ला);
    let munNameNepali = bold(details.पालिका);
    let wardNoNepali = bold(details.वडा);
    let district = bold(details.District);
    let wardNo = englishFont(wardNoNepali);
    let dob = dateofBirth !== '' ? (dateofBirth.length === 2 ? (dateofBirth + " वर्ष (वि.सं. " + applicantInfo().नागरिकता[variables.nagariktaDetails[1]] + " मा)") : englishFont(bold(NepaliDate.EnglishFormatNagarikta(NepaliDate.getAD(details.जन्ममिति))))) : '........Year........Month........Day';
    // let fatherName = 'father', motherName = 'mother', spouseName = 'spouse', protectorName = 'protector';

    let fatherName = bold(details.बुबा.नाम);

    let fatherAddress = bold(details.बुबा.ठेगाना);
    if (details.बुबा.ठेगाना === '') fatherAddress = bold(details.पालिका + '-' + details.वडा + ', ' + details.जिल्ला)
    let fatherCitizenship = bold(details.बुबा.नागरिकता);
    if (details.बुबा.नागरिकता === '') fatherCitizenship = bold('नेपाली')
    let motherName = bold(details.आमा.नाम);
    let motherAddress = bold(details.आमा.ठेगाना);
    if (details.आमा.ठेगाना === '') motherAddress = bold(details.पालिका + '-' + details.वडा + ', ' + details.जिल्ला)
    let motherCitizenship = bold(details.आमा.नागरिकता);
    if (details.आमा.नागरिकता === '') motherCitizenship = bold('नेपाली')

    let spouseName = ''; let spouseAddress = ''; let spouseCitizenship = '';
    if (details.Spouse.isRequired) {
        spouseName = bold(details.Spouse.नाम);
        spouseAddress = bold(details.Spouse.ठेगाना);
        spouseCitizenship = bold(details.Spouse.नागरिकता);
        if (details.Spouse.ठेगाना === '') spouseAddress = bold(details.पालिका + '-' + details.वडा + ', ' + details.जिल्ला)
        if (details.Spouse.नागरिकता === '') spouseCitizenship = bold('नेपाली')
    }
    let protectorName = bold(details.संरक्षक.नाम);
    let protectorAddress = ''; let protectorCitizenship = '';
    if (details.संरक्षक.नाम !== '') {
        protectorAddress = bold(details.संरक्षक.ठेगाना);
        if (details.संरक्षक.ठेगाना === '') protectorAddress = bold(details.पालिका + '-' + details.वडा + ', ' + details.जिल्ला)
        protectorCitizenship = bold(details.संरक्षक.नागरिकता);
        if (details.संरक्षक.नागरिकता === '') protectorCitizenship = bold('नेपाली')
    }
    let date = bold(props.date.currentDate)
    let salutation = bold('श्रीमान/श्रीमती ' + (details.Spouse.isRequired ? details.Spouse.नाम : details.बुबा.नाम) + "को छोरा/छोरी/पत्‍नी")
    let targetSalutation = "को श्री/सुश्री/श्रीमती "
    let nagaritaType = 'वंशज/जन्म'

    variables.nagariktaSpecificItems.otherPeoples.map(
        (otherPeople, index1) => {
            variables.individualDetails.map((detailName, index2) => {
                if (index2 !== 0) {

                    if (details[otherPeople][detailName] === '') {
                        // detailName === 'ठेगाना' ? details[otherPeople][detailName] = details.पालिका + '-' + details.वडा + ', ' + details.जिल्ला :
                        //     // details[otherPeople][detailName] = 'नेपाली'
                        //     ''
                    }
                }
            }
            )
        }
    )

    const nagarikta = {
        "heading": "अनुसूची -१",
        "sub-heading": "(नियम ३ को उप-नियम (१),(३),(३क) र (३ख), नियम ४ को उपनियम (१) र नियम १६ को उपनियम (१) संग सम्बन्धीत )",
        "toName": `श्री ${receiver.Post} ज्यू,`,
        "toAddress": `${receiver.Office}, ${receiver.Address} ।`,
        'subject': 'नेपाली नागरिकताको प्रमाण-पत्र पाउँ।',
        'salutation': 'महोदय,',
        'introInformation': 'म वंशजको नाताले/जन्मका आधारले नेपाली नागरिक भएकोले देहायको विवरण खोली नेपाली नागरिकताको प्रमाण-पत्र पाउनको लागी सिफारिस साथ रु. _ticketAmount।- को टिकट टाँसी यो निवेदन पत्र पेश गरेको छु । मैले यस अघि नेपाली नागरिकताको प्रमाण-पत्र लिएको छैन ।',
        'ticketAmount': '१०',

        'name-Nepali': '१. नाम, थर:- ',
        'name': 'Full Name (in block):- ',
        'sex-Nepali': '२. लिङ्ग:- ',
        'sex': 'Sex:- ',
        'birthPlace-Nepali': '३. जन्मस्थान:- ',
        'birthPlace': 'Place of Birth (Block):- ',
        'permanentAddress-Nepali': '४. स्थायी बासस्थानः- जिल्ला ',
        'munName-Nepali': 'गा.पा/उपमहा/महा/न.पा. ',
        'wardNo-Nepali': 'वडा नं ',
        'permanentAddress': 'Permanent Address: District ',
        'munName': 'VDC/Municipality/Sub/Metropolis ',
        'wardNo': 'Ward No ',
        'dob-Nepali': '५. जन्म मितिः- ',
        'dob': 'Date of Birth (AD):- ',
        'dob-info': '(शैक्षिक प्रमाण-पत्र हुनेले सोही उल्लेख गर्ने)',

        'father-name': '६. बाबुको नाम, थरः- ',
        'father-address': 'ठेगानाः- ',
        'father-citizenship': 'नागरिकता:- ',
        'mother-name': '७. आमाको नाम, थरः- ',
        'mother-address': 'ठेगानाः- ',
        'mother-citizenship': 'नागरिकता:- ',
        'spouse-name': '८. पति/पत्‍नीको नाम, थरः- ',
        'spouse-address': 'ठेगानाः- ',
        'spouse-citizenship': 'नागरिकता:- ',
        'protector-name': '९. संरक्षकको नाम, थरः- ',
        'protector-address': 'ठेगानाः- ',
        'protector-citizenship': 'नागरिकता:- ',


        'endInformation': 'मैले माथि लेखिदिएको व्यहोरा ठीक साँचो हो । झुट्टा ठहरे कानून बमोजिम सहुँला बुझाउँला ।',
        'fingerprints': 'औंठाको छाप',
        'end-salution': 'भवदीय',
        'applicantSign': 'निवेदकको दस्तखत:- ',
        'date': 'मिति:- ',

        'municipality': 'गाउँपालिका/ उपमहानगरपालिका/महानगरपालिका/नगरपालिकाको सिफारिस',
        'wardOffice': '/ २ नं. वडा कार्यालयको सिफारिस',
        'officeNameAndStamp': 'कार्यालयको नाम र छाप:- ',
        'sifarisDoer': 'सिफारेिस गर्नेको:- ',
        'sign': 'दस्तखत:- ',
        'nameAddress': 'नाम, थर:- ',
        'designation': 'पद',


    };

    nagarikta.introInformation = nagarikta.introInformation.replace('_ticketAmount', nagarikta.ticketAmount)


    const componentRef = props.componentRef;
    const cleanFormat = typeof details.cleanFormat ? details.cleanFormat : false;
    const pratilipi = typeof details.pratilipi ? details.pratilipi : false;
    const localLevelType = details.localLevelType ? details.localLevelType : " "
    var itemsForCleanFormat = '';

    if (cleanFormat) {
        itemsForCleanFormat = <SelectBox options={variables.localLevelTypes} handleChanges={(value) =>
            props.detailsUpdater("localLevelType", value)
        } value={localLevelType} items='पालिका प्रकार' />
        nagarikta['munName-Nepali'] = localLevelType + " "
        nagarikta.munName = variables.localLevelTypeConvert[localLevelType] + " "
        nagarikta.introInformation = details.type === 'वंशज' ? nagarikta.introInformation.replace('/जन्मका आधारले', '') : details.type === 'जन्म' ? nagarikta.introInformation.replace('वंशजको नाताले/', '') : nagarikta.introInformation
        nagarikta['spouse-name'] = details.gender === 'महिला' ? nagarikta['spouse-name'].replace('/पत्‍नी', '') : details.gender === 'पुरुष' ? nagarikta['spouse-name'].replace('पति/', '') : nagarikta['spouse-name']
        salutation = bold((details.बुबा.नाम !== '' ? ('श्रीमान ' + details.बुबा.नाम + (details.आमा.नाम !== '' ? ' श्रीमती ' + details.आमा.नाम : '')) : (details.आमा.नाम !== '' ? ' श्रीमती ' + details.आमा.नाम : '')) + ko + " " + variables.childCalling[details.gender] + (details.Spouse.isRequired ? (" " + variables.spouseSalutation[details.gender] + " " + details.Spouse.नाम + ko + " " + variables.spouseCalling[details.gender]) : ''))

        targetSalutation = ko + " " + (details.gender === 'पुरुष' ? 'श्री ' : details.Spouse.isRequired ? 'श्रीमती ' : 'सुश्री ')
        nagaritaType = bold(details.type)

    }
    return (

        <div>
            <div className='row'>
                <div className="col">
                    <input type="checkbox" name="cleanFormat" id="cleanFormat" onChange={(e) => props.detailsUpdater("cleanFormat", e.target.checked)} />
                    <label htmlFor="cleanFormat"> Clean Format</label>

                    {spaces(5)}
                    <input type="checkbox" name="pratilipi" id="pratilipi" onChange={(e) => props.detailsUpdater("pratilipi", e.target.checked)} />
                    <label htmlFor="pratilipi">नागरिकता प्रतिलिपि</label>

                    <br />
                    <label htmlFor="">Local level Type: </label>
                    {itemsForCleanFormat}
                </div>

            </div>
            <div className='printAction'>
                {/* <ReactToPrint
                    trigger={() => <button className="btn btn-danger" type="submit">Submit</button>}
                    content={() => componentRef.current}
                /> */}
            </div>
            {!pratilipi ?
                <div className='nagarikta' ref={componentRef} >
                    <div className='onePage'>

                        {nagarikta.heading} <br />
                        {nagarikta['sub-heading']}
                        <p>
                            {nagarikta.toName} <br />
                            {nagarikta.toAddress}
                        </p>
                        <div className="rect photo">फोटो </div>
                        विषयः- {bold(underline(nagarikta.subject))}<br />
                        <p>{nagarikta.salutation} <br />
                            {spaces(5)}{nagarikta.introInformation}</p>
                        <div className="row left">
                            <div className="col solidBorder">
                                {nagarikta['name-Nepali']} {nameNepali} <br />
                                {nagarikta.name} {name} <br />
                                {nagarikta['sex-Nepali']}{sexNepali} <br />
                                {nagarikta.sex}{sex} <br />
                                {nagarikta['birthPlace-Nepali']} {birthPlaceNepali}<br />
                                {nagarikta.birthPlace} {birthPlace}<br />
                                {nagarikta['permanentAddress-Nepali']}{districtNepali} <br />
                                {nagarikta['munName-Nepali']}{munNameNepali}&nbsp;
                                {nagarikta['wardNo-Nepali']}{wardNoNepali} <br />
                                {nagarikta.permanentAddress}{district} <br />
                                {nagarikta.munName}{munName}&nbsp;
                                {nagarikta.wardNo}{wardNo} <br />
                                {nagarikta['dob-Nepali']}{dobNepali} <br />
                                {nagarikta.dob}{dob} <br />
                                {nagarikta['dob-info']}

                            </div>
                            <div className="col solidBorder">
                                {nagarikta['father-name']}{fatherName} <br />
                                {nagarikta['father-address']}{fatherAddress} <br />
                                {nagarikta['father-citizenship']}{fatherCitizenship} <br />
                                {nagarikta['mother-name']}{motherName} <br />
                                {nagarikta['mother-address']}{motherAddress} <br />
                                {nagarikta['mother-citizenship']}{motherCitizenship} <br />
                                {nagarikta['spouse-name']}{spouseName} <br />
                                {nagarikta['spouse-address']}{spouseAddress} <br />
                                {nagarikta['spouse-citizenship']}{spouseCitizenship} <br />
                                {nagarikta['protector-name']}{protectorName} <br />
                                {nagarikta['protector-address']}{protectorAddress} <br />
                                {nagarikta['protector-citizenship']}{protectorCitizenship} <br />
                            </div>
                        </div>
                        <p>{nagarikta.endInformation}</p>
                        <div className="row left">
                            <div className="col left">
                                {nagarikta.fingerprints} <br />
                                <div className="row left ">
                                    <div className="rect block"></div>
                                    <div className="rect block"></div>
                                </div>

                            </div>
                            <div className="col col-lg-3">

                                {nagarikta['end-salution']} <br />
                                {nagarikta.applicantSign} <br />
                                {nagarikta.date} {date}
                            </div>
                        </div>

                        {underline(nagarikta.municipality)}
                        <p>
                            {spaces(5)}{/*munNameNepali*/ birthPlaceNepali}  मा मिति {bold(dateofBirth)} मा जन्म भई हाल {munNameNepali} {localLevel} वडा नं {wardNoNepali} मा स्थायी रुपमा बसोबास गरी आएका यसमा लेखिएका {salutation} {
                            } वर्ष {age} {targetSalutation} {nameNepali}लाई म राम्ररी चिन्दछु । उपर्युक्त लेखिए बमोजिमको निजको व्यहोरा मैले जाने बुझेसम्म साँचो हो । निजलाई {nagaritaType}का आधारले नागरिकताको प्रमाण-पत्र दिए हुन्छ । उक्त विवरण झुट्टा ठहरे कानून बमोजीम सहुँला बुझाउँला ।
                        </p>
                        <div className="row">
                            <div className="col left">
                                मितिः {date} <br />
                                कार्यालयको नाम र छापः
                            </div>

                            <div className="col col-lg-4 left">
                                <br />
                                दस्तखत .............. <br />
                                नाम,थरः {props.person.sender['Name']}<br />
                                पदः {props.person.sender['Post']}
                            </div>
                        </div>
                    </div>
                    <div className='onePage'>
                        <b><u>गाउँपालिका/उपमहा/महा/नगरपालिकाबाट सर्जमिन र सनाखतका लागि प्रेषित</u></b>
                        <p>....................गाउँ विकास समिति/नगरपालिका/उपमहानगरपालिका/महानगरपालिका
                            वडा नं.....बस्ने....................को छोरा/छोरी/पत्नी वर्ष......को
                            श्री/सुश्री/श्रीमती....................ले ऐन र नियमले तोकेको प्रमाण
                            पेश गर्न नसकेकोले निजलाई स्थलगत सर्जमीन र सनाखतको प्रक्रिया अवलम्वन गरी वंशजको
                            नाता/जन्मको आधारमा नागरिकता सम्बन्धी निर्णय हुन प्रेषित गरिएको छ । </p>
                        <div className="row">
                            <div className="col left">
                                मितिः <br />
                                कार्यालयको छापः
                            </div>

                            <div className="col col-lg-4 left">
                                <br />
                                दस्तखत .............. <br />
                                नाम,थर ..............<br />
                                पद ..............
                            </div>
                            <p></p>
                            <b><u>कार्यालयले भर्ने</u></b>

                            <div className="row">
                                <div className="col solidBorder left">निवेदक/निवेदिका  ........................................ मेरो आफ्नो .............. नाताका हुन् । निजलाई वंशजको नाताले/जन्मको आधारले नेपाली नागरिकताको प्रमाणपत्र दिएमा पछिसम्म फरक पर्ने छैन ।फरक परेमा कानून बमोजीम सहुँला बुझाउँला भनी कार्यालयमा उपस्थित भई सनाखत सहिछाप गर्नेकोः- <br />
                                    नामः .......................................................... <br />
                                    ना.प्र.नं. ................................................... <br />
                                    सहिछापः ................................................. <br />
                                    मितिः .................................................... <br />
                                </div>
                                <div className="col solidBorder left">माथिको विवरण बमोजीम मेरो रोहबरमा सनाखत सहीछाप भएको ठीक साँचो हो । माथिको विवरण ठीक दुरुस्त छ, फरक छैन । फरक परेका कानून बमोजिम सहन बुझाउन मन्जुर छु भनी सहिछाप गर्नेको निवेदकको ।<br />
                                    नामः ..................................................... <br />
                                    सहिछापः ................................................. <br />
                                    माथिः ...................................................... <br />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col solidBorder ">
                                    <u><b>सर्जमिनमा बस्नेको नाम,थर र ठेगानाः</b></u><br />
                                    <div className='left'>
                                        १. <br />
                                        २. <br />
                                        ३. <br />
                                        ४. <br />
                                        ५.
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col solidBorder">
                                    <b><u>सनाखत गर्ने व्यक्तिको नाम,थर र ठेगानाः</u></b> <br />
                                    <div className='left'>
                                        १. <br />
                                        २. <br />
                                        ३. <br />
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <b><u>निर्णय</u></b>
                                <div className="left">
                                    यो अनुसूचीमा भएको सिफारिस, निवेदकको .......................................... को सनाखत सहिछाप र संलग्न निम्न प्रमाणहरुको आधारमा निवेदकलाई वंशजको नाताले/जन्मको आधारले नेपाली नागरिकताको प्रमाणपत्र दिन उपयुक्त हुने देखि निर्णयार्थ पेश गरेको छु ।
                                </div>

                                <b><u>संलग्न प्रमाणहरु</u></b>
                                <div className="left">
                                    1. शिशुको जन्म दर्ता प्रमाण-पत्र <br />
                                    2. शिशुको शैक्षिक प्रमाण-पत्र <br />
                                    3. बाबुको नागरिकता <br />
                                    ४. आमाको नागरिकता <br />
                                    ५. आमाबाबुको विवाह दर्ता प्रमाण-पत्र <br />
                                    ६. बसाईसराई प्रमाण-पत्र <br />
                                    ७. स्थायी बसोबास सिफारीस
                                </div>

                                <div className="row">
                                    <div className="col">..............  <br />वितरित ना.प्र.नं. <br /> मिति </div>
                                    <div className="col">..............  <br />सनाखत गराउने</div>
                                    <div className="col">..............  <br />पेश गर्ने</div>
                                    <div className="col">..............  <br />सदर गर्ने </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> :
                <div className='nagarikta onePage' ref={componentRef}>
                    <br />
                    <br />
                    <p>
                        {nagarikta.toName} <br />
                        {nagarikta.toAddress}
                    </p>
                    <div className="rect photo pratilipiphoto">फोटो </div>
                    विषयः- {bold(underline("नेपाली नागरिकताको प्रमाण-पत्रको प्रतिलिपि पाऊँ ।"))}<br />
                    <div className='left'>
                        {nagarikta.salutation} <br />
                    </div>
                    <div className="left">{spaces(5)} मैले .............अञ्चलाधीशको कार्यालय/...............गोश्वारा कार्यालय र यसै कार्यालयबाट देहायको विवरण भएको नेपाली नागरिकता प्रमाण-पत्र लिएकोमा सो प्रमाण-पत्रको सक्कल झुत्रो भएको/हराएको/नयाँ ढाँचाको आवश्यकता भएको हुदाँ सोको प्रतिलिपी पाउनका लागि सो नागरिकता प्रमाण-पत्रको सक्कल/नक्कल प्रति संलग्न राखी रु. 13/- को टिकट टाँसी सिफारिस सहित यो निवेदन पेश गरेको छु ।
                    </div>
                    <div className='left'> मैले नागरिकता प्रमाणपत्र लिदाँको विवरण यस प्रकार छ ।</div>


                    <div className="row left g-2">
                        <div className="col-auto">ना.प्र.नं.: {bold(applicantInfo().नागरिकता[variables.nagariktaDetails[0]])}</div>
                        <div className="col-auto">जारी मितिः {bold(applicantInfo().नागरिकता[variables.nagariktaDetails[1]])}</div>
                        <div className="col-auto">जिल्लाः {bold(applicantInfo().नागरिकता[variables.nagariktaDetails[2]])}</div>
                        <div className="col-auto">किसिमः {bold(details.type)}</div>
                    </div>
                    <div className="row left">
                        <div className="col solidBorder">
                            {nagarikta['name-Nepali']} {nameNepali} <br />
                            {nagarikta.name} {name} <br />
                            {nagarikta['sex-Nepali']}{sexNepali} <br />
                            {nagarikta.sex}{sex} <br />
                            {nagarikta['birthPlace-Nepali']} {birthPlaceNepali}<br />
                            {nagarikta.birthPlace} {birthPlace}<br />
                            {nagarikta['permanentAddress-Nepali']}{districtNepali} <br />
                            {nagarikta['munName-Nepali']}{munNameNepali}&nbsp;
                            {nagarikta['wardNo-Nepali']}{wardNoNepali} <br />
                            {nagarikta.permanentAddress}{district} <br />
                            {nagarikta.munName}{munName}&nbsp;
                            {nagarikta.wardNo}{wardNo} <br />
                            {nagarikta['dob-Nepali']}{dobNepali} <br />
                            {nagarikta.dob}{dob} <br />
                            {/* {nagarikta['dob-info']} */}
                            ६. बुवाको नाम, थर र वतनः {fatherName}, {fatherAddress} {spaces(5) + "नागरिकताः "}{fatherCitizenship} <br />
                            ७. आमाको नाम, थर र वतनः {motherName}, {motherAddress} {spaces(5) + "नागरिकताः "}{motherCitizenship}<br />
                            {nagarikta['spouse-name'].replace(':-', ' र वतनः ')} {spouseName}, {spouseAddress} {spaces(5) + "नागरिकताः "}{spouseCitizenship}<br />

                        </div>
                        <div>
                            <p>माथि लेखिएको विवरण मैले {receiver.Office},{applicantInfo().नागरिकता[variables.nagariktaDetails[2]]}बाट लिएको नं. {applicantInfo().नागरिकता[variables.nagariktaDetails[0]]} को ना.प्र.प. को व्यहोरासंग दुरुस्त छ, फरक छैन । लेखिएको विवरण झुठा ठहरेमा कानून बमोजिम सहुँला बुझाउँला ।</p>
                            <div className="row left">
                                <div className="col left">
                                    {nagarikta.fingerprints} <br />
                                    <div className="row left ">
                                        <div className="rect block"></div>
                                        <div className="rect block"></div>
                                    </div>

                                </div>
                                <div className="col col-lg-3">

                                    {nagarikta['end-salution']} <br />
                                    {nagarikta.applicantSign} <br />
                                    {nagarikta.date} {date}
                                </div>
                            </div>
                            {underline(nagarikta.municipality)}
                            <p>
                                {spaces(5)}{/*munNameNepali*/ birthPlaceNepali}  मा मिति {bold(dateofBirth)} मा जन्म भई हाल {munNameNepali} {localLevel} वडा नं {wardNoNepali} मा स्थायी रुपमा बसोबास गरी आएका यसमा लेखिएका {salutation} {
                                } वर्ष {age} {targetSalutation} {nameNepali}लाई म राम्ररी चिन्दछु । उपर्युक्त लेखिए बमोजिमको निजको व्यहोरा मैले जाने बुझेसम्म साँचो हो । निजलाई {nagaritaType}का आधारले नागरिकताको प्रमाण-पत्र दिए हुन्छ । उक्त विवरण झुट्टा ठहरे कानून बमोजीम सहुँला बुझाउँला ।
                            </p>
                            <div className="row">
                                <div className="col left">
                                    मितिः {date} <br />
                                    कार्यालयको नाम र छापः
                                </div>

                                <div className="col col-lg-4 left">

                                    दस्तखतः .............. <br />
                                    नाम,थरः {props.person.sender['Name']}<br />
                                    पदः {props.person.sender['Post']}
                                </div>
                            </div>
                        </div>
                    </div>
                </ div>
            }


        </div>


    )
}
Nagarikta.defaultProps = {
    date: '2071-02-07',
    details: {
        birthWard: '२',
        dobNepali: '२०५०-११-०१',
        dob: '1994-02-13',
        age: '२५',
        nameNepali: 'मुकेश पोखरेल',
        name: 'Mukesh Pokharel',
        localLevel: 'गाउँपालिका',
        sexNepali: 'पुरुष',
        sex: "Male",
        birthPlaceNepali: "खार्पा-२, खोटाङ्ग",
        birthPlace: "Kharpa-2, Khotang",
        districtNepali: 'खोटाङ्ग',
        district: 'Khotang',
        munNepali: 'गाँउपालिका',
        mun: 'V.D.C.',
        munNameNepali: 'रावावेँशी',
        munName: 'Rawabeshi',
        wardNoNepali: '२',
        wardNo: '2'
    },
    father: {
        name: 'राजन प्रसााद पोखरेल',
        address: 'खार्पा-२, खोटाङ्ग',
        citizenship: 'नेपाली'
    },
    mother: {
        name: 'गंगा देवि काफ्ले',
        address: 'खार्पा-२, खोटाङ्ग',
        citizenship: 'नेपाली'
    },
    spouse: {
        name: 'आकांक्षा अधिकारी',
        address: 'खार्पा-२, खोटाङ्ग',
        citizenship: 'नेपाली'
    },
    protector: {
        name: 'राजन प्रसााद पोखरेल',
        address: 'खार्पा-२, खोटाङ्ग',
        citizenship: 'नेपाली'
    },


    searchBar: true
}
