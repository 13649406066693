import React from 'react'

export default function Sender(props) {
    const sender = props.person.sender;
    const sname = sender['Name']
    const spost = sender['Post']
    const soffice = sender['Office']
    const saddress = sender['Address']

    if (props.commaSeparated)
        return `${sname}, ${spost}, ${soffice}`;
    return (
        <div align='center'>
            ...........................
            <br />
            {sname} {sname ? <br /> : ''}
            {spost} {spost ? <br /> : ''}
            {soffice} {soffice ? <br /> : ''}
            {saddress} {saddress ? <br /> : ''}

        </div>
    )
}
Sender.defaultProps = {
    commaSeparated: false,
}
