import { setuseDetailsInTextVariables } from "./Variables/TextVariables";

var details = {
    userName: 'मुकेश पोखरेल',
    office: {
        प्रकार: 'वडा कार्यालय',
        ठेगाना: 'देशी लाईन, धरान, सुनसरी, प्रदेश नं १, नेपाल',
        engAddress: 'Deshi Line, Dharan Sunsari, Province No. 1, Nepal',
        नाम: '२ नं वडा कार्यालय',
        engName: '2 No. Ward Office',
        फोन: '025532323',
        email: 'ward2@dharan.gov.np'
    },
    province: 'प्रदेश नं १',
    district: 'सुनसरी',
    municipality: {
        नाम: 'धरान',
        प्रकार: 'उपमहानगरपालिका',
        engName: 'Dharan',
        engType: 'Sub-metropolitan city'
    },
    "वडा नं": '२'
}
export const setuserDetails = (userDetails) => {
    details = userDetails;
    console.log("login details changed", details)
    setuseDetailsInTextVariables(details)
}
export const userDetails = () => { return details; }

console.log("inside userDetails")

