import React, { useState } from 'react'
import InputBox from '../InputBox';
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry';
import { applicantObjects, bold, hakwalaDetailsItems, hakwalaItems, LandDetails, naamSaariHakwalItems } from '../Variables';
import Person from '../Variables/Person';
import { isValidDate } from '../Variables/TextVariables';

export default function NaamSaari(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);
    const [table_land, setTable_Land] = useState([]);

    const titleItems = ['नामसारी सिफारिस', 'जग्गाको हक सम्बन्धमा सिफारिस'];
    const titleName = "selectedTitle";
    const selectedTitle = details[titleName] ? details[titleName] : titleItems[0];
    const selectedIndex = titleItems.indexOf(selectedTitle);



    const itemsToMap = hakwalaItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 0 ? isValidDate(value) : value)
            }} />
        </div>
    })
    const tableEntry = <div>

        <h4>{selectedIndex === 0 ? "मृतकका " : ''}हकदारहरुको विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={naamSaariHakwalItems} details={details} detailsUpdater={props.detailsUpdater} />
    </div>

    const defaults = { 0: userDetails().municipality.नाम + "-" + userDetails().office["वडा नं"] }
    const tableEntry_land = <div>
        <h4>घर/जग्गाको विवरण</h4>
        <TableEntry setTable={setTable_Land} inputHeads={LandDetails} defaults={defaults} details={details} detailsUpdater={props.detailsUpdater} />
    </div>



    const deceasedPerson = details["deceased"] ? details["deceased"] : applicantObjects;
    const deceasedPersonInput = <div>

        <b className='p-1 mb-1  text-primary'>मृतकको विवरण:</b>
        <Person person={deceasedPerson} handlePerson={(field, value) => {
            props.detailsUpdater("deceased", { ...props.details["deceased"], ...{ ...deceasedPerson, ...{ [field]: value } } });
        }} />

    </div>

    return (
        <div>
            <div className='row'>
                <Selection selectionTitle={titleName} options={titleItems} details={details} detailsUpdater={props.detailsUpdater} />

                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                {/* {deceasedPersonInput} */}
                <hr />
                {inputs}
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
                {tableEntry}
                <hr />
                {tableEntry_land}

            </div>

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} noending={selectedIndex === 0 ? false : true} topic={selectedTitle} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {selectedTitle} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को {selectedIndex === 0 ? "मृत्यु भएको हुनाले नीज मृतकका" : ''} नाममा रहेको देहाय बमोजिमको घर/जग्गा मिति {details[hakwalaItems[0]]}मा {details[hakwalaItems[1]]}बाट जारी गरिएको नाता प्रमाणित विवरण{sarjin.sarjinPresent && " तथा " + (sarjin.sarjinDetails)}का आधारमा प्रचलित कानून बमोजिम {selectedIndex === 0 ? "हकदारहरुको नाममा नामसारीको लागि" : "सो घर/जग्गाको हक देहाय बमोजिम हुन"} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (२४) बमोजिम प्रमाणित गरिन्छ ।
                </div>
                <div>
                    <br />
                    <h6> {selectedIndex === 0 ? "मृतकका " : ''}हकदारहरुको विवरण</h6>
                    {table}
                    <br />
                    <h6>घर/जग्गाको विवरण</h6>
                    {table_land}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
