import React from 'react'
import SelectBox from '../SelectBox'

export default function Selection(props) {
    let details = props.details;

    return (
        <div className="row justify-content-center" key={1}>
            <div className="col-6">
                <hr />
                <SelectBox value={details[props.selectionTitle]} options={props.options} handleChanges={(value) => props.detailsUpdater(props.selectionTitle, value)} />
                <hr />
            </div>
        </div>
    )
}
