import React from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Sender from '../ParagraphItems/Sender';
import { bold, sarjaminDate } from '../Variables';
import { checkCharacters, isValidDate } from '../Variables/TextVariables';

export default function MohiLagatKatta(prop) {
    const props = prop.prop;
    const details = props.details;
    const sarjaminMiti = <div className="row">
        <InputBox maxlength={"10"} field={sarjaminDate} value={details[sarjaminDate]} handleChanges={(field, value) => { props.detailsUpdater(field, isValidDate(value)) }} />
    </div>
    return (
        <div>
            {sarjaminMiti}
            <hr />
            <div className='businessClose' ref={props.componentRef}>

                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} मोहीको लगत कट्टाको सिफारिस गरिदिन भनी जग्गाधनी प्रमाणपत्र, जग्गाको प्रमाणित नापी नक्सा सहित दिएको निवेदन अनुसार मिति {bold(details[sarjaminDate])} मा गरिएको सर्जमिन मुचुल्का, मोहीको मञ्जुरीनामा समेतको आधारमा नियमानुसार लगत कट्टा गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(५) बमोजिम सिफारिस गरिएको व्यहोरा अनुरोध छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
