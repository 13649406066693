import React, { useEffect, useState } from 'react'
import InputBox from '../InputBox';
import { applicationItems, arrayToObjects, invitationHeadings, participatedPrograms } from '../Variables';
import './style.css';
import { database } from '../ServerWorks/firebase';
import { userDetail } from '../Variables/TextVariables';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import Spinners from '../InteractingItems/Spinners';
import Table from './Table';
const Dates = require('../Date');

export default function Secretariat() {
  const [applicationInputItems, setApplicationInputItems] = useState(arrayToObjects(applicationItems))
  const [invitationInputHeadings, setInvitationInputHeadings] = useState(arrayToObjects(invitationHeadings))
  const [participatedProgramsInput, setParticipatedProgramsInput] = useState(arrayToObjects(participatedPrograms))
  const [secretariatData, setSecretariatData] = useState({ "applicantInput": [], "invitations": [], "participations": [] });
  const [showloadingIcon, setLoadingIcon] = useState(false);
  const [currentDate] = useState(Dates.getNepaliDate);


  const databaseChainName = userDetail().district + "/" + userDetail().municipality.नाम + "/नगरपालिका";
  let currentFiscalYear = "080-81"
  let FiscalYear = currentFiscalYear // for searching it can take other values as well
  // console.log({ secretariatData });
  // console.log({ invitationInputHeadings })
  // console.log({ applicationInputItems })
  // console.log({ participatedProgramsInput })
  const handleApplicationInputs = (field, value) => {
    console.log("field:", field, "value:", value)
    setApplicationInputItems({ ...applicationInputItems, ...{ [field]: value } })

  }
  const handleInvitationInputs = (field, value) => {
    console.log("field:", field, "value:", value, "inputHeadings:", invitationInputHeadings)
    setInvitationInputHeadings({ ...invitationInputHeadings, ...{ [field]: value } })

  }
  const handleParticipationInputs = (field, value) => {
    console.log("field:", field, "value:", value)
    setParticipatedProgramsInput({ ...participatedProgramsInput, ...{ [field]: value } })

  }
  const handleSubmit = (event, inputType, inputData) => {

    event.preventDefault();
    //add info to secretariat data variable
    // Create a new copy of the applicantInput array with the new items
    const updatedApplicantInput = [
      ...secretariatData[inputType]]
    updatedApplicantInput.push(inputData);

    // Create a new object with the updated applicantInput array and the other properties
    const updatedSecretariatData = {
      [inputType]: updatedApplicantInput

    };
    // Set the state with the updated object
    setSecretariatData({ ...secretariatData, ...updatedSecretariatData });
    // setApplicationInputItems(arrayToObjects(applicationItems));
    inputType === "applicantInput" ? setApplicationInputItems(arrayToObjects(applicationItems)) :
      inputType === "participations" ? setParticipatedProgramsInput(arrayToObjects(participatedPrograms)) :
        setInvitationInputHeadings(arrayToObjects(invitationHeadings))
    // SAVE TO DATABASE

    const docRef = doc(database, databaseChainName, FiscalYear, "Mayor", "सचिवालय", inputType, "dataCount")

    getDoc(docRef)
      .then((snapshot) => {
        let dataCount;
        if (snapshot.exists()) {
          dataCount = snapshot.data()["counts"] + 1
        } else {
          dataCount = 1;
        }
        setLoadingIcon(true);
        let dataToWeb = { ...inputData, ...{ datanum: dataCount, date: currentDate } };

        setDoc(doc(database, databaseChainName, FiscalYear, "Mayor", "सचिवालय", inputType, dataCount + ""),
          dataToWeb, { merge: true })
          .then(() => {
            toast('Data Done 👍');
            setLoadingIcon(false);
            // sendEmail(inputType, inputData, inputHeads);
            setDoc(docRef, { counts: dataCount })
              .then(() => {



                // // alert('Darta Count updated')
                // props.darta.updater([...[dataToWeb], ...dartaDatas]);
                // setDartaItem(arrayToObjects(dartaItems));//reset input editTextboxes
              })
              .catch((error) => {
                alert(error.message);
              })
          })
          .catch((error) => {
            alert(error.message);
          });
      })
      .catch((error) => {
        alert(error.message)
      });



  }
  const handleShow = (inputType) => {
    document.getElementById("show" + inputType + "Button").style.display = "none";
    const dartaDataFromWeb = [];
    setLoadingIcon(true)
    getDocs(collection(database, databaseChainName, FiscalYear, "Mayor", "सचिवालय", inputType))
      .then((snapshot) => {

        snapshot.forEach((doc) => {
          if (doc.id !== "dataCount") dartaDataFromWeb.push(doc.data())
        })
        dartaDataFromWeb.sort((a, b) => {
          if (a.datanum > b.datanum) return 1;
          if (a.datanum < b.datanum) return -1;
          return 0;
        })

        setLoadingIcon(false);

        const updatedSecretariatData = {
          [inputType]: dartaDataFromWeb.reverse()

        };
        // Set the state with the updated object
        setSecretariatData({ ...secretariatData, ...updatedSecretariatData });
        // setApplicationInputItems(arrayToObjects(applicationItems));
      })
  }


  const applicationInputs = applicationItems.map((items, index) => {
    const inputItems = <div key={index} className='col-auto'>
      {items !== "कैफियत" ? <InputBox required={true} field={items} value={applicationInputItems[items]} handleChanges={handleApplicationInputs} /> :
        <InputBox inputHead="mayor_application_input" field={items} value={applicationInputItems[items]} handleChanges={handleApplicationInputs} />}
    </div>
    return inputItems

  });

  const invitationInputs = invitationHeadings.map((items, index) => {
    const inputItems = <div key={index} className='col-auto'>
      {items !== "कैफियत" ? <InputBox required={true} field={items} value={invitationInputHeadings[items]} handleChanges={handleInvitationInputs} /> :
        <InputBox inputHead="mayor_invitation_input" field={items} value={invitationInputHeadings[items]} handleChanges={handleInvitationInputs} />}
    </div>
    return inputItems

  });
  const participationInputs = participatedPrograms.map((items, index) => {
    const inputItems = <div key={index} className='col-auto'>
      {items !== "कैफियत" ? <InputBox required={true} field={items} value={participatedProgramsInput[items]} handleChanges={handleParticipationInputs} /> :
        <InputBox inputHead="mayor_participation_input" field={items} value={participatedProgramsInput[items]} handleChanges={handleParticipationInputs} />}
    </div>
    return inputItems

  });
  const tableEntry = (inputType, inputHeads, inputItems, inputBoxes) => {
    let showButtonId = "show" + inputType + "Button";
    return <>
      <fieldset>
        <form action="submit" onSubmit={(event) => handleSubmit(event, inputType, inputItems, inputHeads)}>
          <div className="row justify-content-between">
            {inputBoxes}
          </div>
          <input className='asideRight btn btn-danger' type="submit" value="थप गर्नुहोस" align='right' disabled={false} />
        </form>
      </fieldset>
      <fieldset>

        <br />
        Output Section
        <div className="d-grid gap-2 col-6 mx-auto">
          <button type="button" className="btn btn-primary" onClick={() => handleShow(inputType)} id={showButtonId}>Show All</button>
        </div>
        {/* {populateTable(inputHeads, secretariatData[inputType])} */}
        {<Table inputType={inputType} tHeadItems={inputHeads} tBodyItems={secretariatData[inputType]} />}
      </fieldset>

    </>
  }
  const transferData = () => {
    alert("Transfer Data");
    //get document
    let sourcepath = userDetail().district + "/" + "धरान" + "/नगरपालिका"
    const docRef = collection(database, sourcepath, FiscalYear, "Mayor", "सचिवालय", 'applicantInput');
    getDocs(docRef)
      .then((snapshot) => {


        let count = 1;
        console.log(snapshot)
        // alert(sourcepath + snapshot.exists() + count)
        let datas = {}
        snapshot.forEach((doc) => {
          // datas = { ...datas, ...{ [docs.data().datanum]: docs.data() } }
          // setDoc(doc(database, databaseChainName, FiscalYear, "Mayor", "सचिवालय", "participations", docs.data().datanum),
          //   docs.data(), { merge: true })
          transfer(doc.data().datanum, doc.data());
          alert(count);
          count++;
        })
        console.log("datas", datas);

        alert("Kuber")
      });

  }
  const transfer = (index, data) => {
    console.log(index, "index");
    setDoc(doc(database, databaseChainName, FiscalYear, "Mayor", "सचिवालय", "applicantInput", index + ""),
      data, { merge: true });
  }

  return (
    <div className='secretariat mukesh'>
      <div style={{
        position: 'fixed',
        top: 62,
        right: 0,
        background: 'linear-gradient(to bottom, #6dd5ed, #2193b0)',
        color: 'white',
        // padding: '2px',
        borderRadius: '20px',
        boxShadow: '0px 20px 10px rgba(0, 0, 0, 0.3)',
        writingMode: 'vertical-rl', /* set writing mode to vertical */
        textOrientation: 'upright', /* keep text upright */
      }}>
        {currentDate}
      </div>
      <ToastContainer />
      {showloadingIcon && <Spinners />}
      <br />
      <marquee direction='left' behavior='scroll' scrollamount='5'>
        <h1 className='heading'>

          नगर प्रमुख स्वकीय सचिवालय कर्मचारीको कार्यविवरण

        </h1>
      </marquee>

      <div className="row">
        <div className="col">
          <div className="row">
            <ul>
              यस इनरुवा नगरपालिका नगर प्रमुखको सचिवालयमा कार्यरत कर्मचारीले निम्न कार्यहरु नगरपालिका कार्यालयको प्रशासनिक नियन्त्रण भित्र रही प्रभावकारी रुपमा कार्यान्वयन गर्नु पर्नेछ ।
              <li>कार्यालय, विभिन्‍न वडा तथा अन्य व्यक्ति तथा निकायहरुबाट नगर प्रमुखलाई पेश गर्नु पर्ने कागज पत्रहरु सुरक्षित साथ राख्‍ने, यथा समयमै पेश गर्ने तथा कार्यान्वयनको फलोअप गर्ने एवम् पत्रहरुको अभिलेख सिलसिलेदार रुपमा राख्‍ने ।
              </li><li>नगर प्रमुखलाई भेटघाट गर्न आउने व्यक्तिहरुको कामको जानकारी लिने तथा आवश्यक जानकारी उपलब्ध गराउने वा नगर प्रमुख ज्यूको समक्ष लिई (First Come First Serve) अनुसार भेट गर्ने व्यवस्था मिलाउने ।
              </li><li>प्रमुख प्रशासकीय अधिकृत मार्फत प्राप्‍त प्रतिवेदनहरु नगर प्रमुख समक्ष पेश गर्ने ।
              </li><li>नगर प्रमुखको बैठक, भेटघाटको लागि व्यक्ति, संघ संस्था र सरकारी कार्यालयहरुको दिन समय र विषय निश्चित गर्ने र त्यसको जानकारी नगर प्रमुखलाई दिने ।
              </li><li>नगर प्रमुखको आगामी कार्यक्रमहरु टिपोट गरी सम्झना गराउने ।
              </li><li>बैठकमा प्रस्तुत गरिने पूर्व निर्धारित एजेण्डाको सारांश नगर प्रमुखलाई समयमै सम्झना गराउने ।
              </li><li>
                नगर प्रमुख सँग भेटघाट गर्न आउने पाहुनाहरु सँग शिष्ट व्यवहार प्रदर्शन गर्ने ।</li><li>
                नगर प्रमुखको स्वकीय सचिवसँग समन्वय गरी नगर प्रमुख सहभागी हुनु भएको कार्यक्रमहरुको अभिलेख राख्‍ने</li><li>
                आवश्यकता अनुसार कम्प्युटर टाईप र अन्य सेक्रेटरियल काम गर्ने ।</li><li>
                नगर प्रमुख र कार्यकारी अधिकृत को निर्देशन अनुसारका अन्‍य कार्य गर्ने ।</li>
            </ul>
          </div>

        </div>

      </div>



      <h2>धन्यवाद</h2>

      <h3>प्राप्‍त निवेदन तथा कागजात</h3>
      {tableEntry("applicantInput", applicationItems, applicationInputItems, applicationInputs)}

      View Data Search Data and Other options as required From the table  --
      <button onClick={() => { window.open('https://mayorapp-74460.web.app/#/', '_blank'); }}>Open Mayor App</button>
      <h3>Invitations to Mayor</h3>
      Make an invitation entry table
      {tableEntry("invitations", invitationHeadings, invitationInputHeadings, invitationInputs)}

      <h3>नगर प्रमुख सहभागी हुनु भएको कार्यक्रम हरु</h3>
      Make input boxes and output should be in tables
      {tableEntry("participations", participatedPrograms, participatedProgramsInput, participationInputs)}
      {/* <button onClick={transferData}>Transfer Data</button> */}
    </div>
  )
}
