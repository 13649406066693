import React, { useRef, useState } from 'react';
import InputBox from '../InputBox';
import Selection from '../ParagraphItems/Selection';
import { dateWithSlash, isValidDate, userDetail } from '../Variables/TextVariables';
import { FormValidation } from '../FormValidation';
import DateItem from '../ParagraphItems/DateItem';
import ReactToPrint from 'react-to-print';
import { spaces } from '../Variables';
import { nextday, previousday } from '../Date';
import { getDatesDifference } from '../tests';
import ApplicationHeading from '../ParagraphItems/ApplicationHeading';

export default function Internal(props) {
    const [includeHotel, setIncludeHotel] = useState(false); // State to manage hotel inclusion
    const componentRef = useRef();
    const billRef = useRef();
    const applicationRef = useRef();

    const topic = props.title;
    const date = props.date;
    const specificItems = props.specificItems.items;
    var topicObjects = specificItems[topic];
    if (typeof topicObjects === 'undefined') topicObjects = {};
    const selectedTitle = "letterType";
    const availableOptions = ['भ्रमण आदेश', 'अन्य आदेश'];
    const selectedOption = topicObjects[selectedTitle] ? topicObjects[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);

    var details = topicObjects[selectedOption];
    if (typeof details === 'undefined') details = {};
    console.log(details);

    const updater = (field, value) => {
        const updatedValue = {};
        updatedValue[field] = value;
        var finalUpdate = {};
        if (field === selectedTitle)
            finalUpdate = updatedValue;
        else finalUpdate[selectedOption] = { ...details, ...updatedValue };
        props.specificItems.updater(topic, { ...specificItems[topic], ...finalUpdate });
    };
    const toggleHotel = () => {
        setIncludeHotel(!includeHotel);
    };


    const printAction = (
        <div className='printAction'>
            <FormValidation ref={componentRef} buttonName="Print भ्रमण आदेश" />
        </div>
    );

    const travelOrderItems = [
        'भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम',
        'दर्जा',
        'कार्यालय',
        'भ्रमणको स्थान',
        'भ्रमणको उद्देश्य',
        'भ्रमण सुरु मिति',
        'भ्रमण अन्त्य मिति',
        'भ्रमणको निमित्त माग गरेको पेश्की खर्च',
        'भ्रमण सम्बन्धी अन्य आवश्यक कुरा',
        'दैनिक भ्रमण भत्ता',
        'कर्मचारीको स्थायी ठेगाना',
        'भ्रमण गर्ने साधन',
        'भाडा दर',
        'फूटकर विवरण',
        'फूटकर जम्मा',
    ];

    const otherOrderItems = ['अन्य आदेश'];

    const itemsToMap = selectedIndex === 0 ? travelOrderItems : otherOrderItems;

    const inputs = itemsToMap.map((item, index) => {
        return (
            <div className="col" key={selectedIndex + "" + index}>
                <InputBox
                    type={(item === 'दैनिक भ्रमण भत्ता' || item === 'भाडा दर' || item === 'फूटकर जम्मा' || item === 'पेश्की रकम' || item === 'भ्रमणको निमित्त माग गरेको पेश्की खर्च') ? "number" : null}
                    field={item}
                    value={details[item]}
                    required={true}
                    handleChanges={(field, value) => {
                        updater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                    }}
                />
            </div>
        );
    });

    const formattedOutput = <>
        <div className="d-flex">
            <span className="label_700">१. भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम</span>
            <span>: {details['भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">२. दर्जा</span>
            <span>: {details['दर्जा'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">३. कार्यालय</span>
            <span>: {details['कार्यालय'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">४. भ्रमणको स्थान</span>
            <span>: {details['भ्रमणको स्थान'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">५. भ्रमणको उद्देश्य</span>
            <span>: {details['भ्रमणको उद्देश्य'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">६. भ्रमण गर्ने अवधि</span>
            <span>: {details['भ्रमण सुरु मिति'] || ''} देखि {details['भ्रमण अन्त्य मिति'] || ''} सम्म</span>
        </div>
        <div className="d-flex">
            <span className="label_700">७. भ्रमण गर्ने साधन</span>
            <span>: {details['भ्रमण गर्ने साधन'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">८. भ्रमणको निमित्त माग गरेको पेश्की खर्च</span>
            <span>: {details['भ्रमणको निमित्त माग गरेको पेश्की खर्च'] || ''}</span>
        </div>
        <div className="d-flex">
            <span className="label_700">९. भ्रमण सम्बन्धी अन्य आवश्यक कुरा</span>
            <span>: {details['भ्रमण सम्बन्धी अन्य आवश्यक कुरा'] || ''}</span>
        </div>
    </>
        ;

    // Calculate total for each dataRow
    const calculateTotal = (row) => {
        const dailyAllowanceTotal = row.dailyAllowanceDays * row.dailyAllowanceRate;
        const hotelCostTotal = includeHotel ? row.hotelCostDays * row.hotelCostRate : 0;
        const miscellaneousCost = row.miscellaneousCost || 0;

        return row.travelCost * 1 + dailyAllowanceTotal * 1 + hotelCostTotal * 1 + miscellaneousCost * 1;
    };
    var datesDifference = getDatesDifference(details['भ्रमण सुरु मिति'], details['भ्रमण अन्त्य मिति']);
    console.log(datesDifference);

    const dataRows = [
        {
            id: 1,
            place: userDetail().municipality.नाम || '', // Office address
            date: details['भ्रमण सुरु मिति'] || '', // Travel start date
            destination: details['भ्रमणको स्थान'] || '', // Destination place
            tool: details['भ्रमण गर्ने साधन'] || '', // Travel tool
            travelCost: details['भाडा दर'] || 0, // Travel cost, assuming it's a number
            dailyAllowanceDays: 1, // Placeholder, adjust as per your logic
            dailyAllowanceRate: details['दैनिक भ्रमण भत्ता'] || 0, // Placeholder, adjust as per your logic
            hotelCostDays: 0, // Placeholder, adjust as per your logic
            hotelCostRate: 0, // Placeholder, adjust as per your logic
            miscellaneousDetails: '', // Placeholder, adjust as per your logic
            miscellaneousCost: '', // Placeholder, adjust as per your logic
            total: 0, // Placeholder, adjust as per your logic
            remarks: '', // Placeholder, adjust as per your logic
        },
        {
            id: 2,
            firstColumn: `${nextday(details['भ्रमण सुरु मिति'])} देखि ${previousday(details['भ्रमण अन्त्य मिति'])} सम्म \n कार्यालयको काम`,
            dailyAllowanceDays: datesDifference != "INVALID DATE" ? datesDifference.day * 1 - 1 : 0,
            tool: '',
            travelCost: 0,
            dailyAllowanceRate: details['दैनिक भ्रमण भत्ता'] || 0,
            hotelCostDays: 0,
            hotelCostRate: 0,
            miscellaneousDetails: details['फूटकर विवरण'], // Placeholder, adjust as per your logic
            miscellaneousCost: details['फूटकर जम्मा'] || 0,
            total: 0,
            remarks: ''
        },
        {
            id: 3,
            place: details['भ्रमणको स्थान'] || '', // Office address
            date: details['भ्रमण अन्त्य मिति'] || '', // Travel start date
            destination: userDetail().municipality.नाम || '', // Destination place
            tool: details['भ्रमण गर्ने साधन'] || '', // Travel tool
            travelCost: details['भाडा दर'] || 0, // Travel cost, assuming it's a number
            dailyAllowanceDays: 0.25, // Placeholder, adjust as per your logic
            dailyAllowanceRate: details['दैनिक भ्रमण भत्ता'] || 0, // Placeholder, adjust as per your logic
            hotelCostDays: 0, // Placeholder, adjust as per your logic
            hotelCostRate: 0, // Placeholder, adjust as per your logic
            miscellaneousDetails: '', // Placeholder, adjust as per your logic
            miscellaneousCost: '', // Placeholder, adjust as per your logic
            total: 0, // Placeholder, adjust as per your logic
            remarks: '', // Placeholder, adjust as per your logic
        }, {}, {}, {}, {}, {},
        {
            id: 9,
            travelTotal: details['भाडा दर'] * 2,
            allowancesTotal: 0,
            miscellaneousTotal: details['फूटकर जम्मा'] || 0,
            total: 0,
            hotelTotal: 0
        },
        {
            id: 10,
            firstColumn: `भ्रमण खर्च (महल ६) को जम्मा रू.`,
            total: 0,
        },
        {
            id: 11,
            firstColumn: `दिनको भ्रमण भत्ता (महल ९) को जम्मा रू.`,
            total: 0,
        },
        {
            id: 12,
            firstColumn: `होटलवास खर्च (महल १२) को जम्मा रू.`,
            total: 0,
        },
        {
            id: 13,
            firstColumn: `फूटकर खर्च (महल ${includeHotel ? "१४" : "११"}) को जम्मा रू.`,
            total: 0,
        },
        {
            id: 14,
            firstColumn: `कुल जम्मा (महल ${includeHotel ? "१५" : "१२"}) को जम्मा रू.`,
            total: 0,
        },
        {
            id: 15,
            firstColumn: `भ्रमण खर्च पेश्की जम्मा रू.`,
            total: details['भ्रमणको निमित्त माग गरेको पेश्की खर्च'] || 0,
        },
        {
            id: 16,
            firstColumn: `खुद भुक्तानी पाउने रू.`,
            total: 0
        },


        // Add more rows as needed
    ];
    // Update total for each row
    dataRows.forEach(row => {
        row.total = calculateTotal(row);
    });
    let allowancesTotal = 0;
    [dataRows[0], dataRows[1], dataRows[2]].forEach(row => {
        allowancesTotal += row.dailyAllowanceDays * row.dailyAllowanceRate;

    });
    dataRows[8].allowancesTotal = allowancesTotal;
    dataRows[8].total = allowancesTotal * 1 + dataRows[8].travelTotal * 1 + dataRows[8].miscellaneousTotal * 1;
    dataRows[9].total = dataRows[8].travelTotal;
    dataRows[10].total = dataRows[8].allowancesTotal;
    dataRows[11].total = dataRows[8].hotelTotal;
    dataRows[12].total = dataRows[8].miscellaneousTotal;
    dataRows[13].total = dataRows[8].total;
    dataRows[14].total = details['भ्रमणको निमित्त माग गरेको पेश्की खर्च'] || 0;
    dataRows[15].total = dataRows[8].total * 1 - dataRows[14].total * 1;

    return (
        <div className='mukesh'>
            {printAction}
            <div className="row">
                <div className="col">
                    <DateItem date={props.date} selection={props.title} />
                </div>
            </div>
            <div className='row'>
                <Selection
                    selectionTitle={selectedTitle}
                    options={availableOptions}
                    details={details}
                    detailsUpdater={updater}
                />
                {inputs}
            </div>

            <div className='businessClose' ref={componentRef} contentEditable={true}>
                <br />
                <br />
                अनुसूची – १ <br />
                (नियम ३ को उप नियम (४) संग सम्बन्धित)

                <h4>{userDetail().municipality.नाम} {userDetail().municipality.प्रकार}</h4>
                <h4>{userDetail().office.नाम}</h4>
                <h5>{userDetail().province}, {userDetail().district} </h5>
                <h5> भ्रमण आदेश</h5>
                <h5>
                    <div id='DateRow' align='right'> मितिः {dateWithSlash(props.date.currentDate)}</div>
                    <br />
                    <div className="content" align='justify'>
                        <pre className='mukesh'>
                            {formattedOutput}
                        </pre>
                    </div>
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <div style={{ width: '45%', textAlign: 'center' }}>
                            ...........................................
                            <div style={{ marginTop: '5px' }}>भ्रमण गर्ने व्यक्ति</div>
                        </div>
                        <div style={{ width: '45%', textAlign: 'center' }}>
                            ...........................................
                            <div style={{ marginTop: '5px' }}>भ्रमण स्वीकृत गर्ने पदाधिकारी</div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div style={{ border: '1px solid black', padding: '11px', marginBottom: '20px' }}>
                        <h4>(आर्थिक प्रशासन शाखाको प्रयोजनको लागि)</h4>
                        <h4>भ्रमण खर्च</h4>
                        <p>बजेट नं. __________ बाट नगद/चेक नं. ________________ रु._______________ अक्षरुपी रु.___________________________________________________________ दिइएको छ ।</p>
                        <br />
                        <br />
                        <div>
                            <div className="row" style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
                                <div className="col">
                                    <p>बुझिलिनेको सहीः</p>
                                    <p>नाम थरः</p>
                                    <p>मितिः</p>
                                </div>
                                <div className="col">
                                    <p>.........................................</p>
                                    <p>(आर्थिक प्रशासन शाखा) </p>
                                    <p>  मितिः</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        </div>
                    </div>
                </h5>
            </div>

            <ReactToPrint
                trigger={() => <button style={{
                    position: 'fixed',
                    zIndex: 100,
                    bottom: '3%',
                    left: '50%'
                }}>Print Bill</button>}
                content={() => billRef.current}
            /> <span>.                   .                              .</span>
            <ReactToPrint
                trigger={() => <button class="btn btn-info" style={{
                    position: 'fixed',
                    zIndex: 100,
                    bottom: '3%',
                    left: '13%'
                }}>Print भुक्तानी निवेदन</button>}
                content={() => applicationRef.current}
            />
            <div className="form-check form-switch d-flex align-items-center">
                <label className="form-check-label me-3" htmlFor="flexSwitchCheckDefault">
                    Include Hotel Expenses
                </label>
                {spaces(8)}
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={includeHotel}
                    onChange={toggleHotel}
                    style={{ width: '60px' }} // Adjust width as needed
                />
                {spaces(8)}
                {includeHotel ? (
                    <span style={{ color: 'green', fontWeight: 'bold' }}>✓</span>
                ) : (
                    <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>
                )}
            </div>


            <div ref={billRef} className='businessClose mukesh table-bill' align='justify'>
                <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
                    इनरुवा नगरपालिका, नगर कार्यपालिकाको कार्यालय<br />
                    इनरुवा, सुनसरी<br />
                    दैनिक तथा भ्रमण खर्चको विल
                </h4>
                <div className="row">
                    <div className="col-8">
                        <div className="d-flex">
                            <span className="label">नाम: </span>
                            <span>{details['भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम'] || ''}</span>
                        </div>
                        <div className="d-flex">
                            <span className="label">पद: </span>
                            <span>{details['दर्जा'] || ''}</span>
                        </div>
                        <div className="d-flex">
                            <span className="label">दर्जा: </span>
                            <span>{details['दर्जा'] || ''}</span>
                        </div>
                        <div className="d-flex">
                            <span className="label">कार्यालय: </span>
                            <span>{details['कार्यालय'] || ''}</span>
                        </div>
                        <div className="d-flex">
                            <span className="label">कर्मचारीको स्थायी ठेगाना: </span>
                            <span>{details['कर्मचारीको स्थायी ठेगाना'] || ''}</span>
                        </div>
                    </div>
                    <div className="col-4">
                        <div>
                            <span>म.ले.प.फा.नं.: </span>
                            <span>४</span> {/* This value seems static, adjust as needed */}
                        </div>
                        <div>
                            <span>नत्थी रसिद वील आदीको संख्या: </span>
                            <span>{details['नत्थी रसिद वील आदीको संख्या'] || ''}</span>
                        </div>
                        <div>
                            <span>भ्रमणको उद्देश्य: </span>
                            <span>{details['भ्रमणको उद्देश्य'] || ''}</span>
                        </div>
                    </div>
                </div>
                <table className="table-bill">
                    <thead>
                        <tr>
                            <th colSpan="2">प्रस्थान</th>
                            <th colSpan="2">पहुच</th>
                            <th rowSpan="2">भ्रमण साधन</th>
                            <th rowSpan="2">भ्रमण खर्च</th>
                            <th colSpan="3">दैनिक भत्ता</th>
                            {includeHotel && <th colSpan="3">होटल बास खर्च</th>}
                            <th colSpan="2">फूटकर खर्च</th>
                            <th rowSpan="2">कूल जम्मा</th>
                            <th rowSpan="2">कैफियत</th>
                        </tr>
                        <tr>
                            <th>स्थान</th>
                            <th>मिति</th>
                            <th>स्थान</th>
                            <th>मिति</th>
                            <th>दिन</th>
                            <th>दर</th>
                            <th>जम्मा</th>
                            {includeHotel && (
                                <>
                                    <th>दिन</th>
                                    <th>दर</th>
                                    <th>जम्मा</th>
                                </>
                            )}
                            <th>विवरण</th>
                            <th>जम्मा</th>
                        </tr>
                        <tr>
                            {[...Array(!includeHotel ? 13 : 16)].map((_, index) => (
                                <td key={index}>{index + 1}</td>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {/* Map through dataRows array to dynamically generate rows */}
                        {dataRows.map((row, index) => (
                            index != 1 && index < 3 ?
                                <tr key={row.id}>
                                    <td>{row.place}</td>
                                    <td>{row.date}</td>
                                    <td>{row.destination}</td>
                                    <td>{row.date}</td>
                                    <td>{row.tool}</td>
                                    <td>{row.travelCost}</td>
                                    <td>{row.dailyAllowanceDays}</td>
                                    <td>{row.dailyAllowanceRate}</td>
                                    <td>{row.dailyAllowanceDays * row.dailyAllowanceRate}</td>
                                    {includeHotel && (
                                        <>
                                            <td>{row.hotelCostDays}</td>
                                            <td>{row.hotelCostRate}</td>
                                            <td>{row.hotelCostDays * row.hotelCostRate}</td>
                                        </>
                                    )}
                                    <td>{row.miscellaneousDetails}</td>
                                    <td>{row.miscellaneousCost}</td>
                                    <td>{row.total}</td>
                                    <td>{row.remarks}</td>
                                </tr> :
                                index == 1 ?
                                    <tr key={row.id}>
                                        <td colSpan={"4"}>
                                            {row.firstColumn}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>{row.dailyAllowanceDays}</td>
                                        <td>{row.dailyAllowanceRate}</td>
                                        <td>{row.dailyAllowanceDays * row.dailyAllowanceRate}</td>
                                        {includeHotel && (
                                            <>
                                                <td>{row.hotelCostDays}</td>
                                                <td>{row.hotelCostRate}</td>
                                                <td>{row.hotelCostDays * row.hotelCostRate}</td>
                                            </>
                                        )}
                                        <td>{row.miscellaneousDetails}</td>
                                        <td>{row.miscellaneousCost}</td>
                                        <td>{row.total}</td>
                                        <td>{row.remarks}</td>
                                    </tr> :
                                    index == 8 ?
                                        <><tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{row.travelTotal}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{row.allowancesTotal}</td>
                                            {includeHotel && (
                                                <>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{row.hotelTotal}</td>
                                                </>
                                            )}
                                            <td></td>
                                            <td>{row.miscellaneousTotal}</td>
                                            <td>{row.total}</td>
                                        </tr>
                                            <tr>
                                                <td colSpan={includeHotel ? "11" : "8"}></td>
                                                <td colSpan={"3"} >स्वीकृत रकम रु.
                                                </td>
                                                <td>{row.total}</td>
                                            </tr>
                                        </>

                                        : index > 8 ? (index != 11 || includeHotel ?
                                            <tr >
                                                <td>{includeHotel || index < 11 ? (index - 8) : (index - 9)}</td>
                                                <td colSpan={"4"} style={{
                                                    textAlign: "left"
                                                }} >{row.firstColumn}</td>
                                                <td>{row.total}</td>
                                                {index == 9 &&
                                                    <>
                                                        <td rowSpan={includeHotel ? "6" : "7"} colSpan={includeHotel ? "5" : "4"}
                                                            style={{
                                                                borderRight: "none", // Remove right border
                                                                // Optional: Add padding
                                                            }}
                                                        >


                                                            <p>
                                                                स्वीकृत भ्रमण आदेश नं...... मिति <br />
                                                                पेश भएको व्हयोरा ठीक छ । <br /> झुठा ठहरे
                                                                प्रचलित कानून बमोजिम सहने बुझाउने छु । <br /> <br />
                                                                ...................... <br />
                                                                भ्रमण गर्ने व्यक्तिको दस्तखत <br />
                                                                मिति
                                                            </p>




                                                        </td>
                                                        <td rowSpan={includeHotel ? "6" : "7"} colSpan={includeHotel ? "5" : "3"}
                                                            style={{
                                                                borderLeft: "none", // Remove right border
                                                                // Optional: Add padding
                                                            }}>
                                                            <p>
                                                                <br />
                                                                ................... <br />
                                                                जाच गर्ने अधिकारीको दस्तखत <br />
                                                                मिति <br /> <br />
                                                                ................... <br />
                                                                स्वीकृत गर्ने अधिकारीको दस्तखत <br />
                                                                मिति
                                                            </p>
                                                        </td>
                                                    </>

                                                }

                                            </tr>
                                            : null)
                                            :
                                            <tr>
                                                {[...Array(!includeHotel ? 13 : 16)].map((_, index) => (
                                                    <td key={index}  >
                                                        &nbsp;
                                                    </td>
                                                ))}
                                            </tr>
                        ))}
                    </tbody>
                </table>
                <p>द्रष्टव्यः पेश भएका विलहरुमा मिति खुलाएको र कर्मचारीले पनि दस्तखत गरेको हुनु पर्दछ । पेश गरेका कागजात हरुको क्रम संख्या मिलाई पेश गर्नु पर्ने छ ।
                </p>
            </div>
            <div ref={applicationRef} className='businessClose mukesh' align='justify'>
                <h4>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {<ApplicationHeading date={props.date} />}
                    <br />

                    {spaces(10)} प्रस्तुत सम्बन्धमा म निवेदक {details['भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम']}, {details['दर्जा']}, {details['कार्यालय']} मिति {details['भ्रमण सुरु मिति']} गते देखि मिति {details['भ्रमण अन्त्य मिति']} गते सम्म {details['भ्रमणको उद्देश्य']}को सिलसिलामा {details['भ्रमणको स्थान']} भ्रमण गरे बापत बिल बमोजिमको भुक्तानी हुने व्यवस्था मिलाइदिनु हुन अनुरोध गर्दछु ।

                    <br />
                    <br />
                    <br />
                    <div className='signature' align="right">
                        <div align="center">
                            ........................... <br />
                            निवेदक <br />
                            {details['भ्रमण गर्ने पदाधिकारी वा कर्मचारीको नाम']} <br />
                            {details['दर्जा']} <br />
                            {details['कार्यालय']}
                        </div>
                    </div>
                </h4>
            </div>
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}
