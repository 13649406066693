import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, outin, reportTypes, schoolInputs } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function SchoolThauSaari(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const outInTitle = "goingOrComingOption";
    const outInOption = details[outInTitle] ? details[outInTitle] : outin[0];

    const itemsToMap = [...schoolInputs, ...[outInOption + " ठेगाना"]];
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 2 ? isValidDate(value) : value)
            }} />
        </div>
    })

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />

                <Selection selectionTitle={outInTitle} options={outin} details={details} detailsUpdater={props.detailsUpdater} />
                {inputs}



                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {details[itemsToMap[0]]}मा रहेको {details[itemsToMap[1]]}को ठाउँसारी गर्नका लागि विद्यालय व्यवस्थापन समितिको निर्णय सहित अनुरोध गरी आएकोमा सो विद्यालयको माग बमोजिम विद्यालय {details[itemsToMap[3]]}मा {outInOption}का लागि यस वडा समितिको मिति {details[itemsToMap[2]]}को निर्णय {sarjin.sarjinPresent && " र " + sarjin.sarjinDetails + "का "}अनुसार स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (३३) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
