import React, { useRef, useState } from 'react';
import { FormValidation } from '../FormValidation';
import { ToastContainer, toast } from 'react-toastify';
import { programEstimateDetails, programItems, sides, spaces, tippaniPeople, underline } from '../Variables';
import SenderReceiver from '../ParagraphItems/SenderReceiver';
import InputBox from '../InputBox';
import DateItem from '../ParagraphItems/DateItem';
import { convertNumberToText, currentFiscalYear, dateWithSlash, isValidDate, userDetail } from '../Variables/TextVariables';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Heading from '../ParagraphItems/Heading';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry';
import Applicant from '../ParagraphItems/Applicant';
import { database } from '../ServerWorks/firebase';
import { addDoc, collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import Spinners from '../InteractingItems/Spinners';
import Pagination from '../ParagraphItems/Pagination';

export default function Samjhouta(props) {
  const [showloadingIcon, setLoadingIcon] = useState(false);
  const [showSamjhoutaDataButton, setShowSamjhoutaDataButton] = useState(true);
  const [mode, setMode] = useState("copy"); // Add a state variable to track the mode
  const [currentSamjhoutaNum, setCurrentSamjhoutaNum] = useState(0); // for edit purpose only
  const [view, setView] = useState(false);
  const [viewContent, setViewContent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const samjhoutaDatas = props.data.data;


  const topic = props.title;
  let samjhoutaItemsCount = 0;
  const FiscalYear = currentFiscalYear();
  const databaseChainName = userDetail().district + "/" + userDetail().municipality.नाम + "/" + userDetail().office.प्रकार + userDetail().office["वडा नं"];
  const componentRef = useRef();
  const date = props.date;
  const specificItems = props.specificItems.items;
  var topicObjects = specificItems[topic];
  if (typeof topicObjects === 'undefined') topicObjects = {};
  const [table, setTable] = useState([]);
  const [table_1, setTable_1] = useState([]);

  const printAction = (
    <div className='printAction'>
      <FormValidation ref={componentRef} />
    </div>
  );

  const samjhoutaUpdater = (field, value) => {
    const updatedValue = {};
    updatedValue[field] = value;
    props.specificItems.updater(topic, { ...specificItems[topic], ...updatedValue });
  };

  const tableEntry = (
    <div>
      <h4>लागत विवरण</h4>
      <TableEntry setTable={setTable} inputHeads={programEstimateDetails} numberIndex={[1]} variableName="लागत विवरण" details={topicObjects} detailsUpdater={samjhoutaUpdater} />
    </div>
  );
  const tableEntry_1 = (
    <div>
      <h4>टिप्पणीमा समावेश व्यक्तिहरु</h4>
      <TableEntry setTable={setTable_1} inputHeads={tippaniPeople} variableName="टिप्पणीमा समावेश व्यक्तिहरु" details={topicObjects} detailsUpdater={samjhoutaUpdater} />
    </div>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const docRef = doc(database, databaseChainName, FiscalYear, topic, "samjhoutaCount");
    console.log("inside handle samjhouta submit");
    getDoc(docRef)
      .then((snapshot) => {
        let samjhoutaCount;
        if (snapshot.exists()) {
          samjhoutaCount = mode !== "edit" ? snapshot.data()["counts"] + 1 : currentSamjhoutaNum;
        } else {
          samjhoutaCount = 1;
        }
        setLoadingIcon(true);
        let dataToWeb = {
          details: topicObjects,
          date: props.date.currentDate,
          person: props.person,
          applicant: props.applicant,
          samjhoutanum: samjhoutaCount,
          content: componentRef.current.innerHTML,

        };
        setDoc(doc(database, databaseChainName, FiscalYear, topic, samjhoutaCount + ""),
          dataToWeb, { merge: true })
          .then(() => {
            setLoadingIcon(false);
            toast('Data submitted 👍');
            if (mode === "edit") {
              const updatedDataIndex = samjhoutaDatas.findIndex(item => item.samjhoutanum === currentSamjhoutaNum);
              if (updatedDataIndex !== -1) {
                const updatedSamjhoutaDatas = [...samjhoutaDatas];
                updatedSamjhoutaDatas[updatedDataIndex] = dataToWeb;
                props.data.updater(updatedSamjhoutaDatas);
                // setSamjhoutaDatas(updatedSamjhoutaDatas); // Update the state with the edited data
              }
            } else
              setDoc(docRef, { counts: samjhoutaCount })
                .then(() => {

                  props.data.updater([...[dataToWeb], ...samjhoutaDatas]);
                  // setDartaItem(arrayToObjects(dartaItems));//reset input editTextboxes

                })
          })
          .catch((error) => {
            setLoadingIcon(false);
            alert(error.message);
          });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const fetchData = () => {
    const collectionRef = collection(database, databaseChainName, FiscalYear, topic);
    // document.getElementById("showSamjhoutaDataButton").style.display = "none";
    setShowSamjhoutaDataButton(!showSamjhoutaDataButton);

    getDocs(collectionRef)
      .then((querySnapshot) => {
        const dataList = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== "samjhoutaCount")
            dataList.push(data);

        });
        dataList.sort((a, b) => {
          if (a.samjhoutanum > b.samjhoutanum) return 1;
          if (a.samjhoutanum < b.samjhoutanum) return -1;
          return 0;
        })
        props.data.updater(dataList.reverse())
        // setsamjhoutaDatas(dataList);
        console.log('hello how are you');
        console.log(dataList);
        // Update your state or do something else with the dataList
      })
      .catch((error) => {
        console.error("Error fetching documents: ", error);
      });
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, samjhoutaDatas.length);
  const currentItems = samjhoutaDatas.slice(startIndex, endIndex);

  const copyButton = (currentIndex, name) => (
    <button
      className='btn btn-light btn-sm'
      onClick={() => {
        const selectedItem = currentItems[currentIndex]; // Use currentIndex instead of index
        const newTopicObjects = { ...selectedItem.details }; // Copy the selected item's data
        props.specificItems.updater(topic, newTopicObjects); // Update the topicObjects with the copied data
        props.handlePerson("", selectedItem.person);
        setView(false);
        setMode(name.toLowerCase());
        setCurrentSamjhoutaNum(selectedItem.samjhoutanum)

      }}
    >
      {name.toLowerCase() === "copy" ? "📋" : "✎"}
    </button>
  );
  const viewButton = (currentIndex) => (
    <button
      className='btn btn-light btn-sm'
      onClick={() => {
        const selectedItem = currentItems[currentIndex]; // Use currentIndex instead of index
        // window.alert(selectedItem.content)
        setView(true);
        setViewContent(selectedItem.content);
        componentRef.current.focus();
        // window.open(selectedItem.content);

      }}
    >
      👁
    </button>
  );
  const submitButtonText = mode === "edit" ? "Update" : "Submit"; // Determine the submit button text based on the mode


  const dataTable = <table className="table">
    <thead>
      <tr>
        <th scope="col">S.N</th>
        <th scope="col">Date</th>
        <th scope="col">Program Name</th>
        <th scope="col">Person</th>
        <th scope="col">Amount</th>
        <th scope="col">Options</th>

      </tr>
    </thead>
    <tbody>
      {currentItems.map((item, index) => (
        <tr key={index}>
          <td>{startIndex + index + 1}</td>
          <td>{item.date}</td>
          <td>{item.details[programItems[0]]}</td>
          <td>{item.person.receiver.Office}</td>
          <td>{item.details[programItems[2]]}</td>
          <td>{copyButton(index, "Copy")} {copyButton(index, "Edit")} {viewButton(index)}</td>
        </tr>
      ))}
    </tbody>
  </table>
  return (
    <div className="mukesh">
      <div>
        {showSamjhoutaDataButton && <button onClick={fetchData} id='showSamjhoutaDataButton'>Fetch Data</button>}
        {samjhoutaDatas.length > 0 && dataTable}
        {samjhoutaDatas.length > 0 && <Pagination currentPage={currentPage} totalPages={Math.ceil(samjhoutaDatas.length / itemsPerPage)} onPageChange={setCurrentPage} />}


        <div className="row">
          <div className="col">
            <div className="form-floating mb-1 DateRow">
              <input
                type="text"
                className="form-control"
                id="applicationDate"
                placeholder="Enter Date"
                value={date.applicationDate}
                onChange={(e) => props.setApplicationDate(isValidDate(e.target.value))}
              />
              <label htmlFor="floatingInput">Application Date: </label>
            </div>
          </div>
          <div className="col">
            <DateItem date={props.date} selection={props.title} />
          </div>
        </div>
        <ToastContainer />
        {showloadingIcon && <Spinners />}

        {printAction}
        १. सम्झौता गर्ने पक्षहरुको विवरण
        <SenderReceiver person={props.person} handlePerson={props.handlePerson} senderHeading={sides[0]} receiverHeading={sides[1]} />
        २. कार्यक्रमको विवरण
        <div className="row">
          <div className="col">
            {programItems.map((items, index) => (
              <div key={index} required={true} className="col">
                <InputBox
                  style={{ width: '100%' }}
                  type={index === 2 ? "number" : "text"}
                  field={items}
                  value={topicObjects[items]}
                  handleChanges={(field, value) => {
                    samjhoutaUpdater(field, field.endsWith("मिति") ? isValidDate(value) : value);
                  }}
                />
              </div>
            ))}
          </div>
          <div className="col">
            {tableEntry}
            {tableEntry_1}
          </div>
        </div>
        {view && <button onClick={() => setView(false)}> Close View</button>}
        {view && <div className='businessClose' contentEditable={true} ref={componentRef} dangerouslySetInnerHTML={{ __html: viewContent }} >
        </div>}
        {!view && <div className='businessClose' contentEditable={true} ref={componentRef}>
          <OfficeInformation noChalani={true} />
          <h3>कार्यक्रम सम्झौता</h3>
          <div className="content" align='justify'>
            १. सम्झौता गर्ने पक्षहरुको विवरण <br />
            {spaces(4)}क. पहिलो पक्षः {props.person.sender['Office']} ।<br />
            {spaces(4)}ख. दोस्रो पक्षः {props.person.receiver['Office']} । <br />
            २. कार्यक्रमको विवरण <br />
            {spaces(4)}क. कार्यक्रमको नामः {topicObjects[programItems[0]]} <br />
            {spaces(4)}ख. कार्यक्षेत्रः {topicObjects[programItems[1]]} <br />
            {spaces(4)}ग. कुल लागतः रु. {topicObjects[programItems[2]]}।- (रु. {convertNumberToText(topicObjects[programItems[2]])}मात्र ।) <br />
            {table}
            {spaces(4)}घ. कार्यक्रम बाट लाभान्वित हुने: {topicObjects[programItems[3]]} <br />
            {/* {table_1} */}
            {spaces(4)}ङ. कार्यक्रम सुरु मिति : {topicObjects[programItems[4]]} गते देखि <br />
            {spaces(4)}च. कार्यक्रम सम्पन्न गरिसक्ने मिति : {topicObjects[programItems[5]]} सम्म <br />
            <h5>सम्झौताका सर्तहरुः</h5>
            १. सम्झौतामा उल्लेख भएको कार्यक्रम म्याद भित्र सम्पन्न गरी सक्नु पर्ने छ । कुनै कारण वश कार्यक्रम सम्पन्न हुन नसकेमा सम्पन्न हुन नसक्नुको औचित्यको आधारमा सम्झौताको म्याद थप गर्न सकिने छ । यसरी म्याद थप नगरी भुक्तानी तथा पेश्कि उपलब्ध हुने छैन । <br />
            २. कार्यक्रममा लाग्ने रकम मध्ये नगरपालिकाले व्यहोर्ने रकम एकमुष्ट रुपमा उपलब्ध गराउने छ । अति आवश्यक परेको खण्डमा मात्र केही रकम पेश्कि उपलब्ध गराउने छ । <br />
            ३. कार्यक्रम सम्पन्न भई सके पछि कार्यक्रम संचालन कर्ताले सम्पूर्ण बील भरपाई तथा आवश्यक कागजात भुक्तानी माग गर्दा न.पा.मा पेश गर्नु पर्नेछ । <br />
            ४. कार्यक्रम संचालन अवधिमा कुनै वादविवाद भएमा कार्यक्रम संचालन कर्ता स्वयंले समाधान गर्नुपर्नेछ । <br />
            ५. आवश्यक अन्य सामाग्रीहरु संचालकले स्वयंम व्यवस्था गर्नु पर्नेछ । <br />
            ६. कार्यक्रम सम्पन्न भएपछि संचालित कार्यक्रमहरुको फोटो न.पा. मा पेश गर्नुपर्नेछ । <br />
            ७. यस सम्झौतामा उल्लेख नभएका कुराहरु प्रचलित कानून अनुसार हुनेछ । <br />
            मथि उल्लेख भए बमोजिम सर्तहरु पालना गर्न निम्न पक्षहरु मन्जुर छौँ । <br />
            <div className="row">
              <div className="col App">
                {underline("कार्यक्रम संचालकको तर्फबाट")}
                <br /><br /> <br />
                ..........................<br />
                {props.person.receiver['Office']}<br />
                {props.person.receiver['Address']}<br />
                मिति : {dateWithSlash(props.date.currentDate)}
              </div>
              <div className="col App">
                {underline("नगरपालिकाको तर्फबाट")}
                <br /><br /> <br />
                <Sender person={props.person} />
                मिति : {dateWithSlash(props.date.currentDate)} <br />
              </div>
            </div>
          </div>
        </div>}
        <form action="default" className="mainform" onSubmit={handleSubmit}>
          <input type="submit" value={submitButtonText} align='right' disabled={false} />
        </form>
      </div>
    </div>
  );
}
