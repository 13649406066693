import { addDoc, collection, doc, setDoc, getDoc, getDocs } from 'firebase/firestore';
import React, { useState } from 'react'
import InputBox from './InputBox';
import { database } from './ServerWorks/firebase';
import { arrayToObjects, dartaItems } from './Variables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinners from './InteractingItems/Spinners';
import { currentFiscalYear, userDetail } from './Variables/TextVariables';


export default function Darta(props) {
    // const [dartaDatas, setDartaDatas] = useState(props.darta.data);
    const databaseChainName = userDetail().district + "/" + userDetail().municipality.नाम + "/Ward" + userDetail().office["वडा नं"]

    // let currentFiscalYear = currentFiscalYear
    let FiscalYear = currentFiscalYear(); // for searching it can take other values as well
    console.log("Fiscal YEar", FiscalYear);
    const [showloadingIcon, setLoadingIcon] = useState(false);
    const dartaDatas = props.darta.data
    const [dartaInputItems, setDartaItem] = useState(arrayToObjects(dartaItems))
    console.log("dartaInputItems", dartaInputItems)
    console.log("databaseChainName", databaseChainName, userDetail().municipality.नाम);
    const handleDartaInputs = (field, value) => {
        console.log("field:", field, "value:", value)
        setDartaItem({ ...dartaInputItems, ...{ [field]: value } })
    }

    const dartaInputs = dartaItems.map((items, index) => {
        const inputItems = <div key={index} className='col-auto'>
            {items !== "कैफियत" ? <InputBox required={true} field={items} value={dartaInputItems[items]} handleChanges={handleDartaInputs} /> :
                <InputBox field={items} value={dartaInputItems[items]} handleChanges={handleDartaInputs} />}
        </div>
        return inputItems

    });
    const tablehead = <thead>
        <tr>
            <td>सि.नं</td>
            <td>दर्ता नं</td>
            <td>मिति</td>
            {dartaItems.map((item, index) => {
                return <td key={index}>{item}</td>
            })}
        </tr>
    </thead>

    const tablebody = dartaDatas.map((items, index) => {
        const outputItems =
            <tr>
                <td>{index + 1}</td>
                <td>{items["dartanum"]}</td>
                <td>{items["date"]}</td>
                {dartaItems.map((item, ind) => {
                    return <td key={ind}>{items[item]}</td>
                })}
            </tr>
        return outputItems
    });
    const dartaOutputs = <div className="row">
        <table className='table table-striped table-dark'>
            {tablehead}
            <tbody>
                {tablebody}
            </tbody>
        </table>
    </div>
    const handleSubmit = event => {
        event.preventDefault();
        // addDoc(collection(database, "Sunsari", "Dharan", "Ward 2", "079-80", "Darta"),
        //     {
        //         dartaInputItems,
        //         dartanum: 2,
        //         date: props.date.currentDate,
        //     }
        // )

        const docRef = doc(database, databaseChainName, FiscalYear, "Darta", "dartaCount")
        console.log("inside handle darta submit")
        getDoc(docRef)
            .then((snapshot) => {
                let dartaCount;
                if (snapshot.exists()) {
                    dartaCount = snapshot.data()["counts"] + 1
                } else {
                    dartaCount = 1;
                }
                setLoadingIcon(true);
                let dataToWeb = { ...dartaInputItems, ...{ dartanum: dartaCount, date: props.date.currentDate } };
                setDoc(doc(database, databaseChainName, FiscalYear, "Darta", dartaCount + ""),
                    dataToWeb, { merge: true })
                    .then(() => {
                        toast('Darta Done 👍');
                        setLoadingIcon(false);

                        setDoc(docRef, { counts: dartaCount })
                            .then(() => {
                                // alert('Darta Count updated')
                                props.darta.updater([...[dataToWeb], ...dartaDatas]);
                                setDartaItem(arrayToObjects(dartaItems));//reset input editTextboxes
                            })
                            .catch((error) => {
                                alert(error.message);
                            })
                    })
                    .catch((error) => {
                        alert(error.message);
                    });
            })
            .catch((error) => {
                alert(error.message)
            })

    }
    const handleShow = () => {
        document.getElementById("showDartaButton").style.display = "none";
        const dartaDataFromWeb = [];
        setLoadingIcon(true)
        getDocs(collection(database, databaseChainName, FiscalYear, "Darta"))
            .then((snapshot) => {

                snapshot.forEach((doc) => {
                    if (doc.id !== "dartaCount") dartaDataFromWeb.push(doc.data())
                })
                dartaDataFromWeb.sort((a, b) => {
                    if (a.dartanum > b.dartanum) return 1;
                    if (a.dartanum < b.dartanum) return -1;
                    return 0;
                })
                // console.log("sorted", dartaDataFromWeb)
                setLoadingIcon(false)
                props.darta.updater(dartaDataFromWeb.reverse())

            })
    }

    return (
        <div className='container mukesh'>

            <ToastContainer />
            {showloadingIcon && <Spinners />}
            <div className="row">
                <fieldset>

                    <form action="submit" onSubmit={handleSubmit}>
                        <div className="row">
                            {dartaInputs}
                        </div>

                        <input className='asideRight btn btn-danger' type="submit" value="दर्ता गर्नुहोस" align='right' disabled={false} />
                    </form>
                </fieldset>
                <fieldset>

                    <br />
                    Output Section
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <button type="button" className="btn btn-primary" onClick={handleShow} id="showDartaButton">Show All</button>
                    </div>
                    {dartaOutputs}
                </fieldset>



            </div>
        </div>
    )
}
