import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import Dashboard from '../Dashboard';
import InputBox from '../InputBox';
import SelectBox from '../SelectBox';
import { auth, database, logout } from '../ServerWorks/firebase';
import { arrayToObjects, officeItem, municipalityItems, districts_Eng, districts_Nep, pradesh_Nep, pradesh_Eng } from '../Variables';
import { getIsUserInfo, setIsUserInfo } from '../Variables/TextVariables';
import Login from './Login';
export default function SignupPage() {
    const [callDashboard, setCallDashboard] = useState();
    const [user] = useAuthState(auth);
    const [userName, setuserName] = useState("");
    const [province, setProvince] = useState("");
    const [district, setDistrict] = useState("");
    const [municipality, setMunicipality] = useState(arrayToObjects(municipalityItems));
    const [office, setOffice] = useState(arrayToObjects(officeItem));
    const handleInputChange = (field, value) => {

        switch (field) {
            case "प्रयोग कर्ता": setuserName(value); break;
            case "Province": setProvince(value); break;
            case "District": setDistrict(value); break;
        }


    }
    const inputItems = [
        <div className='col-auto' key={0}><InputBox required={true} field="प्रयोग कर्ता" value={userName} handleChanges={handleInputChange} /></div>,
        <div className='col-auto' key={1}><SelectBox required={true} items="प्रदेश" options={pradesh_Nepali} value={province} handleChanges={(value) => handleInputChange("Province", value)} /></div>,
        province ? <div className='col-auto' key={2}><SelectBox required={true} items="जिल्ला" options={province_districts[pradesh_Nepali.indexOf(province)]} value={district} handleChanges={(value) => handleInputChange("District", value)} /></div> : '',

    ];
    const munItems = municipalityItems.map((items, index) => {
        return <div className='col-auto' key={index}> <InputBox required={true} field={items} value={municipality[items]} handleChanges={(field, value) => { setMunicipality({ ...municipality, ...{ [field]: value } }) }} /></div>
    })
    const officeItems = officeItem.map((items, index) => {
        return <div className='col-auto' key={index}><InputBox required={true} field={items} value={office[items]} handleChanges={(field, value) => { setOffice({ ...office, ...{ [field]: value } }) }} /></div>

    });
    const handleForm = async (event) => {
        event.preventDefault();
        addDoc(collection(database, "users"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            userName: userName,
            province: province,
            district: district,
            municipality: municipality,
            office: office
        })
            .then((result) => {
                setIsUserInfo(true);
                setCallDashboard(<Dashboard />);

            })
            .catch((error) => alert(error.message))
        alert("form submitted");
    }

    if (callDashboard) return callDashboard;
    if (!user) return <Login />
    return (
        <div className='m-2 center'>
            <br />
            <button className="btn btn-warning asideRight" onClick={() => { logout(); }}>
                Logout
            </button>
            <br />
            <form action="default" onSubmit={handleForm}>
                <h1>User Registration</h1>
                <div className="row">
                    {inputItems}
                </div>
                <br />
                <h5>Municipality Info: (Letter Pad मा प्रयोग हुने)</h5>
                <div className="row">{munItems}</div>

                <br />
                <h5>Office Info: (Letter Pad मा प्रयोग हुने)</h5>
                <div className="row"> {officeItems}</div>
                <input type="submit" className='asideRight btn btn-danger' value="Submit" />
            </form>
        </div>
    )
}

const districts_English = districts_Eng;
const districts_Nepali = districts_Nep;
const pradesh_Nepali = pradesh_Nep;
const pradesh_English = pradesh_Eng;
; const province_districts = [
    districts_Nepali.slice(0, 0 + 14),
    districts_Nepali.slice(14, 14 + 8),
    districts_Nepali.slice(22, 22 + 13),
    districts_Nepali.slice(35, 35 + 11),
    districts_Nepali.slice(46, 46 + 12),
    districts_Nepali.slice(58, 58 + 10),
    districts_Nepali.slice(68, 68 + 9)
]
