import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import Sender from '../ParagraphItems/Sender';
import { bold, spaces, underline } from '../Variables';
import { getApplicant } from '../Variables/TextVariables';


export default function NataPramanit(prop) {
    const props = prop.prop;
    const details = props.details;
    const prayojan = details.प्रयोजन ? details.प्रयोजन : "विशेष";
    const inRelations = details.inRelations ? details.inRelations : ([{ "नाता": "", "नाम": "" }])
    const [count, setCount] = useState(inRelations.length);
    console.log({ count });
    // props.detailsUpdater("प्रयोजन", prayojan);
    // props.detailsUpdater("inRelations", inRelations)

    const prayojanInput = <input type="textarea" name="prayojan" id="prayojan" value={prayojan} onChange={e => props.detailsUpdater("प्रयोजन", e.target.value)} />
    var personsInput = [];
    let personsCounts = 0;//added persons
    const addPersonsButton = <button className='btn btn-primary btn-lg' onClick={(e) => { setCount(count + 1) }}>ADD </button>
    while (count > personsCounts) {
        let personsCount = personsCounts;
        let x = inRelations[personsCount] ? "" : inRelations.push({ "नाता": "", "नाम": "" })
        personsInput.push(
            <div className='row' key={personsCount}>

                <div className="col-md-4"> <input type="text" name="person" id="person" value={(inRelations[personsCount]).नाम} placeholder='व्यक्तिको नाम' onChange={(e) => {
                    let currentObject = inRelations[personsCount]
                    console.log(personsCount, "personsCount")
                    currentObject["नाम"] = e.target.value;
                    console.log('inRelations From Inside', inRelations);
                    props.detailsUpdater("inRelations", [...inRelations])
                }} /></div>
                {spaces(2)}
                <div className="col-md-4 "> <input type="text" name="person" id="person" value={inRelations[personsCount].नाता} placeholder='निवेदक सँग नाता' onChange={(e) => {
                    let currentObject = inRelations[personsCount]
                    currentObject["नाता"] = e.target.value;
                    props.detailsUpdater("inRelations", [...inRelations])
                }} /></div>
                <div className="col">
                    {spaces(2)}
                    <button className='col btn btn-outline-danger btn-sm' onClick={(e) => {
                        inRelations.splice(personsCount, 1);
                        // setInRelations([...inRelations]);
                        setCount(count - 1);
                    }}>Remove
                    </button>
                </div>


            </div>
        )
        ++personsCounts;
    }
    if (personsCounts === 0) {

    }
    const tablehead = <>

        <th>क्र.सं.</th>
        <th>नाम</th>
        <th>नाता</th>
    </>
    const tablebody = inRelations.map((items, index) => {
        console.log(index, items['नाम'] ? true : false)

        return (
            index === 0 ?
                <tr key={index}>
                    <td>{1}</td>
                    <td>{props.applicant.Name}</td>
                    <td>निवेदक</td>
                    <td>{index + 2}</td>
                    <td>{items['नाम']}</td>
                    <td>{items['नाता']}</td>
                </tr>
                : (
                    index % 2 === 0 ? ""
                        : <tr key={index}>
                            <td>{index + 2}</td>
                            <td>{items['नाम']}</td>
                            <td>{items['नाता']}</td>
                            {inRelations[index + 1] ? <><td>{index + 3}</td>
                                <td>{inRelations[index + 1]['नाम']}</td>
                                <td>{inRelations[index + 1]['नाता']}</td>
                            </> : ""}
                        </tr>
                )

        );
    });
    const applicantphoto = <td>
        <div> {props.applicant.Name}</div>
        <div className="rect">फोटो</div>

    </td>
    let photobody = [
        <td align='center' className='nataBoxes'>
            <div className='nataName'>
                {props.applicant.Name}
            </div>
            <div className="rect">फोटो</div>
        </td>
    ];
    const phototable = <>
        {/* <tr >{photohead}</tr> */}
        <tr >{photobody}</tr></>;
    inRelations.map((items, index) => {

        photobody.push(<td key={index} align='center' className='nataBoxes'>

            <div className='nataName'>
                {items['नाम']}
            </div>

            <div className="rect">
                {items['नाम'].startsWith("स्व.") ? "मृतक" : "फोटो"}
            </div>

        </td>);
        return null;
    });
    const componentRef = props.componentRef;
    return (
        <div>
            {/* <div className='printAction'>
                <ReactToPrint
                    trigger={() => <button className="btn btn-danger" type="submit">Submit</button>}
                    content={() => componentRef.current}
                />
            </div> */}
            <br />
            प्रयोजनः   {prayojanInput} {spaces(10)}
            {addPersonsButton}
            <br />
            <br />

            नाता प्रमाणित गर्न चाहेका व्यक्तिहरु <br />
            {personsInput}
            <br />
            <div className='businessClose' ref={componentRef}>
                <OfficeInformation />
                <div id='DateRow' align='right'> मितिः {props.date.currentDate}</div>

                <h3 align="center">विषयः {underline("नाता प्रमाणित")} ।</h3>
                <h4 align="center">यो जो जससँग सम्बन्धित छ ।</h4>

                <div align='justify'>
                    {bold(getApplicant())}ले तपसिलमा उल्लिखित व्यक्तिहरु बीच {bold(prayojan)} प्रयोजनका लागि नाता प्रमाणित गरिदिन यस वडा कार्यालयमा दिनु भएको निवेदन अनुसार निजहरुबीच देहाय बमोजिम नाता रहेको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (१) बमोजिम प्रमाणित गरिन्छ ।
                    <br />
                    {underline("तपसिल")}
                    <table>
                        <thead>
                            <tr>
                                {tablehead}
                                {tablehead}
                            </tr>
                        </thead>
                        <tbody align="left">
                            {console.log(tablebody, "tablebody")}
                            {tablebody}
                        </tbody>
                    </table>
                    <br />

                    <table>
                        <tbody>
                            {phototable}
                        </tbody>

                    </table>
                    निवेदक को औठा छाप
                    <div className="row left ">


                        <div className="rect block"></div>
                        <div className="rect block"></div>

                        <div className="col">
                            <div className='signature' align='right'>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Sender person={props.person} />
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}
