import React from 'react'
import ReactToPrint from 'react-to-print';

export const PrintTable = React.forwardRef((props, ref) => {

    return (

        <ReactToPrint
            // onBeforeGetContent={validateForm()}
            trigger={() =>
            (

                <button className="btn btn-danger" type="submit">Print</button>


            )
            }
            content={() => ref.current}
        />
    )
}
);
