import React, { useState } from 'react'
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold } from '../Variables';

export default function Helpless(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const availableOptions = ['अशक्त', "असहाय", 'अनाथ']
    const selectedTitle = "selectedTitle";
    const selectedOption = details[selectedTitle] ? details[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                <Selection selectionTitle={selectedTitle} options={availableOptions} details={details} detailsUpdater={props.detailsUpdater} />

                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)} {selectedOption} रहेको र नीजको नजिकको पालन पोषण गर्ने कोही व्यक्ति नभएको व्यहोरा {sarjin.sarjinPresent && sarjin.sarjinDetails + "का आधारमा"} प्रमाणित भएकोले निजको पालन पोषणको लागि स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (३०) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
