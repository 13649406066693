import React from 'react'
import { userDetails } from '../LoginDetails'

export default function OfficeFooter() {
    return (
        <div className='officeFooter'>
            <hr />
            <div className="row">
                <div className="col">फोन:- {userDetails().office.फोन}</div>
                <div className="col englishFont" >इमेल:- {userDetails().office.email.toLowerCase()}</div>
            </div>
            <br />
        </div>
    )
}
