import React from 'react'
import { isValidDate } from '../Variables/TextVariables'

export default function ApplicationDate(props) {
    return (
        <div className="form-floating mb-1 DateRow">
            <input type="text" className="form-control" id="applicationDate" placeholder="Enter Date" value={props.date.applicationDate} onChange={(e) => props.setApplicationDate(isValidDate(e.target.value))} />
            <label htmlFor="floatingInput" >Application Date: </label>
        </div>
    )
}
