import React from 'react'
import PropTypes from 'prop-types'
import Login from './Users/Login';

export default function Header(props) {
    const user = props.user;
    return (
        <nav className="navbar navbar-expand-lg bg-light" >
            <div className="container-fluid" data-spy="affix">
                <a className="navbar-brand" href="#">{props.title}</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#"><img src={user?.photoURL} alt="" className="icon" /></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#"> {user?.displayName}</a>
                        </li>
                        <a className="nav-link" href="#"> {user?.email}</a>
                        <h3 className='p-1 mb-1  text-danger'>{props.selection}</h3>
                    </ul>

                    <button className="btn btn-warning" onClick={props.logout}>
                        Logout
                    </button>
                    {props.searchBar ? <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> : ""}
                </div>
            </div>
        </nav>
    )
}
Header.defaultProps = {
    title: "Your Title Here",
    searchBar: true
}
Header.prototype = {
    title: PropTypes.string,
    searchBar: PropTypes.bool.isRequired
}
