import React, { useState } from 'react';
import './EmailSender.css';
import EmailSender from './EmailSender';

const EmailSetup = (props) => {
    const [availableEmails, setAvailableEmails] = useState([
        'chaudharyrajkumar2028@gmail.com',
        'thapakuber52@gmail.com',
        '9842307@gmail.com'
    ]);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [showEmailContent, setShowEmailContent] = useState(false);

    const [newEmail, setNewEmail] = useState('');

    const handleEmailClick = (email) => {
        if (selectedEmails.includes(email)) {
            setSelectedEmails(selectedEmails.filter((selectedEmail) => selectedEmail !== email));
            setAvailableEmails([...availableEmails, email]);
        } else {
            setSelectedEmails([...selectedEmails, email]);
            setAvailableEmails(availableEmails.filter((availableEmail) => availableEmail !== email));
        }
    }



    const handleAddNewEmail = () => {
        if (newEmail.trim() !== '') {
            setSelectedEmails([...selectedEmails, newEmail]);
            setNewEmail('');
        }
    }

    return (
        <div className="email-sender-container">
            <button className="send-email-text" onClick={() => setShowEmailContent(!showEmailContent)}>
                {showEmailContent ? 'Hide' : 'Send Email'}
            </button>
            {showEmailContent && (
                <div>
                    <div className="email-sender-content">
                        <div className="available-emails">
                            <h2>Available Emails</h2>
                            <ul>
                                {availableEmails.map((email) => (
                                    <li key={email} onClick={() => handleEmailClick(email)}>{email}</li>
                                ))}
                            </ul>
                            <input
                                type="text"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Enter a new email address"

                            />
                            <button onClick={handleAddNewEmail}>Add Email</button>
                        </div>
                        <div className="selected-emails">
                            <h2>Selected Emails</h2>
                            <ul>
                                {selectedEmails.map((email) => (
                                    <li key={email} onClick={() => handleEmailClick(email)}>{email}</li>
                                ))}
                            </ul>

                        </div>

                    </div>
                    {selectedEmails.length > 0 && <EmailSender subject={props.subjec} message={"This is a text message"} html={props.html} receiverEmail={selectedEmails.join(",")} senderEmail={"mukesh.inaruwa@gmail.com"} />
                    }
                    {/* <button className="send-email-text " style={{ float: 'right' }} onClick={handleSendEmail}>Send Email</button> */}
                </div>

            )
            }

        </div>
    );
}

export default EmailSetup;
