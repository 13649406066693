import React, { useState } from 'react'
import InputBox from '../InputBox';
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading'
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import Sender from '../ParagraphItems/Sender';
import { arrayToObjects, bold, LandDetails, spaces } from '../Variables';

export default function HouseinMap(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    let initializationObject = arrayToObjects(LandDetails, { 0: userDetails().office["वडा नं"] });
    const landDetails = details.landDetails ? details.landDetails : ([initializationObject])
    const [count, setCount] = useState(landDetails.length); //this is total land counts
    const landInputs = [];
    var landcounts = 0;
    while (count > landcounts) {
        let landcount = landcounts;
        let x = landDetails[landcount] ? "" : landDetails.push(initializationObject);
        landInputs.push(
            <div key={0}>
                <hr />  क्र.स. {landcount + 1}
            </div>
            ,
            LandDetails.map((items, index) => {
                return <div className="col" key={index}>
                    <InputBox field={items} value={landDetails[landcount][items]} handleChanges={(field, value) => {
                        console.log({ landcount }, { field }, { value });
                        let currentObject = landDetails[landcount]
                        currentObject[field] = value;
                        props.detailsUpdater("landDetails", [...landDetails])
                    }} />
                </div>
            }),
            <div className="col" key={1}>
                {spaces(2)}
                <button className='col btn btn-outline-danger btn-sm' onClick={(e) => {
                    landDetails.splice(landcount, 1);
                    // setpersonalDetails([...personalDetails]);
                    setCount(count - 1);
                }}>Remove
                </button>

            </div>

        );
        landcounts++;
    }

    const addButton = <button className='btn btn-primary btn-lg' onClick={(e) => { setCount(count + 1) }}>थप जग्गा</button>

    const tablehead = [
        <th key={0}>क्र.स.</th>
        , LandDetails.map((items, index) => {
            return <th key={index}>{items}</th>
        })]
    const tablebody = landDetails.map((items, index) => {
        return <tr key={index}>
            {['क्र.स.', ...LandDetails].map((item, index1) => {
                return index1 === 0 ? <td key={index1}>{index + 1}</td> : <td key={index1}>{items[item]}</td>
            })}
        </tr>
    })
    return (
        <div>
            <div className="row">
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                {landInputs}
                <hr />
                {addButton}
            </div>

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को नाममा त्यस कार्यालयमा दर्ता श्रेस्ता कायम रहेको देहायमा उल्लिखित जग्गाको जग्गाधनी श्रेस्ता पुर्जामा घर कायम नभएको र हाल उक्त कित्तामा घर निर्माण भएकोले जग्गाधनी प्रमाण पुर्जामा घर कायम गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (१४) बमोजिम सिफारिस साथ अनुरोध गरिन्छ ।
                </div>
                <div>
                    <br />
                    {bold("घर कायम गर्नुपर्ने जग्गाको विवरण")}
                    <table>
                        <thead>
                            <tr>
                                {tablehead}
                            </tr>

                        </thead>
                        <tbody>
                            {tablebody}
                        </tbody>
                    </table>
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
