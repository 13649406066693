import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, houseItems, housesituations, reportTypes } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function HouseDown(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const houseDownTitle = "houseOption";
    const houseDownOption = details[houseDownTitle] ? details[houseDownTitle] : housesituations[0];
    const reportTitle = "reportOption";
    const reportOption = details[reportTitle] ? details[reportTitle] : reportTypes[0];

    const itemsToMap = houseItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 3 ? isValidDate(value) : value)
            }} />
        </div>
    })

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                {inputs}
                <Selection selectionTitle={houseDownTitle} options={housesituations} details={details} detailsUpdater={props.detailsUpdater} />
                <Selection selectionTitle={reportTitle} options={reportTypes} details={details} detailsUpdater={props.detailsUpdater} />


                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {props.topic} सिफारिस गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को नाममा यस वडामा रहेको {details[houseItems[0]] && 'कित्ता नं ' + details[houseItems[0]]} {details[houseItems[1]] && 'क्षेत्रफल ' + details[houseItems[1]] + ' वर्गफूट'} मा रहेको {details[houseItems[2]] && details[houseItems[2]] + " वर्गफूटको"} घर {details[houseItems[3]] && 'मिति ' + details[houseItems[3]] + ' मा'} {houseDownOption} व्यहोरा नीजको निवेदन र {sarjin.sarjinPresent ? (reportOption !== reportTypes[0] ? reportOption + " र " + sarjin.sarjinDetails : sarjin.sarjinDetails) : reportOption}को आधारमा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१२) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
