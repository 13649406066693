import React from 'react'

export default function InputBox(props) {
    const handleInputChange = (event) => {
        console.log("field: " + props.field + " value: " + event.target.value);
        props.handleChanges(props.field, event.target.value.toUpperCase())
    }


    return (
        <div className="form-floating ">
            <input style={props.style} min={props.min} max={props.max} maxLength={props.maxlength} required={props.required} type={props.type ? props.type : "textarea"} className="form-control inputbox" id={props.inputHead + "_" + props.field} placeholder={props.field} value={props.value} onChange={handleInputChange} />
            <label htmlFor="floatingInputName" id={props.field + '_label'}>{props.field}</label>
        </div>

    )
}
InputBox.defaultProps = {
    inputHead: "",
    required: false
}
