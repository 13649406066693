import React, { useState } from 'react'
import InputBox from '../InputBox';
import SelectBox from '../SelectBox';
import { nagariktaDetails, applicantItems, genderItems, nagariktaSpecificItems } from '../Variables';
import { isValidDate } from './TextVariables';

var personDetails = {};
const emptyObject = {};
var count = 0;
export default function Person(props) {
    const [showNagarikta, setShowNagarikta] = useState(false);

    const applicantObjects = {};
    applicantItems.map((items) => {
        items !== 'नागरिकता' ? applicantObjects[items] = '' :
            nagariktaDetails.map(item => {
                // console.log("count ", count++);
                if (!applicantObjects[items]) applicantObjects[items] = emptyObject;
                applicantObjects[items][item] = ''; return null;
            });

        return null;
    })
    const person = props.person ? props.person : applicantObjects;
    personDetails = person;
    const handlePerson = (field, value) => {
        if (field == "जन्ममिति") value = isValidDate(value);
        props.handlePerson(field, value)
    }
    const toggleNagarikta = () => {
        setShowNagarikta(!showNagarikta);
    }

    const applicantQueries = applicantItems.map((items, index) => {
        if (!showNagarikta && items === 'नागरिकता') {
            return null; // Skip rendering नागरिकता items if not interested
        }

        const applicantFormItems = items === 'नागरिकता' ? nagariktaDetails.map((item, index) => {
            return (
                <div className='col form-floating' key={index}>
                    {(typeof person[items] === 'undefined') ? person[items] = '' : ''}
                    <input type="text" className="form-control inputbox" field={item} value={person[items][item]} placeholder={item} onChange={(e) => {
                        let value = e.target.value;
                        if (index === 1) value = isValidDate(value);
                        handlePerson(items, { ...person[items], ...{ [item]: value } })
                    }} />
                    <label htmlFor="floatingInputName" id={item + '_label'}>{item}</label>
                </div>
            )
        }) :
            <div className='col' key={index}>
                {items !== 'gender' ? <InputBox field={items} value={person[items]} handleChanges={handlePerson} /> :
                    <SelectBox items={items} value={person[items]} options={genderItems} handleChanges={(value) => handlePerson(items, value)} />}
            </div>
        return (
            applicantFormItems
        );
    });

    return (
        <div className='input-group'>
            {applicantQueries}
            <button onClick={toggleNagarikta}>{showNagarikta ? 'Show Less' : 'Show More'}</button>
        </div>
    )
}
export const getPerson = personDetails;
