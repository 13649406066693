
/* Length of Gregroian months in a non-leap year */
const nepali_years_and_days_in_months = [
    [2000, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2001, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2002, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2003, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2004, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2005, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2006, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2007, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2008, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2009, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2010, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2011, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2012, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2013, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2014, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2015, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2016, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2017, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2018, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2019, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2020, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2021, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2022, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2023, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2024, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2025, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2026, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2027, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2028, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2029, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [2030, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2031, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2032, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2033, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2034, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2035, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2036, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2037, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2038, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2039, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2040, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2041, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2042, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2043, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2044, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2045, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2046, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2047, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2048, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2049, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2050, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2051, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2052, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2053, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2054, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2055, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2056, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [2057, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2058, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2059, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2060, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2061, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2062, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    [2063, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2064, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2065, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2066, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2067, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2068, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2069, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2070, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2071, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2072, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2073, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2074, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2075, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2076, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2077, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2078, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2079, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2080, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2081, 31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2082, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2083, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [2084, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [2085, 31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [2086, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2087, 31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    [2088, 30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [2089, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2090, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30]
];

const GregorianMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const NepaliMonthData = {
    /*
     In the following table we map the dates:
     The year is the nepali year
     The entries in the array are the nepali Day for the Gregorian 1st of the month
     So first entry is 1st May  i.e. the day in baisakh which is the first of May.
     second entry is 1st June
     third entry is 1st July
     fourth entry is 1st August
     fifth entry is 1st September
     sixth entry is 1st October
     seventh entry is 1st November
     eighth entry is 1st December
     ninth entry is 1st January
     tenth entry is 1st February
     eleventh entry is 1st March
     twelfth entry is 1st April
     */

    // this data are based on http://www.ashesh.com.np
    1970: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1971: [19, 19, 18, 17, 17, 15, 16, 16, 18, 19, 18, 19],
    1972: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 19, 19],
    1973: [19, 20, 18, 18, 17, 16, 17, 17, 18, 20, 18, 20],
    1974: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1975: [19, 19, 18, 17, 16, 16, 16, 16, 18, 19, 18, 19],
    1976: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 19, 20],
    1977: [19, 20, 18, 18, 17, 16, 16, 17, 18, 20, 18, 20],
    1978: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1979: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    1980: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 19, 20],
    1981: [19, 19, 18, 18, 17, 16, 16, 17, 18, 19, 18, 19],
    1982: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1983: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    1984: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 19, 20],
    1985: [19, 19, 18, 18, 17, 16, 16, 17, 18, 19, 18, 19],
    1986: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1987: [19, 19, 17, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    1988: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 19, 20],
    1989: [19, 19, 18, 18, 17, 16, 16, 16, 18, 19, 18, 19],
    1990: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1991: [19, 19, 17, 17, 16, 15, 16, 16, 18, 19, 18, 19],

    // this data based on http://nepalicalendar.rat32.com/index.php
    1992: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 20],
    1993: [19, 19, 18, 18, 17, 16, 16, 16, 18, 19, 18, 19],
    1994: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1995: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    1996: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 20],
    1997: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1998: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    1999: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2000: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 20],
    2001: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2002: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2003: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2004: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 20],
    2005: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2006: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2007: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2008: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 18, 20],
    2009: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2010: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2011: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2012: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 18, 19],
    2013: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2014: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2015: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2016: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 18, 19],
    2017: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2018: [19, 19, 17, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2019: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2020: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 18, 19],
    2021: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2022: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2023: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2024: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 18, 19],
    2025: [19, 19, 18, 17, 17, 16, 16, 16, 18, 19, 18, 19],
    2026: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2027: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2028: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2029: [19, 19, 18, 17, 17, 15, 16, 16, 18, 19, 18, 19],
    2030: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2031: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2032: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2033: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2034: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2035: [18, 19, 17, 17, 16, 15, 15, 16, 17, 18, 17, 19],
    2036: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2037: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2038: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2039: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 17, 18],
    2040: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2041: [19, 19, 18, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2042: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2043: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 17, 18],
    2044: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2045: [19, 19, 17, 17, 16, 15, 16, 16, 18, 19, 18, 19],
    2046: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2047: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 17, 18],
    2048: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2049: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2050: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2051: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 17, 18],
    2052: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 18, 19],
    2053: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2054: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2055: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2056: [18, 18, 17, 16, 16, 14, 15, 15, 17, 18, 18, 19],
    2057: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2058: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2059: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2060: [18, 18, 17, 16, 15, 14, 15, 15, 17, 18, 18, 19],
    2061: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2062: [18, 18, 17, 17, 16, 15, 15, 16, 17, 19, 17, 19],
    2063: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2064: [18, 18, 17, 16, 15, 14, 15, 15, 17, 18, 18, 19],
    2065: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2066: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 17, 19],
    2067: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2068: [18, 18, 17, 16, 15, 14, 15, 15, 17, 18, 18, 19],
    2069: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2070: [18, 18, 17, 17, 16, 15, 15, 16, 17, 18, 17, 18],
    2071: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2072: [18, 18, 16, 16, 15, 14, 15, 15, 17, 18, 18, 19],
    2073: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 18, 19],
    2074: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 17, 18],
    2075: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2076: [18, 18, 16, 16, 15, 14, 15, 15, 16, 18, 18, 19],
    2077: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 19],
    2078: [18, 18, 17, 17, 16, 15, 15, 15, 17, 18, 17, 18],
    2079: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 17, 18],
    2080: [18, 18, 16, 16, 15, 14, 15, 15, 16, 18, 18, 19],

    // this data based on http://www.ashesh.com.np/nepali-calendar/
    2081: [19, 19, 18, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2082: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2083: [18, 18, 17, 16, 16, 15, 16, 16, 17, 19, 17, 18],
    2084: [18, 18, 17, 16, 16, 15, 16, 16, 17, 19, 18, 19],
    2085: [19, 19, 17, 17, 16, 16, 16, 16, 17, 19, 17, 18],
    2086: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2087: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 16, 17],
    2088: [17, 18, 17, 16, 15, 15, 15, 15, 16, 18, 17, 18],
    2089: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2090: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2091: [18, 18, 17, 16, 16, 15, 15, 15, 16, 18, 16, 17],
    2092: [17, 18, 17, 16, 15, 14, 15, 15, 16, 18, 17, 18],
    2093: [18, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2094: [18, 18, 17, 16, 16, 15, 16, 16, 17, 19, 17, 18],
    2095: [18, 18, 17, 16, 16, 15, 15, 15, 17, 18, 16, 17],
    2096: [17, 18, 17, 16, 15, 14, 15, 15, 17, 18, 18, 19],
    2097: [19, 19, 17, 17, 16, 15, 16, 16, 17, 19, 17, 18],
    2098: [18, 18, 17, 16, 16, 15, 15, 16, 17, 19, 17, 19],
    2099: [18, 18, 17, 16, 16, 15, 15, 15, 17, 19, 17, 18],
    2100: [18, 18, 16, 16, 15, 15, 15, 15, 17, 18, 18, 19],
}


function NepaliDate(yy, mm, dd) {
    return {
        nepaliYear: yy,
        nepaliMonth: mm,
        nepaliDay: dd
    }
}

/* Create a NepaliDate object from a JS Date object. */
function fromgregorian(date) {

    if (!date) {
        return undefined;
    }
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate()


    let nepaliYear = year + 56;
    let nepaliMonth = month + 8;
    if (nepaliMonth >= 12) {
        nepaliMonth -= 12;
        nepaliYear += 1;
    }
    let nepaliDay = day + NepaliMonthData[nepaliYear][nepaliMonth] - 1;
    let otherDay = day - GregorianMonths[month];
    let otherMonth = nepaliMonth + 1;
    let otherYear = nepaliYear;
    if (otherMonth >= 12) {
        otherMonth -= 12;
        otherYear += 1;
    }
    if (month === 2 && ((year % 4) === 0) && (((year % 100) !== 0) || ((year % 400) === 0))) {
        otherDay += 1;
    }
    otherDay = otherDay + NepaliMonthData[otherYear][otherMonth] - 1;
    if (otherDay > 0) {
        nepaliYear = otherYear;
        nepaliMonth = otherMonth;
        nepaliDay = otherDay;
    }
    return NepaliDate(nepaliYear, nepaliMonth + 1, nepaliDay)
}

/* Create a NepaliDate object for the current date */
// function today() {
//     return fromgregorian(new Date());
// }
/* Return a String representing the nepali date */
// function toDateString() {
//     return this.nepaliYear.toString() + "/" + this.nepaliMonth + "/" + this.nepaliDay;
// }

/* Return a JS Date object for the NepaliDate date. */
function gregorian(nepaliDate) {
    let y = nepaliDate.nepaliYear;
    let m = nepaliDate.nepaliMonth - 1;
    let d = nepaliDate.nepaliDay;
    if (isNaN(y)) return nepaliDate;
    d -= NepaliMonthData[y][m] - 1;
    m -= 8;
    y -= 56;
    return new Date(y, m, d);
}

/* Create a NepaliDate object from a string in the form YYYY/MM/DD
 Also accepted YYYY/M/DD, YYYY/M/D, YYYY/MM/D
 and forms with out a year.
 The forms without a year are treated as being in the past year.
 */
export const fromPartialString = (v) => {
    let match = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/.exec(v);
    if (match) {
        return NepaliDate(match[1], match[2], match[3]);
    }

    match = /^(\d{4})-(\d{1,2})-(\d{1,2})$/.exec(v);
    if (match) {
        return NepaliDate(match[1], match[2], match[3]);
    }
    match = /^(\d{4})(\d{1,2})(\d{1,2})$/.exec(v);
    if (match) {
        return NepaliDate(match[1], match[2], match[3]);
    }
    match = /^(\d{1,2})\/(\d{1,2})$/.exec(v);
    if (match) {
        let nd = NepaliDate.today();
        let y = nd.nepaliYear;
        let m = match[1];
        let d = match[2];
        if (m > nd.nepaliMonth || (m === nd.nepaliMonth && d > nd.nepaliDay)) {
            y -= 1;
        }
        return NepaliDate(y, m, d);
    }
    return v
}
export const getAD = (nepaliDate) => {
    let date = gregorian(fromPartialString(nepaliDate));
    if (date === nepaliDate) {
        return nepaliDate;
    }
    // let year = englishDate.getFullYear();
    // let month = date.getMonth();
    // let day = date.getDate()

    if (typeof date === 'undefined') return nepaliDate;
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
}
// export const date = gregorian(NepaliDate(2050, 11, 1));

export const getAge = (nepaliDate) => {
    let givenDate = fromPartialString(nepaliDate); let today = fromgregorian(new Date());
    if (givenDate === nepaliDate) {
        return "INVALID DOB";
    }
    var age = today.nepaliYear - givenDate.nepaliYear;
    let currentMonth = today.nepaliMonth;
    if (currentMonth < givenDate.nepaliMonth || (currentMonth === givenDate.nepaliMonth && today.nepaliDay < givenDate.nepaliDay)) {
        age--;
    }
    return age;
}
// export default NepaliDate; 
export const NepaliFormatNagarikta = (date) => {
    const dateObj = fromPartialString(date)
    return dateObj.nepaliYear + " साल " + dateObj.nepaliMonth + " महिना " + dateObj.nepaliDay + " गते";
}
export const EnglishFormatNagarikta = (date) => {
    const dateObj = fromPartialString(date)
    return dateObj.nepaliYear + " Year " + dateObj.nepaliMonth + " Month " + dateObj.nepaliDay + " Day";
}
export const getDatesDifference = (initialDate, finalDate) => {
    let date1 = fromPartialString(initialDate);
    let date2 = fromPartialString(finalDate);
    if (date1 == initialDate || date2 == finalDate) return "INVALID DATE";
    return dateDifferences(date1, date2);
    // return getExactDateDifference(gregorian(date1), gregorian(date2));
}
// this function is generated by AI
function getExactDateDifference(startDate, endDate) {
    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();
    let days = endDate.getDate() - startDate.getDate();

    if (days < 0) {
        months--;
        const monthLength = new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        days += monthLength;
    }
    if (months < 0) {
        years--;
        months += 12;
    }

    return { years, months, days };
}
export const dateDifferences = (date1, date2) => {
    let year = date2.nepaliYear - date1.nepaliYear;
    let month = date2.nepaliMonth - date1.nepaliMonth;
    let day = date2.nepaliDay - date1.nepaliDay;

    if (date2.nepaliYear < 2001 || date2.nepaliYear > 2090 || date2.nepaliMonth > 12 || date2.nepaliDay > 32) return "INVALID DATE or DATE OUT OF RANGE"


    if (day < 0) {
        month--;
        if (!month < 0)
            day = day + nepali_years_and_days_in_months[date2.nepaliYear - 2000][date2.nepaliMonth - 1];
        else
            day = day + nepali_years_and_days_in_months[date2.nepaliYear - 2000 - 1][12]
    }
    if (month < 0) {
        year--;
        month = month + 12;
    }
    return { year, month, day }
}
// export const nextDay = (dateStr) => {
//     console.log(`dateString: ${dateStr}`);
//     const parsedDate = fromPartialString(dateStr);
//     if (!parsedDate) return null;
//     console.log(`parsedDate: ${JSON.stringify(parsedDate)}`);

//     let { nepaliYear, nepaliMonth, nepaliDay } = parsedDate;
//     console.log(`nepaliYear: ${nepaliYear}, nepaliMonth: ${nepaliMonth}, nepaliDay: ${nepaliDay}`);

//     // Find the index for the given year in nepali_years_and_days_in_months
//     const yearData = nepali_years_and_days_in_months.find(y => y[0] === nepaliYear);
//     if (!yearData) {
//         throw new Error(`Year ${nepaliYear} not found in nepali_years_and_days_in_months`);
//     }

//     const daysInMonth = yearData[nepaliMonth];
//     let newDay = nepaliDay + 1;
//     let newMonth = nepaliMonth;
//     let newYear = nepaliYear;

//     if (newDay > daysInMonth) {
//         newDay = 1;
//         newMonth += 1;
//         if (newMonth > 12) {
//             newMonth = 1;
//             newYear += 1;
//         }
//     }

//     console.log(`New Date: ${newYear}-${newMonth}-${newDay}`);

//     return new NepaliDate(newYear, newMonth, newDay);
// }

