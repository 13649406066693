import React from 'react'
import { isValidDate } from '../Variables/TextVariables'

export default function DateItem(props) {
    return (
        <div className="row">
            {/* <div className="col">
                <h1 className='mukesh' style={{ textAlign: 'center' }}>{props.selection}</h1>
            </div> */}
            <div className="col">
                <div className="form-floating asideRight mb-1 mukesh">
                    <input type="text" className="form-control" id="date" placeholder="Enter Date" value={props.date.currentDate} onChange={(e) => props.date.setCurrentDate(isValidDate(e.target.value))} />
                    <label htmlFor="floatingInput">Date</label>
                </div>
            </div>


        </div>
    )
}
