import React, { useState } from 'react'
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Sender from '../ParagraphItems/Sender';
import { bold } from '../Variables';

export default function Alive(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);
    return (
        <div>
            <div>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />

            </div>

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)} जिवित रहेको व्यहोरा {sarjin.sarjinPresent && (sarjin.sarjinDetails + "का आधारमा ")}स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (२२) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <div>
                    <br />

                    {table}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
