import React, { useState } from 'react'
import InputBox from '../InputBox';
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import TableEntry from '../ParagraphItems/TableEntry';
import { bold, GharBatoDetails, jaggaMulyankanItems, availableOptions, reportTypes, udhyogInputs, CharKillaDetails, purjamaGharDetails, purjaGharInputs } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';

export default function GharBato(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);
    const defaults = { 0: userDetails().office["वडा नं"] }

    const availableOptions = ['घरबाटो प्रमाणित', 'चारकिल्ला प्रमाणित', 'पूर्जामा घर कायम गर्ने सिफारिस']
    const selectedTitle = "selectedTitle";
    const selectedOption = details[selectedTitle] ? details[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);
    const reportTitle = "reportOption";
    const reportOption = details[reportTitle] ? details[reportTitle] : reportTypes[0];
    const tableDetails = selectedIndex === 0 ? GharBatoDetails : selectedIndex == 1 ? CharKillaDetails : purjamaGharDetails;

    const itemsToMap = purjaGharInputs;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, index === 0 ? isValidDate(value) : value)
            }} />
        </div>
    })



    const tableEntry = <div>
        <h4>{selectedIndex === 0 ? "घरबाटो" : selectedIndex === 1 ? "चारकिल्ला" : "घर कायम गर्ने जग्गा र घरको"}को विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={tableDetails} defaults={defaults} details={details} detailsUpdater={props.detailsUpdater} />
    </div>

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <Selection selectionTitle={selectedTitle} options={availableOptions} details={details} detailsUpdater={props.detailsUpdater} />

                {selectedIndex === 2 && inputs}
                <hr />
                {tableEntry}
                <hr />
                <Selection selectionTitle={reportTitle} options={[...reportTypes, ...['नक्सा सम्पन्‍न प्रतिवेदन', ' ']]} details={details} detailsUpdater={props.detailsUpdater} />
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' contentEditable={true} ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedOption} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {selectedOption} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {selectedIndex < 2 ? <span>{bold(applicantRelation.personMention)}को नाममा त्यस कार्यालयमा दर्ता श्रेस्ता कायम रहेको जग्गाको {selectedIndex === 0 ? "घरबाटो" : "चारकिल्ला"} तल उल्लिखित विवरण अनुसार भएको व्यहोरा</span> : <span>तपसिल बमोजिमको पूर्जामा {details[itemsToMap[1]]} वर्गफूटको घर मिति {details[itemsToMap[0]]}मा निर्माण भएकोले पूर्जामा घर कायम गरिदिनुहुन</span>} {sarjin.sarjinPresent ? (reportOption !== reportTypes[0] && reportOption !== ' ' ? reportOption + " र " + sarjin.sarjinDetails : sarjin.sarjinDetails) + "को आधारमा" : reportOption + (reportOption != ' ' ? "को आधारमा" : '')} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(२८) बमोजिम प्रमाणित गरिन्छ ।
                </div>
                <br />
                {selectedIndex === 0 ? "घरबाटो" : selectedIndex === 1 ? "चारकिल्ला" : "घर कायम गर्ने जग्गा र घरको"}को विवरण
                {table}
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
