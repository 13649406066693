import React, { useState } from 'react'
import InputBox from '../InputBox'
import { userDetails } from '../LoginDetails'
import Heading from '../ParagraphItems/Heading'
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import RelationToApplicant from '../ParagraphItems/RelationToApplicant'
import SarjinPresent from '../ParagraphItems/SarjinPresent'
import Sender from '../ParagraphItems/Sender'
import TableEntry from '../ParagraphItems/TableEntry'
import { bold, DhanipurjaLandDetails, kittaKaatItems, underline } from '../Variables'

export default function KittaKaat(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const [table, setTable] = useState([]);

    const defaults = { 0: userDetails().municipality.नाम + "-" + userDetails().office["वडा नं"] }
    const tableEntry = <div>
        <h4>जग्गा विवरण</h4>
        <TableEntry setTable={setTable} inputHeads={DhanipurjaLandDetails} defaults={defaults} details={details} detailsUpdater={props.detailsUpdater} />
    </div>
    const itemsToMap = kittaKaatItems;
    const inputs = itemsToMap.map((items, index) => {
        return <div className="col" key={index}>
            <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, value)
            }} />
        </div>
    })

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                {tableEntry}
                <hr />
                {inputs}
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />

            </div>

            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={props.topic} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter}  {props.topic} गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}को तपसिलमा उल्लिखित विवरणको जग्गा/घरजग्गा मध्ये {bold(details[kittaKaatItems[0]])} बाट {bold(details[kittaKaatItems[1]])} क्षेत्रफल जग्गा कित्ताकाट/प्लट मिलान गर्न प्राविधिक निरीक्षण गर्दा मापदण्ड अनुसार मिल्ने देखिएको हुनाले सोको लागि {sarjin.sarjinPresent && (sarjin.sarjinDetails + "का आधारमा ")}स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ (१८) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <div>
                    <br />
                    {underline("जग्गा/घरजग्गाको विवरण")}
                    {table}
                    <br />
                    <br />
                    <br />
                </div>

                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
