import React, { useEffect } from 'react'
import { spaces } from '../Variables';

export default function SarjinPresent(props) {
    let details = props.details;
    const sarjinPresent = details.sarjinPresent ? details.sarjinPresent : false;
    const sarjinDetails = details.sarjinDetails ? details.sarjinDetails : "मिति " + props.date.applicationDate + " मा गरिएको स्थानीय सर्जमिन मुचुल्का समेत";
    const sarjinDetailsInput = <input type="textarea" name="sarjinDetails" id="marriageSarjin" value={sarjinDetails} onChange={e => props.detailsUpdater("sarjinDetails", e.target.value)} />

    useEffect(() => {
        console.log('tryValue Changed has changed:Sarjin');
        props.setSarjin({ sarjinPresent, sarjinDetails })
    }, [sarjinPresent, sarjinDetails]);

    return (
        <div className='p-3 mb-2 bg-primary text-white'>
            <input type="checkbox" id={'_checkbox'} checked={sarjinPresent} onChange={(e) => { props.detailsUpdater("sarjinPresent", e.target.checked) }} />
            <b> सर्जमिन ?</b> {spaces(5)}
            {sarjinPresent ? sarjinDetailsInput : ''}
        </div>
    )
}
