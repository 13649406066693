import React, { useState } from 'react';
import './EmailSender.css';

const EmailSender = ({ subject, message, html, receiverEmail, senderEmail }) => {
    console.log("in emailsender")
    const [loading, setLoading] = useState(false); // add state variable

    const sendEmail = () => {
        console.log("sending email")
        setLoading(true); // set loading to true when sending email
        // let receiverEmail = "chaudharyrajkumar2028@gmail.com";
        // let senderEmail = "mukesh.inaruwa@gmail.com";
        // let html = populateTableForHTML(inputHeads, [message]);

        fetch('https://capricious-dorian-macaroni.glitch.me/sendemail/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiverEmail: receiverEmail,
                senderEmail: senderEmail,
                message: JSON.stringify(message),
                subject: subject,
                html: html,
            })
        })
            .then(response => {
                // Handle the response
                console.log("success")
                setLoading(false);
                alert("Email sent successfully")
            })
            .catch(error => {
                // Handle errors
                console.log("error to send Email");
                alert("Email sending failed.")
            });

    }

    return (
        <>
            {!loading && <button className="send-email-text " style={{ float: 'right' }} onClick={sendEmail}>Send Email</button>}
            {loading && <div className="loading">Sending email...</div>}
        </>
    );
}

export default EmailSender;
