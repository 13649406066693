import React, { useState } from 'react'
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold } from '../Variables';

export default function EconomicStatus(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const availableOptions = ['आर्थिक अवस्था बलियो वा सम्पन्‍नता प्रमाणित', 'आर्थिक अवस्था कमजोर वा बिपन्‍नता प्रमाणित']
    const selectedTitle = "selectedTitle";
    const selectedOption = details[selectedTitle] ? details[selectedTitle] : availableOptions[0];
    const selectedIndex = availableOptions.indexOf(selectedOption);

    return (
        <div>
            <div className='row'>
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                <hr />
                <Selection selectionTitle={selectedTitle} options={availableOptions} details={details} detailsUpdater={props.detailsUpdater} />

                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />
            </div>
            <div className='businessClose' ref={props.componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedOption} date={props.date} />
                <br />
                <div className="content" align='justify'>
                    {props.starter} {selectedOption} सिफारिस गरिदिनुहुन भनी दिनु भएको निवेदन अनुसार {bold(applicantRelation.personMention)}ले यस {userDetails().municipality.नाम} {userDetails().municipality.प्रकार}लाई {selectedIndex === 0 ? "तिरेको कर, घर जग्गाको विवरण, अन्य आयका आधारमा नीजको आर्थिक अवस्था बलियो/निज सम्पन्न भएको" : "तिर्न सक्ने करको विश्लेषण, घर जग्गाको विवरण र अन्य आयका आधारमा नीजको आर्थिक अवस्था कमजोर/निज विपन्न रहको"} व्यहोरा {sarjin.sarjinPresent && sarjin.sarjinDetails + "का आधारमा"} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२ (२) ङ ({selectedIndex === 0 ? "३१" : "३२"}) बमोजिम सिफारिस गरिन्छ ।
                </div>
                <br />
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div>
    )
}
