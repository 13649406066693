import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { userDetails } from '../LoginDetails';
import Heading from '../ParagraphItems/Heading';
import OfficeFooter from '../ParagraphItems/OfficeFooter';
import OfficeInformation from '../ParagraphItems/OfficeInformation'
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Selection from '../ParagraphItems/Selection';
import Sender from '../ParagraphItems/Sender';
import { bold, spaces, twoNamesDefaults, twoNamesSpecificItems, underline } from '../Variables';
import { getApplicant, officeAddress } from '../Variables/TextVariables';

export default function TwoNames(prop) {
    const props = prop.prop;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});
    const selectionTitle = 'selectedTitle';

    const selectionTypes = ['फरक फरक विवरण', 'नाम थर जन्ममिति संशोधन'];
    const selectedTopic = details[selectionTitle] ? details[selectionTitle] : selectionTypes[0];
    const selectedIndex = selectionTypes.indexOf(selectedTopic);

    var subject = details[twoNamesSpecificItems[0]];
    if (!subject) subject = twoNamesDefaults[0]
    // console.log(subject, "subject");

    var relationWithApplicant = details[twoNamesSpecificItems[1]] + 'को';
    if (!details[twoNamesSpecificItems[1]]) relationWithApplicant = '';
    if (details[twoNamesSpecificItems[1]] === "आफै") relationWithApplicant = '';

    // subject = bold(subject)
    relationWithApplicant = bold(relationWithApplicant)

    const componentRef = props.componentRef;
    return (
        <div>
            {/* <div className='printAction'>
                <ReactToPrint
                    trigger={() => <button className="btn btn-danger" type="submit">Submit</button>}
                    content={() => componentRef.current}
                />
            </div> */}
            <Selection selectionTitle={selectionTitle} options={selectionTypes} details={details} detailsUpdater={props.detailsUpdater} />

            {spaces(5)}
            <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />



            <div className='businessClose' ref={componentRef}>
                <OfficeInformation />
                <Heading receiver={props.person.receiver} topic={selectedIndex < 1 ? "फरक-फरक " + subject : subject + " संशोधनको सिफारिस"} date={props.date} />

                <br />
                <div className="content" align='justify'>
                    {bold(getApplicant())}ले तल उल्लेखित विवरण अनुसारको कागजातमा निजको {relationWithApplicant} {subject}
                    {selectedIndex < 1 ? <span> फरक हुन गएकोले सो फरक हुन गएको {subject} भएको व्यक्ति एकै व्यक्ति भएको प्रमाणित गरी </span> :
                        <span> संशोधनको सिफारिस </span>}
                    पाउँ भनी यस {officeAddress()} को {userDetails().office.प्रकार}मा मिति <b>{props.date.applicationDate}</b> गते पेश गर्नु भएको {bold("निवेदनसाथ संलग्न कागजात" + (sarjin.sarjinPresent ? ' तथा ' + sarjin.sarjinDetails : ''))}का आधारमा निजले पेश गरेको व्यहोरा मनासिव भएको खुल्न आएकोले
                    {selectedIndex < 1 ? <span>सो फरक-फरक {subject} भएको व्यक्ति एकै भएको व्यहोरा स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१५) बमोजिम सिफारिस गरिन्छ।</span> :
                        <span>{selectedTopic} संशोधनका लागि स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(१६) बमोजिम सिफारिस गरिन्छ।</span>}


                    <br />
                    {underline(bold('तपसिल'))}
                    <table>
                        <thead>
                            <tr>
                                <th>फरक परेको विवरण</th>
                                <th>हुनु पर्ने विवरण</th>
                                <th>फरक परेको कागजात</th>

                            </tr>
                        </thead>

                        <tbody className='left'>
                            <tr>
                                <td>{bold(details[twoNamesSpecificItems[2]])}</td>
                                <td>{bold(details[twoNamesSpecificItems[3]])}</td>
                                <td>{bold(details[twoNamesSpecificItems[4]])}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <br />
                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />
                </div>

            </div>
        </div >
    )
}
